import React from 'react';
import { cx } from '../../../../helpers/utils';

export const ShareCardWrapper: React.FC<{
  imageUrl?: string;
  imageComponent?: React.ReactNode;
  title: string;
  actions: React.ReactNode;
  centerContent?: React.ReactNode;
  belowContent?: React.ReactNode;
}> = ({
  imageUrl,
  imageComponent,
  title,
  actions,
  centerContent,
  belowContent,
}) => {
  return (
    <div className="flex w-full flex-col gap-1 rounded-lg border border-slate-200/70 bg-white px-2.5 py-2.5 shadow-[0_0px_2px_0_rgb(0,0,0,0.05)] hover:bg-slate-25">
      <div
        className={cx(
          'flex items-start justify-between gap-2',
          belowContent ? 'mb-3' : ''
        )}
      >
        <div className="flex items-center justify-center gap-3 truncate">
          <div className="flex size-7 flex-shrink-0 items-center rounded-lg bg-slate-100 p-1.5">
            {imageUrl ? (
              <img height={16} width={16} alt={title} src={imageUrl} />
            ) : (
              imageComponent
            )}
          </div>
          <p className="truncate text-sm font-medium text-slate-600">{title}</p>
        </div>
        {centerContent && (
          <div className="flex-1 overflow-hidden">{centerContent}</div>
        )}
        <div className="flex items-start space-x-0">{actions}</div>
      </div>
      {belowContent}
    </div>
  );
};
