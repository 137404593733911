import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { Team } from '../../../graphql/operations';
import { EmailsInput } from '../../Common/EmailsInput';
import { TeamInvitationLink } from './TeamInvitationLink';
import { useSelector } from 'react-redux';
import { selectUid } from '../../../redux/selectors';
import { Alert } from '../../../components/Alert';
import { ModalDialog } from '../../../components/modals';

export interface AddMemberModalProps {
  team: Team;
  open: boolean;
  onClose: () => void;
  onAdd: (emails: string[]) => void;
  defaultEmails?: string[];
}

export const AddMembersModal: React.FC<AddMemberModalProps> = ({
  team,
  open,
  onAdd,
  onClose,
  defaultEmails,
}) => {
  const [emails, setEmails] = useState(defaultEmails ?? []);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const userId = useSelector(selectUid);
  const onAddClick = useCallback(() => {
    if (!emails.length || isInvalid) return;
    onAdd(emails);
    setEmails([]);
  }, [emails, isInvalid, onAdd]);

  if (!open) return null;

  const isAdmin =
    team?.members?.some((m) => m.uid === userId && m.roles.ADMIN) ?? false;

  const canInvite = team.settings.enableTeamInvite || isAdmin;

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={
        <FormattedMessage
          defaultMessage="Invite members to your team"
          id="LTDlkC"
          description="Team view. Add members dialog title"
        />
      }
      text={
        !canInvite ? (
          <Alert
            severity="info"
            title={
              <FormattedMessage
                defaultMessage="Only admins can invite to the team"
                id="1OzVs7"
              />
            }
            description={
              <FormattedMessage
                defaultMessage="Only an admin can invite members to the team. Ask admin to send an invitation."
                id="b2h9kF"
              />
            }
          />
        ) : (
          <div className="flex flex-col gap-2">
            <TeamInvitationLink team={team} />

            <h3 className="text-lg">
              <FormattedMessage
                defaultMessage="Invite by email"
                description="Team invitation by email. Title."
                id="TYHQDJ"
              />
            </h3>
            <EmailsInput
              emails={emails}
              setEmails={setEmails}
              onChangeValidationState={setIsInvalid}
              actions={
                <Button color="primary" onClick={onAddClick}>
                  <FormattedMessage
                    defaultMessage="Send invite"
                    id="5/uKKC"
                    description="Team view. Add members dialog. Add button title"
                  />
                </Button>
              }
            />
          </div>
        )
      }
      actions={
        <Button onClick={onClose}>
          <FormattedMessage
            defaultMessage="Cancel"
            id="xzQXp4"
            description="Team view. Add members dialog. Cancel button title"
          />
        </Button>
      }
    />
  );
};
