import React from 'react';
import { GDriveScreenshot } from './GDriveScreenshot';
import { TactiqScreenshot } from './TactiqScreenshot';

export const Screenshot: React.FC<{
  timestamp: number;
  link: string;
}> = ({ timestamp, link }) => {
  if (link.startsWith('https://drive.google.com')) {
    return <GDriveScreenshot link={link} />;
  } else {
    return <TactiqScreenshot timestamp={timestamp} />;
  }
};
