import { LabelConfig } from '@tactiq/model';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeetingSearchResultHit } from '../../../graphql/operations';
import {
  selectIsProUser,
  selectTeam,
  selectUid,
  selectUserTags,
} from '../../../redux/selectors';
import { MeetingCardImpl } from './MeetingCard';
import { removeMeetingLabel } from '../../../graphql/meetings';
import { isMeetingOwner } from '../../../helpers/meetings';
import { AnyMeeting } from '../../../models/meeting';
import { removeLabelFromMeeting } from '../../../redux/modules/global';
import { trackWebEvent } from '../../../helpers/analytics';

export interface MeetingCardProps {
  meeting: AnyMeeting;
  highlights?: MeetingSearchResultHit['highlights'];
  readOnly?: boolean;
}

export const MeetingCard: React.FC<MeetingCardProps> = (props) => {
  const userId = useSelector(selectUid);
  const isPaidUser = useSelector(selectIsProUser);
  const userTags = useSelector(selectUserTags);
  const dispatch = useDispatch();
  const team = useSelector(selectTeam);
  const onRemoveLabel = async (label: LabelConfig) => {
    dispatch(
      removeLabelFromMeeting({ meetingId: props.meeting.id, labelId: label.id })
    );
    await removeMeetingLabel(props.meeting.id, label.id);
    trackWebEvent('Transcript Label Removed', { team_id: team?.id });
  };
  const isOwner = isMeetingOwner(userId, props.meeting);

  return (
    <MeetingCardImpl
      {...props}
      isOwner={isOwner}
      isPaidUser={isPaidUser}
      userTags={userTags}
      onRemoveLabel={onRemoveLabel}
    />
  );
};
