/* eslint-disable no-undef */

export interface Tag {
  icon: string;
  fieldName?: string;
  name: string;
  isSystem?: boolean;
}

export const SystemTag_Question: Tag = {
  icon: String.fromCodePoint(10067), // ❓
  name: 'Question',
  isSystem: true,
};

export const SystemTags: Tag[] = [
  {
    icon: String.fromCodePoint(127919), // 🎯
    name: 'Decision',
    isSystem: true,
  },
  {
    icon: String.fromCodePoint(9989), // ✅
    name: 'Action Item',
    isSystem: true,
  },
  SystemTag_Question,
];

export const HIGHLIGHT_TAG: Tag = {
  icon: '✏️',
  name: 'Highlight',
};
