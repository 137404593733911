import { logger } from '@tactiq/model';
import { subscribeToUserUpdates } from '../../../graphql/user';
import { updateUserDataFromBackend } from '../../../redux/modules/user';
import { gotMeetingKits, setUserLoaded } from '../../../redux/modules/global';
import { AppDispatch } from '../../../redux/store';
import { ApolloClientFactory } from '../../../graphql/client';
import {
  UserFragmentFragmentDoc,
  UserUpdatesSubscription,
} from '../../../graphql/operations';

let unsubscribeFromUserUpdates: (() => void) | undefined;

export async function resubscribeToUserChanges(
  uid: string,
  dispatch: AppDispatch
): Promise<void> {
  function update(user: UserUpdatesSubscription['user']) {
    dispatch(updateUserDataFromBackend(user));
    dispatch(
      gotMeetingKits(
        JSON.parse(JSON.stringify(user?.meetingKits)) ?? {
          explore: [],
          used: [],
        }
      )
    );

    dispatch(setUserLoaded(true));
  }

  const client = await ApolloClientFactory.getClient();
  const cachedUser = client.readFragment({
    id: `User:${uid}`,
    fragment: UserFragmentFragmentDoc,
    fragmentName: 'UserFragment',
  });

  if (cachedUser) {
    update(cachedUser);
  }

  return new Promise((resolve, reject) => {
    // unsubscribe if subscription already exists
    if (unsubscribeFromUserUpdates) {
      unsubscribeFromUserUpdates();
    }

    unsubscribeFromUserUpdates = subscribeToUserUpdates(
      client,
      (user) => {
        update(user);
        resolve();
      },
      () => {
        logger.info('subscribeToUserUpdates complete');
      },
      (err) => {
        logger.error(err);
        reject(err);
      }
    );
  });
}
