import { useMutation } from '@apollo/client';
import { ArrowRightLeft } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import { RadioGroup, Radio } from '../../../components/Radio';
import { ModalDialog } from '../../../components/modals';
import {
  TeamMember,
  TeamMemberStatus,
  TransferSeatDocument,
} from '../../../graphql/operations';
import { gotTeam } from '../../../redux/modules/user';
import { selectTeam } from '../../../redux/selectors';

export const TransferSeatDialog: React.FC<{
  source: TeamMember;
  onClose: () => void;
}> = ({ source, onClose }) => {
  const team = useSelector(selectTeam);
  const options =
    team?.members.filter((m) => m.status === TeamMemberStatus.FREE) ?? [];
  const [selectedMember, setSelectedMember] = React.useState<string>(
    options[0].uid
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  const [transferSeat, transferSeatMutation] =
    useMutation(TransferSeatDocument);

  const isLoading = transferSeatMutation.loading;

  return (
    <ModalDialog
      open
      size="small"
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
      }}
      title={
        <FormattedMessage
          defaultMessage="Transfer paid team seat"
          id="/4C+XW"
        />
      }
      text={
        <div className="flex flex-col gap-2">
          <div className="text-sm">
            <FormattedMessage
              defaultMessage="This change incurs no additional charges."
              id="xSwq/r"
            />
          </div>
          {!isLoading && (
            <RadioGroup value={selectedMember} onChange={setSelectedMember}>
              {options.map((member) => (
                <Radio
                  value={member.uid}
                  key={member.uid}
                  label={member.displayName ?? member.email}
                />
              ))}
            </RadioGroup>
          )}
        </div>
      }
      actions={
        <div className="flex flex-row items-center justify-end gap-2">
          <Button onClick={onClose} variant="secondaryOutline">
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
          <Button
            loading={isLoading}
            onClick={async () => {
              if (!selectedMember) {
                enqueueSnackbar(
                  intl.formatMessage(
                    {
                      defaultMessage: 'Please select a member',
                      id: 'RLrXCZ',
                    },
                    {}
                  ),
                  {
                    variant: 'ERROR',
                  }
                );
                return;
              }

              dispatch(
                gotTeam(
                  (
                    await transferSeat({
                      variables: {
                        input: {
                          sourceUid: source.uid,
                          targetUid: selectedMember,
                        },
                      },
                    })
                  ).data?.TransferSeat
                )
              );

              onClose();
            }}
            startIcon={<ArrowRightLeft className="h-5 w-5" />}
          >
            <FormattedMessage defaultMessage="Transfer" id="DtYelJ" />
          </Button>
        </div>
      }
    />
  );
};
