import React from 'react';
import { ButtonRequestAllPermissions } from './ButtonRequestAllPermissions';
import { Alert } from '../../components/Alert';
import { FormattedMessage } from 'react-intl';

export const AlertNeedMorePermissions: React.FC = () => {
  return (
    <Alert
      severity="warning"
      action={
        <ButtonRequestAllPermissions
          event="Setup Helper - Fix Permissions"
          label="Fix"
        />
      }
      description={
        <FormattedMessage
          defaultMessage="Tactiq needs additional permissions"
          id="PHFnIY"
        />
      }
    />
  );
};
