import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl, IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../../components/Alert';
import { Switch } from '../../../components/buttons/Switch';
import { EditableText } from '../../../components/TextInput/EditableText';
import {
  UpdateDisplayNameDocument,
  UpdateTeamSetting_DisableSelfJoinDomainDocument,
  UpdateTeamSetting_EnableEmailLinkSignInDocument,
  UpdateTeamSetting_EnableLlmDocument,
  UpdateTeamSetting_EnableTeamInviteDocument,
  UpdateTeamSetting_ResetMeetingsRetentionPeriodDocument,
  UpdateTeamSetting_SetMeetingsRetentionPeriodDocument,
} from '../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackWebEvent,
  trackWebPage,
} from '../../../helpers/analytics';
import { gotTeam } from '../../../redux/modules/user';
import {
  selectTeam,
  selectUid,
  selectUserTier,
} from '../../../redux/selectors';
import { SettingsRow } from '../../Common/SettingsRow';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { Link } from '../../../components/Link';
import { Select } from '../../../components/Select';

const InfoAlert: React.FC<{
  isTeam: boolean;
  isPaidTeam: boolean;
  isAdmin: boolean;
}> = ({ isTeam, isPaidTeam, isAdmin }) => {
  if (!isTeam) {
    // Not in team -> Settings disabled + encourage alert to create paid team
    return (
      <Alert
        severity="info"
        description="These settings are only available for paid team plans. You will need to create a paid team to use this feature."
      />
    );
  } else if (isTeam && !isPaidTeam) {
    // Not in paid team -> Settings disabled + encourage alert to upgrade to paid team
    return (
      <Alert
        severity="info"
        description="If you would like to activate this feature you will need to upgrade to a paid team."
      />
    );
  } else if (isPaidTeam && !isAdmin) {
    // Not an admin -> Settings disabled + encourage alert to ask admin to change settings
    return (
      <Alert
        severity="info"
        description="The settings on this page are controlled by your team admin. If you would like a setting changed you will need to contact them."
      />
    );
  }
  // In paid team and admin -> Settings enabled
  else if (isPaidTeam && isAdmin) {
    return (
      <Alert
        severity="info"
        title="Ahoy captain!"
        description="Changes you make on this page will apply to all members of your team."
      />
    );
  } else return null;
};

function UserCannotChangeSettings(intl: IntlShape) {
  return enqueueSnackbar(
    intl.formatMessage({
      defaultMessage: 'Only team admins can change this setting',
      id: 'SwQ8Tt',
    }),
    { variant: 'WARNING' }
  );
}

const RetentionSettings: React.FC = () => {
  const intl = useIntl();
  const userId = useSelector(selectUid);
  const team = useSelector(selectTeam);
  const isAdmin =
    (team && team.members.some((m) => m.uid === userId && m.roles.ADMIN)) ??
    false;

  const dispatch = useDispatch();

  const [
    UpdateTeamSetting_SetMeetingsRetentionPeriod,
    UpdateTeamSetting_SetMeetingsRetentionPeriodMutation,
  ] = useMutation(UpdateTeamSetting_SetMeetingsRetentionPeriodDocument);

  const [
    UpdateTeamSetting_ResetMeetingsRetentionPeriod,
    UpdateTeamSetting_ResetMeetingsRetentionPeriodMutation,
  ] = useMutation(UpdateTeamSetting_ResetMeetingsRetentionPeriodDocument);

  if (!team?.plan?.isCustomContract) return null;

  const retentionDays = team?.settings.meetingsRetentionPeriodDays;
  const isRetentionEnabled = !!retentionDays;

  const isLoading =
    UpdateTeamSetting_SetMeetingsRetentionPeriodMutation.loading ||
    UpdateTeamSetting_ResetMeetingsRetentionPeriodMutation.loading;

  const DAYS_IN_A_MONTH = 30;
  const PossibleMonths = [...Array(55).keys()].map((i) => 6 + i); // 6 months to 5 years

  return (
    <>
      <SettingsRow
        settingInfo={{
          title: 'Use default data retention settings',
          description: (
            <FormattedMessage
              defaultMessage="By default, transcripts of all members of your team are kept forever."
              id="6MfuO8"
            />
          ),
        }}
        settingAction={
          <Switch
            disabled={isLoading}
            isOn={!isRetentionEnabled}
            onClick={async () => {
              if (!isAdmin) {
                UserCannotChangeSettings(intl);
              } else {
                if (isRetentionEnabled) {
                  // disable retention
                  trackWebEvent(
                    'Settings - Team - Toggled Use default data retention settings'
                  );
                  dispatch(
                    gotTeam(
                      (
                        await UpdateTeamSetting_ResetMeetingsRetentionPeriod({
                          variables: {},
                        })
                      ).data?.UpdateTeamSetting_ResetMeetingsRetentionPeriod
                    )
                  );
                } else {
                  // enable retention, set to default 1 year
                  trackWebEvent(
                    'Settings - Team - Toggled Off use default data retention settings'
                  );
                  dispatch(
                    gotTeam(
                      (
                        await UpdateTeamSetting_SetMeetingsRetentionPeriod({
                          variables: {
                            input: {
                              value: 36 * DAYS_IN_A_MONTH,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_SetMeetingsRetentionPeriod
                    )
                  );
                }
              }
            }}
          />
        }
      />
      {isRetentionEnabled ? (
        <SettingsRow
          settingInfo={{
            title: 'Use custom retention period',
            description: (
              <>
                <FormattedMessage
                  defaultMessage="Archive all transcripts of all members of your team after a set amount of time."
                  id="E8R5yh"
                />
                {retentionDays < DAYS_IN_A_MONTH * 12 ? (
                  <div className="mt-2">
                    <Alert
                      severity="warning"
                      description="You have a short retention period that may negatively impact your team members."
                    />
                  </div>
                ) : null}
              </>
            ),
          }}
          settingAction={
            <Select
              value={retentionDays}
              disabled={isLoading}
              onChange={async (value) => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                  return;
                }
                trackWebEvent('Settings - Custom retention value selected', {
                  value,
                });
                dispatch(
                  gotTeam(
                    (
                      await UpdateTeamSetting_SetMeetingsRetentionPeriod({
                        variables: {
                          input: {
                            value,
                          },
                        },
                      })
                    ).data?.UpdateTeamSetting_SetMeetingsRetentionPeriod
                  )
                );
              }}
              options={PossibleMonths.map((month) => ({
                value: month * DAYS_IN_A_MONTH,
                label: intl.formatMessage(
                  {
                    defaultMessage: '{month} months',
                    id: 'AlFJm7',
                  },
                  { month }
                ),
              }))}
            />
          }
        />
      ) : null}
    </>
  );
};

export const TeamSettings: React.FC = () => {
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const isPaidTeam = team?.isPaid;
  const isAdmin =
    (team && team.members.some((m) => m.uid === userId && m.roles.ADMIN)) ??
    false;

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    trackWebPage('Team Settings');
  }, []);

  const [
    updateTeamSetting_EnableEmailLinkSignIn,
    updateTeamSetting_EnableEmailLinkSignInMutation,
  ] = useMutation(UpdateTeamSetting_EnableEmailLinkSignInDocument);

  const [updateTeamSetting_EnableLLM, updateTeamSetting_EnableLLMMutation] =
    useMutation(UpdateTeamSetting_EnableLlmDocument);

  const [
    updateTeamSetting_DisableSelfJoinDomain,
    updateTeamSetting_DisableSelfJoinDomainMutation,
  ] = useMutation(UpdateTeamSetting_DisableSelfJoinDomainDocument);

  const [
    updateTeamSetting_EnableTeamInvite,
    updateTeamSetting_EnableTeamInviteMutation,
  ] = useMutation(UpdateTeamSetting_EnableTeamInviteDocument);

  const [updateDisplayName] = useMutation(UpdateDisplayNameDocument);

  return (
    <div className="flex flex-col">
      <div className="mt-4">
        <InfoAlert
          isAdmin={isAdmin}
          isPaidTeam={isPaidTeam ?? false}
          isTeam={!!team}
        />
      </div>
      <ul className="flex flex-col divide-y divide-gray-100">
        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Enable sign in with email magic link',
              id: 'RjzYXU',
            }),
            description: (
              <FormattedMessage
                defaultMessage="This will allow your team members to sign in to Tactiq by getting an email with a one-time link."
                id="S8Cozn"
              />
            ),
          }}
          requiresUpgrade={{
            condition: !isPaidTeam,
            reason: 'Needs team plan',
            action: () => setShowTierPricingDialog(true),
          }}
          settingAction={
            <Switch
              disabled={updateTeamSetting_EnableEmailLinkSignInMutation.loading}
              isOn={team?.settings.enableEmailLinkSignIn}
              onClick={async () => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                } else {
                  trackWebEvent(
                    'Settings - Team - Toggled Enable Email Link Sign In',
                    {
                      enabled: !team?.settings.enableEmailLinkSignIn,
                    }
                  );
                  dispatch(
                    gotTeam(
                      (
                        await updateTeamSetting_EnableEmailLinkSignIn({
                          variables: {
                            input: {
                              value: !team?.settings.enableEmailLinkSignIn,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_EnableEmailLinkSignIn
                    )
                  );
                }
              }}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Enable AI features (Large Language Models)',
              id: 'AEENT9',
              description: 'Setting description',
            }),
            description: (
              <FormattedMessage
                defaultMessage="If disabled, this overrides the settings of the individual team members. This gives you access to AI-generated summaries, Meeting Kits, and more. We use OpenAI GPT API for generation. They do not store your data and do not use it to improve their models. Read more at {link}"
                id="AvOQ1T"
                description="Setting description"
                values={{
                  link: (
                    <Link blue target="_blank" to="https://tactiq.io/gpt">
                      https://tactiq.io/gpt
                    </Link>
                  ),
                }}
              />
            ),
          }}
          requiresUpgrade={{
            condition: !isPaidTeam,
            reason: 'Needs team plan',
            action: () => setShowTierPricingDialog(true),
          }}
          settingAction={
            <Switch
              disabled={updateTeamSetting_EnableLLMMutation.loading}
              onClick={async () => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                } else {
                  trackWebEvent('Settings - Team - Toggled Enable LLM', {
                    enabled: !team?.settings.enableLLM,
                  });
                  dispatch(
                    gotTeam(
                      (
                        await updateTeamSetting_EnableLLM({
                          variables: {
                            input: {
                              value: !team?.settings.enableLLM,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_EnableLLM
                    )
                  );
                }
              }}
              isOn={team?.settings.enableLLM}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Block Auto-Join for Same Domain',
              id: 'ZjX519',
            }),
            description: (
              <FormattedMessage
                defaultMessage="Enable this setting to prevent users with the same email domain from automatically being added to your team."
                id="biV1qU"
              />
            ),
          }}
          settingAction={
            <Switch
              disabled={updateTeamSetting_DisableSelfJoinDomainMutation.loading}
              isOn={team?.settings.disableSelfJoinDomain}
              onClick={async () => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                } else {
                  trackWebEvent(
                    'Settings - Team - Toggled Allow Self Join Domain',
                    {
                      enabled: team?.settings.disableSelfJoinDomain,
                    }
                  );
                  dispatch(
                    gotTeam(
                      (
                        await updateTeamSetting_DisableSelfJoinDomain({
                          variables: {
                            input: {
                              value: !team?.settings.disableSelfJoinDomain,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_DisableSelfJoinDomain
                    )
                  );
                }
              }}
            />
          }
        />
        <SettingsRow
          requiresUpgrade={{
            condition: !isPaidTeam,
            reason: 'Needs team plan',
            action: () => setShowTierPricingDialog(true),
          }}
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Restrict Non-Admin Invitations',
              id: '+4+Zbu',
            }),
            description: (
              <FormattedMessage
                defaultMessage="Enable this setting to limit the ability to invite new members to the team exclusively to admin users."
                id="Pslcjk"
              />
            ),
          }}
          settingAction={
            <Switch
              disabled={updateTeamSetting_EnableTeamInviteMutation.loading}
              isOn={team?.settings.enableTeamInvite}
              onClick={async () => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                } else {
                  trackWebEvent(
                    'Settings - Team - Toggled Allow allow team invite',
                    {
                      enabled: team?.settings.enableTeamInvite,
                    }
                  );
                  dispatch(
                    gotTeam(
                      (
                        await updateTeamSetting_EnableTeamInvite({
                          variables: {
                            input: {
                              value: !team?.settings.enableTeamInvite,
                            },
                          },
                        })
                      ).data?.UpdateTeamSetting_EnableTeamInvite
                    )
                  );
                }
              }}
            />
          }
        />
        <RetentionSettings />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Change display name',
              id: 'g2BWbe',
              description: 'Setting description',
            }),
            description: intl.formatMessage({
              defaultMessage: 'Change your team name as it appears in Tactiq',
              id: '/5/zKG',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <EditableText
              value={team ? team.displayName : ''}
              onClick={() => {
                if (!isAdmin) {
                  UserCannotChangeSettings(intl);
                  return false;
                }
                return true;
              }}
              onSave={async (newName: string) => {
                if (!team) {
                  return;
                }
                const oldName = team.displayName;
                dispatch(gotTeam({ ...team, displayName: newName }));
                try {
                  const response = await updateDisplayName({
                    variables: {
                      input: {
                        value: newName,
                      },
                    },
                  });
                  dispatch(gotTeam(response.data?.team_updateDisplayName));
                } catch (_) {
                  dispatch(gotTeam({ ...team, displayName: oldName }));
                }
              }}
              isValid={(s: string) => Boolean(s.trim())}
              errorLabel={intl.formatMessage({
                defaultMessage: 'Display name can not be empty',
                id: '6SOsH2',
                description: 'Setting description',
              })}
            />
          }
        />
      </ul>
      {showTierPricingDialog && (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.TEAM_SETTINGS}
          onClose={() => setShowTierPricingDialog(false)}
          teamSpecific
        />
      )}
    </div>
  );
};
