import { TeamPricing, PricingCurrency } from '../../../graphql/operations';
import { getCountryCode } from '../../../helpers/locale';
import { RootState } from '../../../redux/store';

export const getDefaultPricing = (
  userPricing: RootState['user']['pricing']
): TeamPricing | PricingCurrency => {
  const countryCode = getCountryCode();
  const currency = userPricing.team.currencies?.find(
    (c) => c.countryCode === countryCode
  );

  if (currency) {
    return currency;
  }

  return userPricing.team;
};
