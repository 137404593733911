import React, { PropsWithChildren } from 'react';

export const CenteredPanel: React.FC<
  PropsWithChildren<{
    actionButton?: React.ReactNode;
  }>
> = ({ children }) => {
  return (
    <div className="flex min-h-screen flex-row items-center justify-center">
      {children}
    </div>
  );
};
