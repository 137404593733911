import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingComponent from '../Landing/Loading/LoadingComponent';
import { useAsyncEffect } from 'use-async-effect';
import { fetchApiV2 } from '../../helpers/api/helpers';
import { selectUid } from '../../redux/selectors';
import { RootState } from '../../redux/store';

export const ProductHunt: React.FC = () => {
  const userId = useSelector(selectUid);
  const startedUp = useSelector((state: RootState) => state.global.startedUp);

  const [isLoading, setIsLoading] = useState(true);

  useAsyncEffect(async () => {
    if (startedUp && userId) {
      await fetchApiV2('/a/invitations/product-hunt', { method: 'POST' });

      setIsLoading(false);
    }
  }, [startedUp, userId]);

  if (!isLoading) {
    window.location.href =
      'https://help.tactiq.io/en/articles/6885674-gpt-3-for-your-meeting-transcript-in-tactiq';
  }

  return <LoadingComponent />;
};
