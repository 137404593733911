import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyCancellationCoupon,
  cancelSubscription,
} from '../../../graphql/billing';
import { selectTeam, selectUserPlan } from '../../../redux/selectors';
import { CancellationDialog as CancellationDialogImpl } from './CancellationDialog';
import { useMutation } from '@apollo/client';
import {
  ApplyTeamCancellationCouponDocument,
  CancelTeamSubscriptionDocument,
} from '../../../graphql/operations';
import { gotTeam } from '../../../redux/modules/user';

/**
 * Pro cancelation dialog
 * @param {unknown} param0 params
 * @param {boolean} param0.open open
 * @param {(doneSomething?: boolean) => void} param0.onClose close handler
 * @returns {React.FC} a component
 */
export const ProCancellationDialog: React.FC<{
  open: boolean;
  onClose: (doneSomething?: boolean) => void;
}> = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { paid: plan } = useSelector(selectUserPlan);
  const intl = useIntl();
  const isDiscountApplied =
    plan?.__typename === 'StripePaidPlan' && plan.percentOff === 50;

  const onCancel = useCallback(
    async (reason: string, reasonText: string) => {
      setIsLoading(true);

      await cancelSubscription(reason, reasonText);

      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Your subscription has been cancelled.',
          id: 'y8AsiP',
        }),
        { variant: 'SUCCESS' }
      );

      setIsLoading(false);

      onClose(true);
    },
    [intl, onClose]
  );

  const onApplyCoupon = useCallback(
    async (reason: string, reasonText: string) => {
      setIsLoading(true);

      await applyCancellationCoupon({ reason, reasonText });

      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage:
            'The coupon was successfully applied to your subscription.',
          id: '7t5npg',
        }),
        { variant: 'SUCCESS' }
      );

      setIsLoading(false);

      onClose(true);
    },
    [intl, onClose]
  );

  if (
    !plan ||
    (plan.__typename !== 'StripePaidPlan' &&
      plan.__typename !== 'PaypalPaidPlan')
  ) {
    return null;
  }

  return (
    <CancellationDialogImpl
      open={open}
      plan={plan}
      isLoading={isLoading}
      isDiscountApplied={isDiscountApplied}
      isAnnual={plan.recurrenceInterval === 'year'}
      isTeam={false}
      onClose={onClose}
      onCancel={onCancel}
      onApplyCoupon={onApplyCoupon}
    />
  );
};

/**
 * Team cancelation dialog
 * @param {unknown} param0 params
 * @param {boolean} param0.open open
 * @param {(doneSomething?: boolean) => void} param0.onClose close handler
 * @returns {React.FC} a component
 */
export const TeamCancellationDialog: React.FC<{
  open: boolean;
  onClose: (doneSomething?: boolean) => void;
}> = ({ open, onClose }) => {
  const { plan } = useSelector(selectTeam) ?? {};
  const intl = useIntl();
  const dispatch = useDispatch();
  const isDiscountApplied = plan?.percentOff === 50;
  const [cancelTeamSubscription, cancelTeamSubscriptionMutation] = useMutation(
    CancelTeamSubscriptionDocument
  );
  const [applyTeamCancellationCoupon, applyTeamCancellationCouponMutation] =
    useMutation(ApplyTeamCancellationCouponDocument);

  const isLoading =
    cancelTeamSubscriptionMutation.loading ||
    applyTeamCancellationCouponMutation.loading;

  const onCancel = useCallback(
    async (reason: string, reasonText: string) => {
      dispatch(
        gotTeam(
          (
            await cancelTeamSubscription({
              variables: { input: { reason, reasonText } },
            })
          ).data?.team_billing_cancelSubscription
        )
      );

      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Your team subscription has been cancelled.',
          id: 'Wc8pt6',
        }),
        { variant: 'SUCCESS' }
      );

      onClose(true);
    },
    [cancelTeamSubscription, dispatch, intl, onClose]
  );

  const onApplyCoupon = useCallback(
    async (reason: string, reasonText: string) => {
      dispatch(
        gotTeam(
          (
            await applyTeamCancellationCoupon({
              variables: { input: { reason, reasonText } },
            })
          ).data?.team_billing_applyCancellationCoupon
        )
      );

      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage:
            'The coupon was successfully applied to your subscription.',
          id: '7t5npg',
        }),
        { variant: 'SUCCESS' }
      );

      onClose(true);
    },
    [applyTeamCancellationCoupon, dispatch, intl, onClose]
  );

  if (!plan) {
    return null;
  }

  return (
    <CancellationDialogImpl
      open={open}
      plan={plan}
      isLoading={isLoading}
      isDiscountApplied={isDiscountApplied}
      isAnnual={plan.recurrenceInterval === 'year'}
      isTeam
      onClose={onClose}
      onCancel={onCancel}
      onApplyCoupon={onApplyCoupon}
    />
  );
};
