import React from 'react';
import { cx } from '../../../../helpers/utils';
import { FormattedMessage } from 'react-intl';
import { Sparkles, X } from 'lucide-react';
import { Button } from '../../../../components/buttons';
import { trackWebEvent } from '../../../../helpers/analytics';

type AISummaryPreviewExperimentProps = {
  participantNames: string[];
  onClose: () => void;
  onGenerateSummary: () => void;
};

type TextSkeletonProps = {
  count: number;
};

const tailwindWidthClasses = [
  'w-4',
  'w-5',
  'w-6',
  'w-7',
  'w-8',
  'w-9',
  'w-10',
  'w-11',
  'w-12',
  'w-14',
  'w-16',
  'w-20',
  'w-24',
  'w-28',
  'w-32',
  'w-36',
  'w-40',
];

const randomWidth = () => {
  return tailwindWidthClasses[
    Math.floor(Math.random() * tailwindWidthClasses.length) // NOSONAR
  ];
};

const TextSkeleton: React.FC<TextSkeletonProps> = ({ count }) => (
  <div className="flex flex-wrap gap-x-1 gap-y-2">
    {Array.from({ length: count }).map((_, index) => (
      <div
        key={index}
        className={cx('h-3 rounded-md bg-gray-200', randomWidth())}
      ></div>
    ))}
  </div>
);

export const AISummaryPreviewExperiment: React.FC<
  AISummaryPreviewExperimentProps
> = ({ participantNames, onClose, onGenerateSummary }) => {
  const [name1, name2, name3] = participantNames;

  const getSummaryLabel = () => {
    switch (participantNames.length) {
      case 2:
        return (
          <FormattedMessage
            defaultMessage="During the meeting, {name1} and {name2} discussed:"
            values={{ name1, name2 }}
            id="/IBBTB"
          />
        );
      case 3:
        return (
          <FormattedMessage
            defaultMessage="During the meeting, {name1}, {name2}, and {name3} discussed:"
            values={{ name1, name2, name3 }}
            id="m/FTyu"
          />
        );
      default:
        return (
          <FormattedMessage
            defaultMessage="During the meeting, {name1}, {name2} and {otherCount} other people discussed:"
            values={{ name1, name2, otherCount: participantNames.length - 2 }}
            id="QnAkFT"
          />
        );
    }
  };

  const listItem = (count: number) => (
    <li className="flex items-center space-x-3">
      <div className="h-2 w-2 rounded-full bg-gray-200"> </div>
      <TextSkeleton count={count} />
    </li>
  );

  return (
    <div className="relative mt-6 space-y-4 rounded-xl border bg-indigo-50 px-8 py-6 shadow-sm">
      <div className="space-y-3">
        <p className="text-sm font-semibold text-gray-500">
          {getSummaryLabel()}
        </p>
        <TextSkeleton count={30} />
      </div>
      <div className="space-y-3">
        <p className="text-sm font-semibold text-gray-500">
          <FormattedMessage
            defaultMessage="Action items for {name1}:"
            id="ABu4qE"
            values={{ name1 }}
          />
        </p>
        <ul className="space-y-2 pl-2">
          {listItem(6)}
          {listItem(4)}
        </ul>
      </div>
      <div className="space-y-3">
        <p className="text-sm font-semibold text-gray-500">
          <FormattedMessage
            defaultMessage="Action items for {name2}:"
            id="o+Jq2w"
            values={{ name2 }}
          />
        </p>
        <ul className="space-y-2 pl-2">
          {listItem(6)}
          {listItem(4)}
        </ul>
      </div>

      {/* dialogue */}
      <div className="absolute left-1/2 top-1/2 mb-4 w-[60%] -translate-x-1/2 -translate-y-[60%] transform space-y-4 rounded-lg border bg-white px-6 py-5 shadow-xl">
        {/* title */}
        <div className="flex items-center justify-between">
          <p className="text-md font-semibold text-gray-700">
            <FormattedMessage
              defaultMessage="Generate AI summary of this meeting!"
              id="KNL4u8"
            />
          </p>
          <Button
            variant="naked"
            size="small"
            onClick={() => {
              onClose();
              trackWebEvent('Exp AI Preview - Dialogue Closed');
            }}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>

        {/* CTAs */}
        <div className="flex flex-wrap justify-start gap-3 overflow-hidden">
          <Button
            startIcon={<Sparkles />}
            onClick={() => {
              onGenerateSummary();
              onClose();
              trackWebEvent('Exp AI Preview - Summary Generated');
            }}
          >
            <FormattedMessage defaultMessage="Generate now" id="2mX+65" />
          </Button>
          <Button
            variant="light"
            startIcon={<Sparkles />}
            className="truncate"
            onClick={() => {
              onClose();
              trackWebEvent('Exp AI Preview - Custom Prompt Chosen');
            }}
          >
            <FormattedMessage
              defaultMessage="Start with a custom prompt"
              id="6RYTVm"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
