import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FullMeeting } from '../../../../models/meeting';
import TranscriptSection from '../TranscriptSection';

const VideoWrapperClasses = 'relative pb-[56.25%] &:not(:last-child):mb-5';

const IFrameClasses = 'absolute top-0 left-0 w-full h-full border-none';

const MeetingRecordings: React.FC<{
  recordings?: FullMeeting['recordings'];
}> = ({ recordings }) => {
  const videos = recordings?.GoogleMeet || [];

  if (!videos.length) {
    return null;
  }

  return (
    <TranscriptSection
      place="recordings"
      header={
        <FormattedMessage
          defaultMessage="Recording"
          id="zsj8xz"
          description="Meeting page. Recording section header."
        />
      }
    >
      {videos.map((recording) => (
        <div className={VideoWrapperClasses} key={recording.fileId}>
          <iframe
            title="Video from transcript"
            className={IFrameClasses}
            src={`https://drive.google.com/file/d/${recording.fileId}/preview`}
            width="640"
            height="480"
            allow="autoplay"
          />
        </div>
      ))}
    </TranscriptSection>
  );
};

export default MeetingRecordings;
