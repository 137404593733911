import { Immutable, RawBlock } from '@tactiq/model';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';
import { TranscriptChanges } from '../models/transcript';

export const changeTranscriptTextForBlocks = (
  sourceBlocks: RawBlock[],
  messageIds: string[],
  newTranscript: string
): TranscriptChanges | undefined => {
  const messageIdsSet = new Set(messageIds);

  if (messageIds.length === 1) {
    const block = sourceBlocks.find(
      ({ messageId }) => messageId === messageIds[0]
    );

    if (!block) {
      return;
    }

    return {
      oldMessageIds: messageIds,
      newBlocks: [
        {
          ...block,
          ...(block.originalTranscript
            ? {}
            : { originalTranscript: block.transcript }),
          transcript: newTranscript,
          version: Date.now(),
        },
      ],
      updatedAt: Date.now(),
    };
  } else {
    const updatedBlocks = sourceBlocks.filter((b) =>
      messageIdsSet.has(b.messageId)
    );

    if (!updatedBlocks.length) {
      return;
    }

    let isPinned = false;
    const tags: string[] = [];

    const deletedBlocks: RawBlock[] = [];

    const originalTranscript = updatedBlocks.reduce(
      (memo, block: Immutable<RawBlock>): string => {
        deletedBlocks.push(block as RawBlock);
        isPinned = isPinned || (block.isPinned ?? false);
        if (block.tags) {
          tags.push(...block.tags);
        }
        return `${memo ? memo + ' ' : ''}${block.transcript}`.replace(
          /\s+/g,
          ' '
        );
      },
      ''
    );

    return {
      oldMessageIds: messageIds,
      newBlocks: [
        ...deletedBlocks.map((b) => ({ ...b, isDeleted: true })),
        {
          ...omit(updatedBlocks[0], 'isDeleted'),
          messageId: uuid(),
          isPinned,
          tags: uniq(tags),
          originalTranscript,
          transcript: newTranscript,
          version: Date.now(),
        },
      ],
      updatedAt: Date.now(),
    };
  }
};
