import { stringify } from 'csv-stringify/browser/esm/sync';

export const saveAsCsv = (
  headers: string[],
  data: string[][],
  fileName: string
): void => {
  const csv = stringify([headers, ...data]);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
