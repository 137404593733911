import React from 'react';
import {
  MeetingKitItem,
  MeetingKitItemOutputType,
} from '../../graphql/operations';
import { Newspaper } from 'lucide-react';

export const getMeetingKitItemIcon = (
  item: MeetingKitItem,
  className?: string
): React.ReactElement => {
  switch (item.outputType) {
    case MeetingKitItemOutputType.TEXT:
      return <Newspaper className={className} />;
    case MeetingKitItemOutputType.MARKDOWN:
      return <Newspaper className={className} />;
    case MeetingKitItemOutputType.ACTION_ITEMS:
      return <Newspaper className={className} />;
  }
};
