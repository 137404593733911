import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { watchMeetings } from '../../helpers/api/meetings';
import {
  selectIsEnhancedPrivacyEnabled,
  selectUid,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { ApolloClientFactory } from '../../graphql/client';
import { logger } from '@tactiq/model';

export const MeetingListSubscriptionProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const uid = useSelector(selectUid);
  const isUserDataLoaded = useSelector(
    (state: RootState) => state.global.userLoaded
  );
  const isEnhancedPrivacyEnabled = useSelector(selectIsEnhancedPrivacyEnabled);

  const [client, setClient] =
    useState<(typeof ApolloClientFactory)['_client']>();
  useEffect(() => {
    async function init() {
      const apolloClient = await ApolloClientFactory.getClient();
      setClient(apolloClient);
    }

    init().catch(logger.error);
  });

  const myMeetings = useSelector(
    (state: RootState) => state.global.meetings.my
  );

  useEffect(() => {
    if (uid && isUserDataLoaded && client) {
      return watchMeetings(client, dispatch, myMeetings); // isEnhancedPrivacyEnabled);
    }
  }, [
    uid,
    isUserDataLoaded,
    isEnhancedPrivacyEnabled,
    dispatch,
    client,
    myMeetings,
  ]);

  return <>{children}</>;
};
