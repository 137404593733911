import React from 'react';
import { LabelsList } from './List';

export const Labels: React.FC = () => {
  return (
    <div className="my-4">
      <LabelsList />
    </div>
  );
};
