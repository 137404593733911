import { User } from 'firebase/auth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let fbq: any;

export function trackAppVisitor(u?: User | null): void {
  if (typeof fbq === 'function' && u) {
    fbq('init', '365608268404265', {
      em: u.email,
      fn: u.displayName?.split(' ', 2)[0] ?? '',
      ln: u.displayName?.split(' ', 2)[1] ?? '',
      external_id: u.uid,
    });

    fbq('track', 'PageView');
  }
}
