import { getToken } from './firebase/auth';

/**
 * HTTP POST to Api2 service
 * @deprecated Please use fetchApiV2 instead
 * @param {string} path needs to be prefixed with `a/` for authenticated endpoints and `u/` for unauthenticated
 * @param {object} body payload
 * @returns JSON
 */
export async function api2post(path: string, body: unknown): Promise<any> {
  const res = await fetch(`/api/2/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getToken()}`,
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error(`${res.status} ${res.statusText}`);
}
