import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { ShareModal } from './common/ShareModal';
import { ShareMeetingImpl } from './ShareMeetingImpl';
import { AllIntegrations } from '../../../Integration/integrations';
import { TranscriptBlock } from '../../../../models/meeting';
import { RawTranscript } from '@tactiq/model';
import { RootState } from '../../../../redux/store';
import { RecentDestination } from '../../../../models/integration';
import { ErrorBoundary } from '../../../Common/ErrorBoundary';
import { SharingError } from './Error';
import {
  TierPricingDialogSource,
  trackWebPage,
} from '../../../../helpers/analytics';
import { useFullMeeting } from '../../common/meeting-hooks';
import { MeetingReach } from '../../../../graphql/operations';
import { selectTeam, selectUserTier } from '../../../../redux/selectors';
import { TierPricingDialog } from '../../../Credits/TierPricing/TierPricingDialog';

interface Props {
  meetingId: string;
  rawTranscript: RawTranscript;
  blocks: TranscriptBlock[];
  onClose: () => void;
  integrationId?: string;
  emails: string[];
  reach?: MeetingReach;
}

export const ShareMeeting: React.FC<Props> = (props) => {
  const {
    meetingId,
    blocks,
    rawTranscript,
    onClose,
    integrationId,
    emails,
    reach,
  } = props;
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const intl = useIntl();
  const connections = useSelector((state: RootState) => state.user.connections);
  const userShares = useSelector((state: RootState) => state.user.shares);
  const meeting = useFullMeeting(meetingId);

  useEffect(() => {
    meetingId && trackWebPage('Share Transcript');
  }, [meetingId]);

  const possibleDestinations = [
    ...AllIntegrations.filter((x) => x.shareDescription),
  ];

  const userConnections = [
    {
      connectionKey: 'link',
      type: 'link',
      name: intl.formatMessage({
        defaultMessage: 'Shareable Link',
        id: 'MO1P+l',
        description: 'Shareable Link connection title',
      }),
    },
    ...(connections ?? []),
  ];

  const recentDestinations = (userShares ?? [])
    .map((x) => ({
      sharedAt: x.sharedAt,
      integration: AllIntegrations.find((i) => i.id === x.integrationId),
      connection: userConnections.find(
        (c) => c.connectionKey === x.connectionKey
      ),
      options: x.options,
    }))
    .filter((x, idx, arr) => {
      return (
        x.integration &&
        x.integration.id !== 'link' &&
        x.connection &&
        idx ===
          arr.findIndex((d) => {
            return (
              x.integration?.id === d.integration?.id &&
              x.connection?.connectionKey === d.connection?.connectionKey &&
              isEqual(x.options, d.options)
            );
          })
      );
    })
    .slice(0, 3) as RecentDestination[];

  if (!meeting) {
    return null;
  }

  const content = (
    <ErrorBoundary ErrorComponent={SharingError}>
      {!meeting.isPreview ? (
        <ShareMeetingImpl
          title={meeting.title}
          rawTranscript={rawTranscript}
          meeting={meeting}
          blocks={blocks}
          possibleDestinations={possibleDestinations}
          recentDestinations={recentDestinations}
          userConnections={userConnections}
          integrationId={integrationId}
          emails={emails}
          reach={reach}
        />
      ) : (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.MEETING_TRANSCRIPT}
          onClose={onClose}
        />
      )}
    </ErrorBoundary>
  );

  return integrationId ? (
    content
  ) : (
    <ShareModal onClose={onClose}>{content}</ShareModal>
  );
};
