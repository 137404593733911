import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/buttons';
import {
  UpdateUserSettingDocument,
  UserSettingsSharingParticipants,
} from '../../../../graphql/operations';
import { trackWebEvent } from '../../../../helpers/analytics';
import { useAsyncEffect } from 'use-async-effect';
import { ModalDialog } from '../../../../components/modals';

export interface AutomaticShareDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * Automatic Share Dialog
 * @param {unknown} param0 params
 * @param {boolean} param0.open open
 * @param {() => void} param0.onClose close handler
 * @returns {React.FC} a component
 */
export const AutomaticShareDialog: React.FC<AutomaticShareDialogProps> = ({
  open,
  onClose,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const [updateUserSetting] = useMutation(UpdateUserSettingDocument);

  useAsyncEffect(async () => {
    if (open) {
      trackWebEvent('Meeting view - Automatic share dialog - Viewed');
      await updateUserSetting({
        variables: {
          input: {
            lastAutomaticShareDialogTimestamp: Date.now(),
          },
        },
      });
    }
  }, [open, updateUserSetting]);

  const onCancel = () => {
    trackWebEvent(
      'Meeting view - Automatic share dialog - Cancel button - Clicked'
    );
    onClose();
  };

  const onEnable = useCallback(async () => {
    trackWebEvent(
      'Meeting view - Automatic share dialog - Enable button - Clicked'
    );

    setIsUpdating(true);

    await updateUserSetting({
      variables: {
        input: {
          sharing: {
            participants: UserSettingsSharingParticipants.AUTO,
          },
        },
      },
    });

    setIsUpdating(false);
    onClose();
  }, [onClose, updateUserSetting]);

  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={
        <FormattedMessage
          defaultMessage="Enable automatic sharing"
          id="hRDNtV"
        />
      }
      text={
        <div>
          <FormattedMessage
            defaultMessage="Would you like to enable automatic sharing for all future meetings?"
            id="XDMiYa"
          />
          <br />
          <FormattedMessage
            defaultMessage="You can change your choice later in settings."
            id="zhdcew"
          />
        </div>
      }
      actions={
        <div>
          <Button loading={isUpdating} variant="soft" onClick={onCancel}>
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
          <Button loading={isUpdating} onClick={onEnable}>
            <FormattedMessage defaultMessage="Enable" id="TqSTI0" />
          </Button>
        </div>
      }
    />
  );
};
