import React from 'react';
import { useSelector } from 'react-redux';
import { selectMentions } from '../../../../redux/selectors';
import { useFullMeeting } from '../../common/meeting-hooks';
import { Comment } from '../transcript/block/comment/editor/Comment';
import { CommentBox } from '../transcript/block/comment/Box';

/**
 * Transcript comment list
 * @param {unknown} param0 params
 * @param {string} param0.meetingId meeting id
 * @param {boolean} param0.canDelete can delete
 * @returns {React.FC} a component
 */
export const TranscriptCommentList: React.FC<{
  meetingId: string;
  canDelete: boolean;
}> = ({ meetingId }) => {
  const meeting = useFullMeeting(meetingId);
  const comments = [...(meeting?.comments ?? [])].filter(
    (c) => !c.relatedBlockId
  );
  comments.sort((a, b) => a.createdAt - b.createdAt);
  const mentions = useSelector(selectMentions);

  return (
    <div className="space-y-4">
      {comments.map((comment) => (
        <CommentBox key={comment.id} comment={comment} />
      ))}
      <Comment mentions={mentions} />
    </div>
  );
};
