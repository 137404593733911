import React, { useCallback, useEffect, useState } from 'react';
import { ModalDialog } from '../../../components/modals';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/buttons';
import { CreateSpaceDocument, TeamMember } from '../../../graphql/operations';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { kSpace } from '../../../helpers/routes';
import { SpaceNameAndIcon } from '../SpaceNameAndIcon';
import { AddMembers } from '../AddMembers';
import { trackWebEvent } from '../../../helpers/analytics';

type CreateSpaceDialogProps = {
  onClose: () => void;
};

const isValidValueInRequiredField = (value: string | Array<unknown>) => {
  if (typeof value === 'string') {
    return value.trim() !== '';
  } else if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
};

const ANALYTICS_EVENTS = {
  VIEWED_SPACE_CREATION_DIALOG: 'Viewed Space Creation Dialog',
  CANCEL_SPACE_CREATION: 'Cancel Space Creation',
  CONFIRM_SPACE_CREATION: 'Confirm Space Creation',
};

export const CreateSpaceDialog: React.FC<CreateSpaceDialogProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [spaceIcon, setSpaceIcon] = useState('💬');
  const [spaceName, setSpaceName] = useState('');
  const [isSpaceNameValid, setIsSpaceNameValid] = useState(true);

  const [selectedMembers, setSelectedMembers] = useState<Array<TeamMember>>([]);

  const [canAddMeetings, setCanAddMeetings] = useState(true);
  const [canManageMeetings, setCanManageMeetings] = useState(false);

  const [createSpace, createSpaceMutation] = useMutation(CreateSpaceDocument);

  useEffect(() => {
    trackWebEvent(ANALYTICS_EVENTS.VIEWED_SPACE_CREATION_DIALOG);
  }, []);

  const onSubmit = async () => {
    const isValidName = isValidValueInRequiredField(spaceName);
    setIsSpaceNameValid(isValidName);

    if (isValidName) {
      trackWebEvent(ANALYTICS_EVENTS.CONFIRM_SPACE_CREATION);

      const response = await createSpace({
        variables: {
          input: {
            name: spaceName,
            icon: spaceIcon,
            members: selectedMembers.map((user) => ({
              uid: user.uid,
              permissions: {
                ADD: canAddMeetings,
                MANAGE: canManageMeetings,
              },
            })),
          },
        },
      });

      // TODO: Error handing
      if (response.data?.createSpace.success) {
        const spaceId = response.data?.createSpace?.space?.id;
        if (spaceId != null) {
          navigate(kSpace.replace(':id', spaceId));
        }
        onClose();
      }
    }
  };

  const handleClose = useCallback(() => {
    trackWebEvent(ANALYTICS_EVENTS.CANCEL_SPACE_CREATION);
    onClose();
  }, [onClose]);

  return (
    <ModalDialog
      open
      onClose={handleClose}
      title={<FormattedMessage defaultMessage="Create Space" id="v3aFf1" />}
      size="small"
      actions={
        <div className="flex flex-1 justify-between">
          <Button onClick={onClose} variant="light" color="primary">
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
          <Button
            loading={createSpaceMutation.loading}
            onClick={onSubmit}
            variant="filled"
            color="primary"
          >
            <FormattedMessage defaultMessage="Create space" id="2bMVTP" />
          </Button>
        </div>
      }
    >
      <div className="mb-4">
        <SpaceNameAndIcon
          spaceIcon={spaceIcon}
          setSpaceIcon={setSpaceIcon}
          spaceName={spaceName}
          setSpaceName={setSpaceName}
          isSpaceNameValid={isSpaceNameValid}
        />
      </div>
      <AddMembers
        isPersonalSpace={true}
        label={intl.formatMessage({
          defaultMessage: 'Add members (optional)',
          id: 'Emai1l',
        })}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        canManageMeetings={canManageMeetings}
        canAddMeetings={canAddMeetings}
        setCanAddMeetings={setCanAddMeetings}
        setCanManageMeetings={setCanManageMeetings}
        excludedUsers={[]}
        onClose={handleClose}
      />
    </ModalDialog>
  );
};
