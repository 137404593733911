export function scrollToTimestamp(
  startTimestamp: number,
  endTimestamp: number
): void {
  const scrollEvent = new CustomEvent('scrollToTranscriptMessageByTimestamp', {
    detail: {
      startTimestamp,
      endTimestamp,
    },
  });
  document.dispatchEvent(scrollEvent);
}
