import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { trackWebPage } from '../../helpers/analytics';
import { Page } from '../Common/Navigation';
import { TeamSettings } from '../Teams/Team/TeamSettings';
import { Account } from './Account';
import { AutoReplace } from './AutoReplace';
import { GeneralSettings } from './general';
import { Labels } from './labels';
import { Tabs } from '../../components/Tabs';
import {
  Bookmark as TagsIcon,
  Settings,
  Shield,
  SpellCheck,
  Tags as LabelsIcon,
  Users,
} from 'lucide-react';
import { Tags } from './Tags';

enum SettingsCategory {
  general = 'general',
  labels = 'labels',
  tags = 'tags',
  'auto-correct' = 'auto-correct',
  account = 'account',
  team = 'team',
}

const SettingsComponent: React.FC = () => {
  const intl = useIntl();
  const { category } = useParams<{ category: SettingsCategory }>();
  const navigate = useNavigate();

  useEffect(() => {
    trackWebPage('Settings', {
      category,
    });
  }, [category]);

  const onChangeTab = useCallback(
    (newCategory: string) => {
      navigate(`/settings/${newCategory}`);
    },
    [navigate]
  );

  let content = null;

  switch (category) {
    case SettingsCategory.general:
      content = <GeneralSettings />;
      break;
    case SettingsCategory.labels:
      content = <Labels />;
      break;
    case SettingsCategory.tags:
      content = <Tags />;
      break;
    case SettingsCategory['auto-correct']:
      content = <AutoReplace />;
      break;
    case SettingsCategory.account:
      content = <Account />;
      break;
    case SettingsCategory.team:
      content = <TeamSettings />;
      break;
    default:
      content = <GeneralSettings />;
      break;
  }

  const tabs = [
    {
      name: (
        <div className="flex items-center gap-x-2">
          <Settings />
          <FormattedMessage defaultMessage="General" id="1iEPTM" />
        </div>
      ),
      id: SettingsCategory.general,
    },
    {
      name: (
        <div className="flex items-center gap-x-2">
          <Users />
          <FormattedMessage defaultMessage="Team" id="wsUmh9" />
        </div>
      ),
      id: SettingsCategory.team,
    },
    {
      name: (
        <div className="flex items-center gap-x-2">
          <LabelsIcon />
          <FormattedMessage defaultMessage="Labels" id="m3QEyd" />
        </div>
      ),
      id: SettingsCategory.labels,
    },
    {
      name: (
        <div className="flex items-center gap-x-2">
          <TagsIcon />
          <FormattedMessage defaultMessage="Tags" id="1EYCdR" />
        </div>
      ),
      id: SettingsCategory.tags,
    },
    {
      name: (
        <div className="flex items-center gap-x-2">
          <SpellCheck />
          <FormattedMessage defaultMessage="Auto-Correct" id="GOvZH5" />
        </div>
      ),
      id: SettingsCategory['auto-correct'],
    },
    {
      name: (
        <div className="flex items-center gap-x-2">
          <Shield />
          <FormattedMessage defaultMessage="Account" id="TwyMau" />
        </div>
      ),
      id: SettingsCategory.account,
    },
  ];

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Settings',
        id: 'XgWgdS',
        description: 'Menu bar text.',
      })}
    >
      <h1 className="text-3xl">
        <FormattedMessage
          defaultMessage="Settings"
          id="lIelbI"
          description="Page header."
        />
      </h1>
      <div className="my-4">
        <Tabs tabs={tabs} selectedTab={category} onChange={onChangeTab}></Tabs>
      </div>
      {content}
    </Page>
  );
};

export default SettingsComponent;
