import { logger } from '@tactiq/model';
import { Recycle } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncEffect } from 'use-async-effect';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/buttons';
import { Team } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { createTeamInvitationLink } from '../../../helpers/api';
import { baseURL } from '../../../helpers/firebase/config';
import { Spinner } from '../../../components/Spinner';

/**
 * Team invitation link
 * @param {unknown} param0 params
 * @param {Team} param0.team team
 * @returns {React.FC} a component
 */
export const TeamInvitationLink: React.FC<{ team: Team }> = ({ team }) => {
  const intl = useIntl();
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  const onCreateLink = useCallback(async () => {
    trackWebEvent('Team View - Invitation link create', { team_id: team?.id });
    setIsLoading(true);
    try {
      await createTeamInvitationLink(team.id);
    } catch (e) {
      setHasErrored(true);
    }

    setIsLoading(false);
  }, [team]);

  useAsyncEffect(async () => {
    if (!team.invitationLink && !isCreatingLink) {
      setIsCreatingLink(true);

      try {
        await onCreateLink();
      } catch (err) {
        logger.error(err);
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'Could not create team invitation link. Please chat with support.',
            id: 'RE6bKq',
          }),
          { variant: 'WARNING' }
        );
      }
    }
  }, [team, onCreateLink, isCreatingLink, intl]);

  const onCopyInvitationLink = useCallback(() => {
    if (!team.invitationLink) {
      return;
    }

    trackWebEvent('Team View - Invitation link copied', { team_id: team?.id });
    navigator.clipboard
      .writeText(`${baseURL}/x/u/l/${team.invitationLink.id}?type=team`)
      .catch(logger.error);
    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage: 'Invitation link copied to clipboard',
        id: 'iV75XP',
        description:
          'Team view - Invitation link copied to clipboard toast message',
      }),
      { variant: 'SUCCESS' }
    );
  }, [team, intl]);

  if (hasErrored) return null;

  return (
    <>
      <div className="flex flex-row">
        <h3 className="flex-1 text-lg">
          <FormattedMessage
            defaultMessage="Invitation link"
            description="Team invitation link. Title."
            id="OdFB/O"
          />
        </h3>
        <Button
          variant="outlined"
          loading={isLoading}
          onClick={() => onCreateLink()}
          size="small"
          startIcon={<Recycle className="h-4 w-4" />}
        >
          <FormattedMessage
            defaultMessage="Reset the link"
            id="JqptRB"
            description="Team invitation link. Re-generate button tooltip text."
          />
        </Button>
      </div>

      {team.invitationLink ? (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <TextInput
              value={`${baseURL}/x/u/l/${team.invitationLink.id}?type=team`}
              inputProps={{
                onFocus: (event) => event.target.select(),
              }}
            />
            <Button size="small" onClick={onCopyInvitationLink}>
              <FormattedMessage
                defaultMessage="Copy link"
                description="Team invitation link. Copy button title."
                id="tCwPPj"
              />
            </Button>
          </div>
        </div>
      ) : (
        <Spinner className="p-4" />
      )}
    </>
  );
};
