import React, { ReactElement, useEffect, useState } from 'react';
import { Page } from '../Common/Navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/buttons';
import { Copy, EllipsisVertical, History, Plus, X } from 'lucide-react';
import { Table } from '../../components/Table';
import { DeadEnd } from '../../components/DeadEnd';
import { Menu } from '../../components/Menu';
import {
  useWorkflowArchive,
  useWorkflowDuplicate,
  useWorkflowList,
} from '../../services/Workflow';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router';
import { ConfirmActionDialog } from '../../components/modals';
import {
  trackDeletedWorkflow,
  trackWorkflowEditorOpened,
  trackWorkflowHistoryOpened,
  trackWorkflowProvideFeedbackButton,
  viewWorkflowLibraryPage,
  workflowListViewed,
} from '../../helpers/analytics';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../components/ToggleButtonGroup';
import { WorkflowsType } from '../../graphql/operations';
import { Avatar } from '../../components/Avatar';
import { Tooltip } from '../../components/Tooltip';
import { WorkflowDiscoveryModal } from './WorkflowDiscovery';

export default function WorkflowList(): ReactElement {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: 'My Workflows',
    id: 'KbEin0',
  });
  const [type, setType] = useState(WorkflowsType.ALL);
  const types = React.useMemo(
    () => [
      {
        id: WorkflowsType.ALL,
        name: intl.formatMessage({ defaultMessage: 'All', id: 'zQvVDJ' }),
      },
      {
        id: WorkflowsType.TEAM,
        name: intl.formatMessage({ defaultMessage: 'Team', id: 'wsUmh9' }),
      },
      {
        id: WorkflowsType.MINE,
        name: intl.formatMessage({ defaultMessage: 'Mine', id: 'snC7V6' }),
      },
      {
        id: WorkflowsType.USED,
        name: intl.formatMessage({ defaultMessage: 'Used', id: 'XYkq4n' }),
      },
    ],
    [intl]
  );

  React.useEffect(() => {
    viewWorkflowLibraryPage();
  }, []);

  return (
    <Page title={title} grow maxWidth={'lg'}>
      <div className="mb-4 flex flex-wrap items-center justify-between gap-y-4">
        <div>
          <h1 className="mb-2 text-3xl font-bold tracking-tight">{title}</h1>
          <p className="text-slate-500">
            <FormattedMessage
              defaultMessage="Create and manage your workflow automations here"
              id="mgM09X"
            />
          </p>
        </div>
        <div className="flex items-center gap-x-4">
          <ProvideFeedbackButton />
          <CreateWorkflowButton />
        </div>
      </div>
      <div className="my-6 flex items-center gap-3">
        <ToggleButtonGroup value={type} onChange={setType} style="disconnected">
          {types.map((type) => (
            <ToggleButton key={type.id} value={type.id}>
              {type.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <WorkflowTable type={type} />
    </Page>
  );
}

function WorkflowTable({ type }: { type: WorkflowsType }): ReactElement {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useWorkflowList({
    input: { type },
  });
  const archive = useWorkflowArchive();
  const [archiveId, setArchiveId] = useState<null | string>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (data?.workflows) {
        workflowListViewed({
          type,
          workflowsCount: data?.workflows?.length,
        });
      }
    }
  }, [data?.workflows, data?.workflows?.length, loading, type]);

  if (error) throw error;
  if (!data?.workflows && loading)
    return <Spinner className="m-auto my-16" size="1.5rem" />;

  const workflows = (data?.workflows ?? []).filter((ii) => !ii.isArchived);

  if (workflows.length === 0)
    return (
      <div className="my-16">
        <DeadEnd
          title={formatMessage({
            defaultMessage: 'Create your first workflow',
            id: 'LhWBrL',
          })}
          description={formatMessage({
            defaultMessage:
              'Streamline your after meeting workflow by automating tasks. Use Tactiq Al prompts and connect with third-party apps to setup triggers and actions',
            id: 'uW0puE',
          })}
          action={<CreateWorkflowButton />}
        />
      </div>
    );
  return (
    <>
      <Table
        initialPageSize={100}
        onRowClick={(row) => navigate(`/workflows/${row.id}`)}
        columns={[
          {
            accessorKey: 'name',
            enableResizing: true,
            header: formatMessage({ defaultMessage: 'Name', id: 'HAlOn1' }),
            enableSorting: true,
            cell: ({ row }) => (
              <a
                className="sticky max-w-[14rem] truncate text-sm font-medium text-slate-700 group-hover/row:underline lg:max-w-[22rem] xl:max-w-[28rem]"
                onClick={() => {
                  trackWorkflowEditorOpened({ workflowId: row.id });
                  navigate(`/workflows/${row.original.id}`);
                }}
              >
                {row.original.name}
              </a>
            ),
          },
          {
            accessorKey: 'owner',
            enableResizing: true,
            header: formatMessage({ defaultMessage: 'Owner', id: 'zINlao' }),
            enableSorting: true,
            cell: ({ row }) => (
              <span className="flex shrink-0 items-center gap-2 text-sm text-slate-500">
                <Avatar
                  name={row.original.createdBy.displayName}
                  src={row.original.createdBy.avatarUrl}
                  size="sm"
                />
                {row.original.createdBy.displayName}
              </span>
            ),
          },
          {
            accessorKey: 'triggerType',
            header: formatMessage({
              defaultMessage: 'Trigger type',
              id: '6Vngei',
            }),
            enableSorting: true,
            enableResizing: true,
            size: 100,
            maxSize: 150,
            cell: () => (
              <div className="inline-flex rounded-full border border-slate-200 bg-white px-2 py-0.5 text-xs font-medium text-slate-500">
                Manual
              </div>
            ),
          },
          {
            id: 'actions',
            meta: {
              stopEventPropagation: true,
              align: 'right',
            },
            size: 80,
            cell: ({ row }) => (
              <div className="sticky inline-flex items-center justify-end gap-x-3">
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <FormattedMessage defaultMessage="History" id="djJp6c" />
                  }
                >
                  <Button
                    variant="icon"
                    size="small"
                    onClick={() => {
                      trackWorkflowHistoryOpened({ workflowId: row.id });
                      navigate(`/workflows/${row.original.id}/activity`);
                    }}
                  >
                    <History size="1rem" />
                  </Button>
                </Tooltip>
                <DuplicateWorkflowButton id={row.original.id} />

                <Menu>
                  <Menu.Trigger>
                    <Button variant="naked">
                      <EllipsisVertical size="1rem" />
                    </Button>
                  </Menu.Trigger>
                  <Menu.Item
                    icon={<X size="1rem" />}
                    className="text-red-500"
                    onClick={() => {
                      setArchiveId(row.original.id);
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Delete Workflow"
                      id="LzXP/m"
                    />
                  </Menu.Item>
                </Menu>
              </div>
            ),
          },
        ]}
        data={workflows}
      />

      {archiveId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage defaultMessage="Delete workflow" id="RPw47l" />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to delete this workflow?"
              id="dqGjs3"
            />
          }
          yes={<FormattedMessage defaultMessage="Delete" id="K3r6DQ" />}
          yesProps={{ loading: archive.loading, color: 'error' }}
          onYes={async () => {
            await archive.request({
              input: { id: archiveId, isArchived: true },
            });
            trackDeletedWorkflow({ workflowId: archiveId });
            setArchiveId(null);
          }}
          onNo={() => setArchiveId(null)}
        />
      )}
    </>
  );
}

function CreateWorkflowButton() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <WorkflowDiscoveryModal open={open} setOpen={setOpen} />
      <Button
        startIcon={<Plus size="1rem" />}
        onClick={() => setOpen(true)}
        size="small"
      >
        <FormattedMessage defaultMessage="New workflow" id="GIHRKG" />
      </Button>
    </>
  );
}

function ProvideFeedbackButton() {
  return (
    <Button
      href="https://tactiq.typeform.com/ai-workflow"
      target="_blank"
      variant="secondaryOutline"
      size="small"
      onClick={() => trackWorkflowProvideFeedbackButton()}
    >
      <FormattedMessage defaultMessage="Provide feedback" id="GdjHV6" />
    </Button>
  );
}

function DuplicateWorkflowButton({ id }: { id: string }) {
  const { data, request, loading } = useWorkflowDuplicate(id);
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const newId = data?.duplicateWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${newId}`);
    }
  }, [newId, isSuccess, navigate]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <FormattedMessage defaultMessage="Duplicate workflow" id="IynmOz" />
      }
    >
      <Button
        variant="icon"
        size="small"
        onClick={() => request()}
        loading={loading}
      >
        {!loading && <Copy size="1rem" />}
      </Button>
    </Tooltip>
  );
}
