import React from 'react';
import { FormattedMessage } from 'react-intl';

export const BadgeNew: React.FC<{ visible: boolean }> = ({ visible }) => {
  if (!visible) return null;

  return (
    <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
      <FormattedMessage defaultMessage="New" id="bW7B87" />
    </span>
  );
};
