import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as routes from '../../helpers/routes';
import { trackWebPage } from '../../helpers/analytics';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../models/integration';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { IntegrationListItem } from './IntegrationListItem';
import { AllIntegrations } from './integrations';

export const IntegrationsList: React.FC = () => {
  const user = useSelector(({ user }: RootState) => user);
  const intl = useIntl();

  useEffect(() => {
    trackWebPage('Integration');
  }, []);

  const userConnections = Object.values(user.connections ?? {});

  const integrations = AllIntegrations.filter(
    (x) => x.description && !x.hidden
  ).map(
    (i) =>
      ({
        ...i,
        category: i.category ?? IntegrationCategory.Other,
      }) as IntegrationImplementation
  );

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Integrations',
        id: 'rvcwqf',
        description: 'Integrations page navigation title',
      })}
    >
      <h1 className="text-3xl">
        <FormattedMessage
          defaultMessage="Integrations"
          id="Et4iaV"
          description="Integrations page header."
        />
      </h1>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {[
          IntegrationCategory.Meetings,
          IntegrationCategory.Automation,
          IntegrationCategory.Storage,
          IntegrationCategory.CRM,
          IntegrationCategory.Other,
        ].map((category) => (
          <React.Fragment key={category}>
            <h2 className="col-span-full mt-4 text-2xl">{category}</h2>
            {integrations
              .filter((i) => i.category === category)
              .map((integration) => (
                <IntegrationListItem
                  key={integration.id}
                  integration={integration}
                  userConnections={userConnections}
                />
              ))}
          </React.Fragment>
        ))}
      </div>

      <div>
        <p className="mt-4">
          <FormattedMessage
            defaultMessage="Don't see your platform? Then request the next integration {requestIntegration}."
            id="vHi29u"
            description="Request integration description text."
            values={{
              requestIntegration: (
                <strong>
                  <a
                    href={routes.kIntegrationRequest}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      defaultMessage="here"
                      id="d0amT9"
                      description="Request integration link text."
                    />
                  </a>
                </strong>
              ),
            }}
          />
        </p>
      </div>
    </Page>
  );
};

export default IntegrationsList;
