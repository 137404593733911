import {
  gotMeeting,
  gotMeetingTags,
  gotScreenshots,
  gotTranscriptSummaries,
  setLoadingMeetingData,
  updateNotFoundTranscripts,
  updateUnauthorizedTranscripts,
} from '../modules/global';
import { TactiqStore } from '../store';
import { logger } from '@tactiq/model';
import { queryMeetingWithTranscript } from '../../graphql/meetings';
import { trackWebEvent } from '../../helpers/analytics';
import {
  getTranscriptSummariesFromExtension,
  getRawTranscriptFromExtension,
} from '../../helpers/storage';
import {
  selectIsEnhancedPrivacyEnabled,
  createSelectRawTranscriptSelector,
} from '../selectors';
import omit from 'lodash/omit';
import { gotTranscript, getScreenshots } from './helpers';
import { BasicMeeting } from '../../models/meeting';

export async function getMeeting(
  store: TactiqStore,
  meetingId: string,
  meeting: BasicMeeting | undefined,
  options: {
    key?: string;
    force?: boolean;
  }
): Promise<void> {
  store.dispatch(setLoadingMeetingData(true));

  try {
    await fetchMeetingWithTranscript(
      store,
      meetingId,
      meeting,
      options.force ?? false
    );
  } catch (err) {
    if (
      err.message &&
      (err.message.includes('Unauthorized') ||
        err.message.includes('The user is not logged in'))
    ) {
      store.dispatch(
        updateUnauthorizedTranscripts({
          [meetingId]: true,
        })
      );
    } else if (err.message && err.message.includes('Meeting not found')) {
      store.dispatch(
        updateNotFoundTranscripts({
          [meetingId]: true,
        })
      );
    } else {
      throw err;
    }
  }

  store.dispatch(setLoadingMeetingData(false));
}

async function fetchMeetingWithTranscript(
  store: TactiqStore,
  meetingId: string,
  meeting: BasicMeeting | undefined,
  force: boolean
): Promise<void> {
  const state = store.getState();
  const selectRawTranscript = createSelectRawTranscriptSelector();
  const rawTranscript = selectRawTranscript(state, meetingId);
  const isEnhancedPrivacyEnabled = selectIsEnhancedPrivacyEnabled(state);

  if (isEnhancedPrivacyEnabled) {
    const transcriptSummaries = await getTranscriptSummariesFromExtension();
    store.dispatch(gotTranscriptSummaries(transcriptSummaries));
  }

  if (!rawTranscript || !meeting || force) {
    const meetingData = await queryMeetingWithTranscript(meetingId);

    logger.info('Got meeting update fetching transcript');
    store.dispatch(gotMeeting(omit(meetingData, 'userTags', 'transcript')));
    store.dispatch(gotMeetingTags(meetingData.userTags));

    if (meetingData.transcript) {
      store.dispatch(gotTranscript(meetingId, meetingData.transcript));
      store.dispatch(
        gotScreenshots({
          meetingId,
          screenshots: meetingData.transcript.blocks
            .filter((block) => block.type === 'screenshot')
            .reduce(
              (memo, block) => ({
                ...memo,
                [block.messageId]: block.transcript,
              }),
              {} as Record<string, string>
            ),
        })
      );
    } else if (!rawTranscript) {
      try {
        const browserRawTranscript =
          await getRawTranscriptFromExtension(meetingId);

        if (browserRawTranscript) {
          store.dispatch(gotTranscript(meetingId, browserRawTranscript));

          await getScreenshots(store, meetingId, browserRawTranscript);
        }
      } catch (err) {
        logger.error(err);

        trackWebEvent('Meeting View - Transcript browser loading failed', {
          error: 'message' in err ? err.message : err,
        });
      }
    }
  }
}
