import { LucideIcon } from 'lucide-react';
import React from 'react';

export type BreadcrumbItem = {
  href: string;
  label: React.ReactNode;
};

export const PageHeaderChip: React.FC<{
  icon: LucideIcon;
  label: React.ReactNode;
}> = ({ icon, label }) => {
  const Icon = icon;
  return (
    <div className="mt-2 flex items-center text-sm text-gray-500">
      <Icon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      {label}
    </div>
  );
};
