import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button } from '../../components/buttons';
import { trackWebPage } from '../../helpers/analytics';
import { kReportingOwnMeetings } from '../../helpers/routes';
import { Page } from '../Common/Navigation';

export const Reporting: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    trackWebPage('Reporting');
  }, []);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Reporting',
        id: 'reFEEC',
      })}
      maxWidth={'md'}
    >
      <h1 className="text-3xl">
        <FormattedMessage defaultMessage="Reporting" id="reFEEC" />
      </h1>
      <div className="flex flex-col gap-4">
        <div>
          <Button
            variant="soft"
            onClick={() => {
              navigate(kReportingOwnMeetings);
            }}
          >
            <FormattedMessage defaultMessage="Own Meetings" id="7OPhic" />
          </Button>
        </div>
      </div>
    </Page>
  );
};
