import React from 'react';
import { useDispatch } from 'react-redux';
import { IntegrationImplementation } from '../../../../../models/integration';
import { ShareModal } from '../common/ShareModal';
import { ConnectionDialog as ConnectionDialogImpl } from './ConnectionDialog';

interface Props {
  integration: IntegrationImplementation;
  onClose: () => void;
}

const ConnectionDialog: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  return (
    <ShareModal onClose={props.onClose}>
      <ConnectionDialogImpl
        dispatch={dispatch}
        integration={props.integration}
      />
    </ShareModal>
  );
};

export default ConnectionDialog;
