import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectMentions } from '../../../../../../../redux/selectors';
import { useMeetingContext } from '../../../../MeetingContext';
import { SelectionObserverContext } from '../../../selection-observer';
import { Comment } from './Comment';

/**
 * Comment editor
 * @returns {React.FC} a component
 */
export const CommentEditor: React.FC = () => {
  const meetingId = useMeetingContext();
  const mentions = useSelector(selectMentions);
  const { onCommentBlock, commentParams } = useContext(
    SelectionObserverContext
  );

  const onCancel = useCallback(() => {
    onCommentBlock(null);
  }, [onCommentBlock]);

  if (!meetingId) {
    return null;
  }

  return (
    <Comment
      mentions={mentions}
      onCancel={onCancel}
      autoFocus={true}
      relatedBlockId={commentParams?.groupId}
      relatedCommentId={commentParams?.commentId}
    />
  );
};
