import React, { useCallback, ReactElement, ReactNode } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { Wand2, Sparkles, X } from 'lucide-react';
import { MoreAiToolsMenu } from '../../Transcripts/view/ai/MoreAiToolsMenu';
import { AvailablePrompt } from '../../Transcripts/view/ai/AIGenerated';
import { Button } from '../../../components/buttons';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Checkbox } from '../../../components/Checkbox';
import { EmojiField } from '../../Common/icons/EmojiField';

interface MeetingKitItemInfo {
  meetingKitId: string;
  meetingKitItemId: string;
  icon?: string;
  name: string;
  description: string;
  prompt: string;
}

const icon = (icon: string, fallback: ReactNode) =>
  icon ? (
    <EmojiField
      value={icon}
      defaultIcon={<Wand2 className="h-4 w-4 text-slate-600" />}
      EmojiProps={{
        unified: icon,
        size: 20,
      }}
    />
  ) : (
    fallback
  );

function MeetingKitDetails(props: {
  item: MeetingKitItemInfo;
  onReset: () => void;
}): ReactElement | null {
  const { item, onReset } = props;
  return (
    <div className="flex flex-col gap-y-6 rounded-lg bg-slate-100/70 p-5">
      <div className="flex flex-row justify-between gap-2">
        <div className="flex w-10/12 max-w-60 flex-row gap-x-4">
          {icon(item.icon ?? '', '')}
          <div className="font-bold leading-5 tracking-tight text-slate-800">
            {item.name}
          </div>
        </div>
        <Button
          size="icon"
          variant="icon"
          startIcon={<X className="h-4 w-4 text-slate-400" />}
          onClick={onReset}
        />
      </div>
      {item.description || item.prompt ? (
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold text-slate-800">
            {item.description}
          </div>
          <div className="text-sm text-slate-600">{item.prompt}</div>
        </div>
      ) : null}
    </div>
  );
}

export function RunMeetingKit(
  props: WorkflowNodeProps<{
    meetingKitItemId?: string;
    applyToMeeting?: boolean;
  }>
): ReactElement {
  const reactFlow = useReactFlow();
  const usedMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.used
  );
  const systemMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.system
  );

  const handleMeetingKitSelection = useCallback(
    (value: AvailablePrompt | null) => {
      const { meetingKitId, meetingKitItemId, type } = value ?? {
        meetingKitId: null,
        meetingKitItemId: null,
      };
      reactFlow.updateNodeData(props.id, {
        promptType: type,
        meetingKitId,
        meetingKitItemId,
      });
    },
    [reactFlow, props.id]
  );

  const setApplyToMeeting = useCallback(
    (applyToMeeting: boolean) => {
      reactFlow.updateNodeData(props.id, {
        applyToMeeting,
      });
    },
    [reactFlow, props.id]
  );

  const idToMeetingKitDetails = new Map<string, MeetingKitItemInfo>();
  [systemMeetingKits, ...usedMeetingKits].forEach((mk) => {
    mk.items.forEach((mki) => {
      idToMeetingKitDetails.set(mki.id, {
        meetingKitId: mk.id,
        meetingKitItemId: mki.id,
        name: mki.name,
        description: mki.description,
        prompt: mki.prompt,
        icon: mki.icon,
      });
    });
  });

  const selectedKitItemDetails = props.data.meetingKitItemId
    ? idToMeetingKitDetails.get(props.data.meetingKitItemId as string)
    : null;

  const builder = (
    <div className="flex flex-col gap-4">
      {!selectedKitItemDetails ? (
        <MoreAiToolsMenu
          loading={true}
          quickPromptsLength={0}
          usedPrompts={new Set()}
          onClick={handleMeetingKitSelection}
          trigger={
            <Button
              startIcon={<Sparkles size="1rem" className="text-slate-700" />}
              variant="light"
              className="w-full min-w-full"
            >
              <FormattedMessage
                defaultMessage="Select an AI prompt"
                id="XI78Pv"
              />
            </Button>
          }
        />
      ) : (
        <MeetingKitDetails
          item={selectedKitItemDetails}
          onReset={() => handleMeetingKitSelection(null)}
        />
      )}
      <Checkbox
        id={`${props.id}-applyToMeeting`}
        checked={!!props.data.applyToMeeting}
        label={
          <FormattedMessage
            defaultMessage="Save the result to the meeting"
            id="6306sX"
          />
        }
        onChange={setApplyToMeeting}
      />
    </div>
  );

  return (
    <SingleSourceNode
      icon={
        <Sparkles className="size-8 rounded-md bg-brand-50 p-2 text-brand" />
      }
      workflowNode={props}
    >
      {builder}
    </SingleSourceNode>
  );
}
