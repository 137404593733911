import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IntegrationImplementation } from '../../../models/integration';
import { Navigate, useParams } from 'react-router';
import { MeetingReach } from '../../../graphql/operations';
import { Mail } from 'lucide-react';

export const integration: IntegrationImplementation = {
  order: 2,
  connector: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { id: meetingId } = useParams<{ id: string | undefined }>();

    if (meetingId) {
      return (
        <Navigate
          replace
          to={`/transcripts/${meetingId}/share/link?reach=${MeetingReach.RESTRICTED}`}
        />
      );
    }

    return null;
  },
  id: 'email',
  title: 'Email',
  icon: () => <Mail className="h-8 w-8" />,
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Share by email"
      id="AaCvZb"
      description="Share by email. Integration share description."
    />
  ),
  share: async () => ({ link: '' }),
  ShareDialog: () => null,
  isConnected: () => true,
};

export default integration;
