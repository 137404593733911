import React from 'react';
import { cx } from '../../../../../../helpers/utils';

/**
 * Google Drive Screenshot
 * @param {unknown} param0 params
 * @param {boolean} param0.isHighlight is highlight
 * @param {string} param0.link link
 * @returns {React.FC} component
 */
export const GDriveScreenshot: React.FC<{
  link: string;
}> = ({ link }) => {
  const src = link.replace('/view', '/preview');

  return (
    <div className="relative w-full pt-[56.25%]">
      <iframe
        className={cx('absolute left-0 top-0 h-full w-full border-none')}
        title="Google Drive Screenshot"
        src={src}
        scrolling="no"
      />
    </div>
  );
};
