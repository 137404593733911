import { StripePaidPlanData, TeamRole } from './user';

export interface TeamInvitationLink {
  id: string;
  usedTimes: number;
}

export interface TeamDataV1 {
  id: string;
  name?: string;
  plan?: StripePaidPlanData;
  stripe?: {
    stripeId: string;
  };
  link?: TeamInvitationLink;
  settings?: TeamSettingsData;
}

export interface TeamData {
  id: string;
  name: string;
  displayName?: string;
  plan?: StripePaidPlanData;
  stripeId?: string;
  link?: TeamInvitationLink;
  settings?: TeamSettingsData;
  members: Record<string, TeamMemberData>;
  invitations?: string[];
}

export interface TeamMemberData {
  uid: string;
  email: string;
  displayName: string;
  photoURL?: string;
  roles: Record<TeamRole, boolean>;
  requestedUpgrade?: boolean;
  /**
   * equal to the value of `isOutOfCredits` of a `UserPlan` that would be constructed for the user from `UserData`
   *
   * This field is set when the user runs out of credits, their allowance changes, or their plan data changes
   */
  isOutOfMeetings?: boolean;
  /**
   * equal to the value of `isOutOfMeetings` of a `UserPlan` that would be constructed for the user from `UserData`
   *
   * This field is set when the user's plan data changes
   */
  hasPersonalActivePlan?: boolean;
  usingSeat?: boolean;
  settings?: TeamMemberSettingsData;
}

export interface TeamMemberSettingsData {
  meetingsRetentionPeriodDays?: number;
}

export interface TeamSettingsData {
  enableEmailLinkSignIn?: boolean;
  enableLLM?: boolean;
  disableSelfJoinDomain?: boolean;
  enableTeamInvite?: boolean;
  meetingsRetentionPeriodDays?: number;
}

export const MAX_DOMAIN_USERS = 100;
