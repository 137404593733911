import { useMutation } from '@apollo/client';
import { AlertTriangle } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import { ModalDialog } from '../../components/modals';
import { RequestSubscriptionMoveDocument } from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { isEmailValid } from '../../helpers/utils';
import { selectTeam, selectUserEmail } from '../../redux/selectors';
import { RootState } from '../../redux/store';

export const RequestSubscriptionMoveDialog: React.FC<{
  isBillingAdministratorOnTeamPlan: boolean;
  onClose: (doneSomething?: boolean) => void;
}> = ({ isBillingAdministratorOnTeamPlan, onClose }) => {
  const userEmail = useSelector(selectUserEmail);
  const userPaidPlan = useSelector((state: RootState) => state.user.plan.paid);
  const team = useSelector(selectTeam);
  const [dataMoveTargetEmail, setDataMoveTargetEmail] = useState('');
  const [requestSubscriptionMove, requestSubscriptionMoveMutation] =
    useMutation(RequestSubscriptionMoveDocument);

  const intl = useIntl();

  const isTeamPlan =
    (isBillingAdministratorOnTeamPlan ||
      userPaidPlan?.__typename === 'TeamPaidPlan') &&
    (team?.members.length ?? 0) > 1;

  useEffect(() => {
    trackWebEvent('RequestSubscriptionMoveDialog - viewed');
  }, []);

  return (
    <ModalDialog
      open
      onClose={onClose}
      icon={
        <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
      }
      color="error"
      title={
        <FormattedMessage
          defaultMessage="Request Subscription Move"
          id="CJo3n6"
        />
      }
      text={
        isTeamPlan ? (
          <FormattedMessage
            defaultMessage="You are on a team plan. To move the plan to another account, please invite them to your team, then downgrade yourself to free up a seat, and then upgrade them using your seat."
            id="sM3fJv"
          />
        ) : (
          <FormattedMessage
            defaultMessage="This will move your subscription to the account you specify, and your account will become a free one."
            id="+0zmFS"
          />
        )
      }
      actions={
        isTeamPlan ? (
          <Button
            onClick={() => {
              onClose();
            }}
          >
            <FormattedMessage defaultMessage="Close" id="rbrahO" />
          </Button>
        ) : (
          <>
            <Button
              color="error"
              loading={requestSubscriptionMoveMutation.loading}
              onClick={async () => {
                if (!isEmailValid(dataMoveTargetEmail)) {
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage: 'Please enter a valid email',
                      id: 'prBAWB',
                    }),
                    { variant: 'ERROR' }
                  );
                  return false;
                }

                if (dataMoveTargetEmail.trim()) {
                  if (dataMoveTargetEmail.trim() === userEmail) {
                    enqueueSnackbar(
                      intl.formatMessage({
                        defaultMessage:
                          'You cannot move the subscription to yourself',
                        id: 'w7Lj36',
                      }),
                      { variant: 'ERROR' }
                    );
                    return false;
                  }

                  const result = await requestSubscriptionMove({
                    variables: {
                      input: {
                        targetEmail: dataMoveTargetEmail.trim(),
                      },
                    },
                  });

                  if (result.data?.requestSubscriptionMove?.success) {
                    setDataMoveTargetEmail('');
                    onClose(true);
                  }
                }
              }}
            >
              <FormattedMessage
                defaultMessage="Request subscription move"
                id="b5rm9g"
              />
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
            </Button>
          </>
        )
      }
    >
      {isTeamPlan ? null : (
        <TextInput
          label={intl.formatMessage({
            defaultMessage: 'Target account email',
            id: 'MUNM1k',
          })}
          type="email"
          value={dataMoveTargetEmail}
          onChange={setDataMoveTargetEmail}
          errorLabel={intl.formatMessage({
            defaultMessage: 'Please enter a valid email address',
            id: 'oLpv29',
          })}
        />
      )}
    </ModalDialog>
  );
};
