import { useMutation } from '@apollo/client';
import once from 'lodash/once';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeTranscriptNotesDocument } from '../../../../graphql/operations';
import { trackWebEvent } from '../../../../helpers/analytics';
import { isMeetingEditable } from '../../../../helpers/meetings';
import { updateTranscriptNotes } from '../../../../redux/modules/global';
import {
  createSelectRawTranscriptSelector,
  selectTeam,
} from '../../../../redux/selectors';
import { RootState } from '../../../../redux/store';
import { useMeeting } from '../../common/meeting-hooks';
import { TranscriptNotesComponent } from './TranscriptNotes';

/**
 * Transcript notes
 * @param {unknown} param0 params
 * @returns {React.FC} a component
 */
const TranscriptNotes: React.FC<{ meetingId: string }> = ({ meetingId }) => {
  const dispatch = useDispatch();
  const meeting = useMeeting(meetingId);
  const team = useSelector(selectTeam);
  const selectRawTranscript = useMemo(createSelectRawTranscriptSelector, []);
  const transcript = useSelector((state: RootState) =>
    selectRawTranscript(state, meetingId)
  );
  const isEditable = isMeetingEditable(meeting) && !meeting?.isPreview;
  const trackEventOnce = useMemo(() => {
    return once(trackWebEvent);
  }, []);

  const [changeTranscriptNotes] = useMutation(ChangeTranscriptNotesDocument);
  const onChange = useCallback(
    async (content: string) => {
      if (!meeting) return;

      if ((!transcript?.notes || !transcript.notes.content) && content) {
        trackWebEvent('Transcript notes added', {
          team_id: team?.id,
          meetingId: meeting.id,
        });
      } else if (content) {
        trackEventOnce('Transcript notes updated', {
          team_id: team?.id,
          meetingId: meeting.id,
        });
      }

      const updatedAt = Date.now();

      dispatch(updateTranscriptNotes({ meetingId, content, updatedAt }));

      await changeTranscriptNotes({
        variables: {
          input: {
            id: meeting.id,
            newNotes: {
              content,
              version: (transcript?.notes?.version ?? 0) + 1,
            },
            updatedAt,
          },
        },
      });
    },
    [
      meeting,
      transcript?.notes,
      dispatch,
      meetingId,
      changeTranscriptNotes,
      trackEventOnce,
    ]
  );

  if (!transcript || !meeting) {
    return null;
  }

  return (
    <TranscriptNotesComponent
      notes={transcript.notes}
      place="notes"
      readOnly={!isEditable}
      onChange={onChange}
    />
  );
};

export default TranscriptNotes;
