import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { kSettingsGeneral, kSettingsTeam } from '../../helpers/routes';
import { RootState } from '../../redux/store';
import { Button } from '../../components/buttons';
import { Alert } from '../../components/Alert';

export const RequireLLMEnabled: React.FC<
  PropsWithChildren<{ disableAlert?: boolean }>
> = ({ children, disableAlert }) => {
  const navigate = useNavigate();

  const isLLMEnabledUser =
    useSelector((state: RootState) => state.user.settings?.enableLLM) ?? true;
  const isLLMEnabledTeam =
    useSelector((state: RootState) => state.user.team?.settings?.enableLLM) ??
    true;

  const isLLMEnabled = isLLMEnabledUser && isLLMEnabledTeam;

  if (isLLMEnabled) return <div>{children}</div>;

  if (disableAlert) return null;

  if (!isLLMEnabledTeam) {
    return (
      <Alert
        severity="info"
        action={
          <Button size="small" onClick={() => navigate(kSettingsTeam)}>
            <FormattedMessage defaultMessage="View Team Settings" id="EQ+IKM" />
          </Button>
        }
        description={
          <FormattedMessage
            defaultMessage="AI features using Large Language Models are disabled by your team administrator."
            id="mLuMRK"
          />
        }
      />
    );
  }

  return (
    <Alert
      severity="info"
      action={
        <Button size="small" onClick={() => navigate(kSettingsGeneral)}>
          <FormattedMessage defaultMessage="View Settings" id="VrgkJ/" />
        </Button>
      }
      description={
        <FormattedMessage
          defaultMessage="AI features using Large Language Models are disabled for you in Settings."
          id="RWdNw+"
        />
      }
    />
  );
};
