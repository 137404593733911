import get from 'lodash/get';
import escapeRegExp from 'lodash/escapeRegExp';
import { FuseResult, FuseResultMatch } from 'fuse.js';

const createMatch = <T>(
  refIndex: number,
  key: string,
  item: T,
  regExp: RegExp
): FuseResultMatch | void => {
  const val = get(item, key);

  if (!val || typeof val !== 'string') {
    return;
  }

  const matches = val.matchAll(regExp);
  const indices: [number, number][] = [];

  for (const match of matches) {
    const [term] = match;
    const { index } = match;

    if (index !== undefined) {
      indices.push([index, index + term.length - 1]);
    }
  }

  if (indices.length) {
    return {
      indices,
      key,
      refIndex,
    };
  }
};

export const search = <T>(
  collection: T[],
  keys: string[],
  query: string | string[]
): FuseResult<T>[] => {
  if (!query) {
    return [];
  }

  const regExp = new RegExp(
    Array.isArray(query)
      ? query.map((item) => escapeRegExp(item)).join('|')
      : escapeRegExp(query),
    'gi'
  );
  const filtered: FuseResult<T>[] = [];
  collection.forEach((item, refIndex) => {
    const matches = [];
    for (const key of keys) {
      const match = createMatch(refIndex, key, item, regExp);

      if (match) {
        matches.push(match);
      }
    }

    if (matches.length) {
      filtered.push({
        refIndex,
        item,
        matches,
      });
    }
  });

  return filtered;
};
