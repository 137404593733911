import { LabelConfig } from '@tactiq/model';
import clone from 'lodash/cloneDeep';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../components/buttons';
import { removeLabel as removeLabelMutation } from '../../../../graphql/labels';
import { removeLabel } from '../../../../redux/modules/global';
import { selectLabels } from '../../../../redux/selectors';
import { Label } from '../../../Common/Label';
import { LabelCreateDialog } from './CreateDialog';
import { LabelsInfo } from './Info';
import { LabelUpdateDialog } from './UpdateDialog';
import { Edit2, PlusIcon, Trash } from 'lucide-react';
import { Spinner } from '../../../../components/Spinner';
import { Table } from '../../../../components/Table';
import { Tooltip } from '../../../../components/Tooltip';

/**
 * Labels List
 * @returns {React.FC} a component
 */
export const LabelsList: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const labels = useSelector(selectLabels);

  const [editLabel, setEditLabel] = useState<LabelConfig | null>(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [isRemoving, setIsRemoving] = useState<string | null>(null);

  const onCreateClick = useCallback(() => {
    setShowCreateDialog(true);
  }, []);
  const onCloseCreateDialog = useCallback(() => {
    setShowCreateDialog(false);
  }, []);
  const onCloseEditDialog = useCallback(() => {
    setEditLabel(null);
  }, []);

  const onRemoveLabel = useCallback(
    async (labelId: string) => {
      if (
        confirm(
          intl.formatMessage({
            defaultMessage: 'Are you sure you want to delete this label?',
            id: 'wmcYTf',
            description: 'Confirmation message for deleting a label',
          })
        )
      ) {
        setIsRemoving(labelId);

        try {
          await removeLabelMutation(labelId);
          dispatch(removeLabel(labelId));
        } finally {
          setIsRemoving(null);
        }
      }
    },
    [dispatch, intl]
  );

  return (
    <div className="flex flex-col gap-4">
      <LabelsInfo />
      <Table
        search={true}
        data={labels}
        initialPageSize={10}
        columns={[
          {
            id: 'name',
            header: intl.formatMessage({
              defaultMessage: 'Label',
              id: '753yX5',
            }),
            enableSorting: true,
            cell: ({ row }) => <Label {...row.original} />,
            accessorFn: (row) => row.name,
          },
          {
            id: 'description',
            header: intl.formatMessage({
              defaultMessage: 'Description',
              id: 'Q8Qw5B',
            }),
            enableSorting: true,
            cell: ({ row }) => (
              <span className="text-sm">{row.original.description}</span>
            ),
            accessorFn: (row) => row.description,
          },
          {
            id: 'actions',
            cell: ({ row }) => {
              return (
                <div className="flex justify-end gap-2">
                  <Tooltip
                    title={intl.formatMessage({
                      defaultMessage: 'Edit label',
                      id: 'IcnxH4',
                    })}
                  >
                    <Button
                      onClick={() => setEditLabel(clone(row.original))}
                      size="small"
                      variant="icon"
                      textSize="xs"
                      aria-label={intl.formatMessage({
                        defaultMessage: 'Edit label',
                        id: 'IcnxH4',
                      })}
                    >
                      <Edit2 />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={intl.formatMessage({
                      defaultMessage: 'Delete label',
                      id: 'rU8EZJ',
                    })}
                  >
                    <Button
                      onClick={() => onRemoveLabel(row.original.id)}
                      aria-label={intl.formatMessage({
                        defaultMessage: 'Delete label',
                        id: 'rU8EZJ',
                      })}
                      size="small"
                      variant="icon"
                      textSize="xs"
                      color="error"
                    >
                      <span>
                        {isRemoving === row.original.id ? (
                          <Spinner className="m-1" />
                        ) : (
                          <Trash />
                        )}
                      </span>
                    </Button>
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
        actions={() => {
          return (
            <Button
              startIcon={<PlusIcon className="h-6 w-6" />}
              onClick={onCreateClick}
            >
              <FormattedMessage
                defaultMessage="Create label"
                id="LjvKDp"
                description="Labels page. Create new label button."
              />
            </Button>
          );
        }}
      />
      <LabelCreateDialog
        open={showCreateDialog}
        labels={labels}
        onClose={onCloseCreateDialog}
      />
      <LabelUpdateDialog
        label={editLabel}
        labels={labels}
        onClose={onCloseEditDialog}
      />
    </div>
  );
};
