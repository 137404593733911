import { useMutation } from '@apollo/client';
import { isAfter } from 'date-fns';
import { Gift } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../components/buttons';
import { ModalDialog } from '../../components/modals';
import {
  CreateTeamDocument,
  Team,
  UpgradeToTeamDocument,
  UserTier,
} from '../../graphql/operations';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../helpers/analytics';
import { upgradePlan } from '../../helpers/api/billing';
import { isProduction } from '../../helpers/firebase/config';
import { getCountryCode } from '../../helpers/locale';
import {
  selectTeam,
  selectUid,
  selectUserPricing,
  selectUserTier,
} from '../../redux/selectors';
import { TierPricingDialog } from '../Credits/TierPricing/TierPricingDialog';

// Promotion time compoment is housed here and used in the billing page/navigation bar
// Update the below variables for the new promotion as well as text in between 'UPDATE HERE' comments

// Event name for tracking in mixpanel
export const PromotionEventName = 'Year End';
// Param from the email that will trigger the automatic opening of the dialog
export const EmailParamString = 'year-end-2024';
// Date the components will no longer be shown (Format: YYYY-MM-DD)
export const CutOffDate = '2024-01-07';
// Date the offer expires for showing in text for dialogs (Format: Month Day, Year)
export const CutOffDateString = 'January 7th, 2024';
// Production coupon code for Stripel
export const ProductionCouponCode = 'UhQMz4Nw';
// Testing coupon code for Stripe
export const TestingCouponCode = 'IlRzj2gX';

export function canSeePromo(
  uid: string | undefined,
  tier: UserTier,
  team?: Team
): boolean {
  if (!uid) return false;
  if (isAfter(new Date(), new Date(CutOffDate))) return false;
  if (tier === UserTier.ENTERPRISE) return false;
  if (tier === UserTier.PRO && team?.isPaid) return false;
  if (tier === UserTier.TEAM && team?.plan?.recurrenceInterval === 'year')
    return false;

  return true;
}

export function canGetPromo(
  uid: string | undefined,
  tier: UserTier,
  team?: Team
): boolean {
  if (tier === UserTier.ENTERPRISE) return false;

  if (
    tier === UserTier.FREE &&
    team?.isPaid &&
    !team.members.find((x) => x.uid === uid)?.roles.ADMIN
  )
    return false;

  if (
    team?.isPaid &&
    team.plan?.recurrenceInterval === 'month' &&
    !team.members.find((x) => x.uid === uid)?.roles.ADMIN
  )
    return false;

  return canSeePromo(uid, tier, team);
}

/**
 * Promo Dialog
 * @param {unknown} param0 params
 * @param {() => void} param0.onClose close callback
 * @returns {React.FC} component
 */
export const PromoDialog: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const canGetPromotion = canGetPromo(userId, userTier, team);
  React.useEffect(() => {
    trackWebEvent('Promotion Dialog Shown', {
      tier: userTier,
    });
  }, [userTier]);
  const userPricing = useSelector(selectUserPricing);
  const [createTeam] = useMutation(CreateTeamDocument);
  const [upgradeToTeam] = useMutation(UpgradeToTeamDocument);
  const { team: teamPricing } = userPricing;
  const globalPricing = teamPricing;
  const countryCode = getCountryCode();
  const currency = globalPricing.currencies?.find(
    (c) => c.countryCode === countryCode
  );
  const pricing = React.useMemo(() => {
    if (currency) {
      return currency;
    }
    return globalPricing;
  }, [currency, globalPricing]);
  const currencySymbol =
    'currencySymbol' in pricing ? pricing.currencySymbol : pricing.currency;

  async function confirmUpgradeToTeamPlan() {
    if (!userId) return;
    await upgradePlan({
      uid: userId,
      isAnnual: true,
      isTeam: true,
      coupon: isProduction() ? ProductionCouponCode : TestingCouponCode,
      createTeam,
      upgradeToTeam,
      teamId: team?.id,
    });
  }

  return (
    <>
      <ModalDialog
        icon={
          <div className="animate-bounce">
            <Gift strokeWidth={2} size={20} />
          </div>
        }
        open
        onClose={onClose}
        // UPDATE HERE
        title={<FormattedMessage defaultMessage="Year end sale!" id="zfwRQy" />}
        // UPDATE HERE
        text={
          <div className="flex flex-col gap-2">
            <p>
              <FormattedMessage
                defaultMessage="Get {amount} off Team Annual Plan and receive {value} for the whole year! Offer expires {expiry}."
                id="I64ib3"
                values={{
                  amount: <b className="text-indigo-600">25%</b>,
                  value: (
                    <b className="text-indigo-600">
                      <FormattedMessage
                        defaultMessage="unlimited meetings and AI credits"
                        id="tXEcYg"
                      />
                    </b>
                  ),
                  expiry: <b>{CutOffDateString}</b>,
                }}
              />
            </p>
            {!canGetPromotion && (
              <p>
                <FormattedMessage
                  defaultMessage="Please talk to your team administrator(s) about this sale so that they can upgrade the team's plan."
                  id="t9Cv59"
                />
              </p>
            )}
          </div>
        }
        actions={
          <>
            {canGetPromotion && (
              <Button
                onClick={async () => {
                  trackWebEvent(
                    `${PromotionEventName} - Dialog - Upgrade clicked`
                  );
                  await confirmUpgradeToTeamPlan();
                }}
              >
                <FormattedMessage defaultMessage="Upgrade" id="0h/lPM" />
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => {
                trackWebEvent(`${PromotionEventName} - Dialog Closed`);
                onClose();
              }}
            >
              <FormattedMessage defaultMessage="Close" id="rbrahO" />
            </Button>
          </>
        }
      >
        <div className="mx-auto my-3 h-full w-full rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 p-1">
          <div className="flex h-full w-full flex-row items-center justify-between gap-2 rounded-lg bg-white px-3 py-2">
            <p>
              <span className="text-xl font-bold text-indigo-600">
                {currencySymbol}
                {pricing.annualAmount * 0.75} {''}
              </span>
              <FormattedMessage
                defaultMessage="per user per year"
                id="o+knyb"
              />
            </p>
            <div className="rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 px-3 py-1 font-bold text-white">
              <p>
                <FormattedMessage defaultMessage="25% off!" id="QfP6Tq" />
              </p>
            </div>
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

/**
 * Nav Bar Button
 * @returns {React.FC} component
 */
export const PromotionNavButton: React.FC = () => {
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const canSeePromotion = canSeePromo(userId, userTier, team);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPromoDialog, setShowPromoDialog] = React.useState(false);

  React.useEffect(() => {
    if (searchParams.get('offer') === EmailParamString) {
      setShowPromoDialog(true);
      trackWebEvent(`${PromotionEventName} - Link from email present`, {
        tier: userTier,
      });
    }
  }, [searchParams, userTier]);

  if (!canSeePromotion) return null;

  return (
    <button
      onClick={() => {
        trackWebEvent(`${PromotionEventName} - Clicked`, {
          tier: userTier,
          where: 'navigation',
        });
        setShowPromoDialog(true);
      }}
      className="mt-3 flex w-full flex-row items-center gap-x-3 rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 p-2 text-white"
    >
      <div className="animate-bounce">
        <Gift strokeWidth={2} size={20} />
      </div>

      <div>
        <p className="font-bold">
          {/* UPDATE HERE */}
          <FormattedMessage defaultMessage="Year end sale!" id="zfwRQy" />
          {/* UPDATE HERE */}
        </p>
      </div>
      {showPromoDialog && (
        <PromoDialog
          onClose={() => {
            setShowPromoDialog(false);
            setSearchParams();
          }}
        />
      )}
    </button>
  );
};

/**
 * Promotion Banner
 * @returns {React.FC} component
 */
export const PromoBanner: React.FC = () => {
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const canSeePromotion = canSeePromo(userId, userTier, team);

  if (!canSeePromotion) return null;

  return (
    <div className="mx-auto h-full w-full rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 p-1">
      <div className="flex h-full w-full flex-col items-center justify-between gap-2 rounded-lg bg-white px-6 py-5 md:flex-row">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-bold text-blue-950">
            {/* UPDATE HERE */}
            <FormattedMessage defaultMessage="Year end sale!" id="zfwRQy" />
            {/* UPDATE HERE */}
          </p>
          <div className="flex flex-col">
            <p>
              <FormattedMessage
                defaultMessage="Get {amount} off Team Annual Plan and receive {value} for the whole year!"
                id="WnWtcM"
                values={{
                  amount: <b className="text-indigo-600">25%</b>,
                  value: (
                    <b className="text-indigo-600">
                      <FormattedMessage
                        defaultMessage="unlimited AI credits"
                        id="1jsFLc"
                      />
                    </b>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Offer ends {expiry}."
                id="u+1ioQ"
                values={{
                  expiry: <b>{CutOffDateString}</b>,
                }}
              />
            </p>
          </div>
        </div>
        <Button
          size="large"
          onClick={() => {
            trackWebEvent(`${PromotionEventName} - Clicked`, {
              tier: userTier,
              where: 'banner',
            });
            setShowTierPricingDialog(true);
          }}
        >
          <p>
            <FormattedMessage defaultMessage="Upgrade now" id="9tBhzB" />
          </p>
        </Button>
      </div>
      {showTierPricingDialog ? (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.PROMO_BANNER}
          onClose={() => setShowTierPricingDialog(false)}
        />
      ) : null}
    </div>
  );
};
