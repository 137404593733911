import { RawTranscript } from '@tactiq/model';
import { fetchApiV2 } from '../helpers';
import { MeetingShareSettings } from '../../../graphql/operations';

interface CreateDocumentParams {
  meetingId: string;
  title: string;
  rawTranscript: RawTranscript;
  connectionKey: string;
  shareSettings: MeetingShareSettings;
  contactId: string;
}

export async function createQuipDocument(
  params: CreateDocumentParams
): Promise<{ externalId: string; link: string }> {
  return await fetchApiV2('/a/integrations/quip/create', {
    method: 'post',
    body: JSON.stringify(params),
  });
}

export async function connectToQuip(accessToken: string): Promise<{
  connectionKey: string;
}> {
  return await fetchApiV2('/a/integrations/quip/connect', {
    method: 'POST',
    body: JSON.stringify({
      accessToken,
      folder: 'Tactiq',
    }),
  });
}
