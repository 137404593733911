import { RawTranscript, SaveMeetingResponse, logger } from '@tactiq/model';
import {
  MeetingBasicFieldsFragment,
  MeetingFullFieldsFragment,
  MeetingType,
} from '../../graphql/operations';
import { Unsubscribe } from 'firebase/firestore';
import { Dispatch } from 'redux';
import { ApolloClientFactory } from '../../graphql/client';
import {
  subscribeToMeetingUpdates,
  subscribeToMeetingsUpdates,
  subscribeToTranscriptUpdates,
} from '../../graphql/meetings';
import {
  gotMeeting,
  gotMeetings,
  gotRawTranscript,
  deleteMeeting,
  restoreMeeting,
  archiveMeeting,
} from '../../redux/modules/global';

import { createSelectRawTranscriptSelector } from '../../redux/selectors';
import { TactiqStore } from '../../redux/store';
import { isProduction } from '../firebase/config';
import { fetchApiV2 } from './helpers';

export const saveMeetingTranscript = async ({
  meetingId,
  rawTranscript,
}: {
  meetingId: string;
  rawTranscript: RawTranscript;
}): Promise<SaveMeetingResponse> => {
  return await fetchApiV2(`/a/meeting/${meetingId}/transcript`, {
    method: 'POST',
    body: JSON.stringify(rawTranscript),
  });
};

export const saveMeetingTranscriptToGoogleDrive = async (
  meetingId: string,
  rawTranscript: RawTranscript
): Promise<{ externalId: string; link: string }> => {
  return await fetchApiV2<{ externalId: string; link: string }>(
    `/a/integrations/googledrive/save`,
    {
      method: 'POST',
      body: JSON.stringify({
        meetingId,
        rawTranscript,
      }),
    }
  );
};

export const watchTranscript = (
  client: (typeof ApolloClientFactory)['_client'],
  meetingId: string,
  store: TactiqStore
): Unsubscribe => {
  const dispatch = store.dispatch;

  return subscribeToTranscriptUpdates(
    client,
    meetingId,
    (updatedRawTranscript) => {
      const selectRawTranscript = createSelectRawTranscriptSelector();
      const rawTranscript = selectRawTranscript(store.getState(), meetingId);

      if (rawTranscript?.updatedAt !== updatedRawTranscript.updatedAt) {
        dispatch(
          gotRawTranscript({
            meetingId,
            transcript: updatedRawTranscript,
          })
        );
      }
    },
    () => {
      logger.info('Transcript subscription completed');
    },
    (err) => logger.error(err)
  );
};
export const watchMeetings = (
  client: (typeof ApolloClientFactory)['_client'],
  dispatch: Dispatch,
  myMeetings: Record<string, MeetingBasicFieldsFragment>
): Unsubscribe => {
  return subscribeToMeetingsUpdates(
    client,
    (updates) => {
      if (!updates) {
        return;
      }
      (updates.archived ?? []).forEach((meetingId: string) => {
        dispatch(archiveMeeting(meetingId));
      });
      (updates.restored ?? []).forEach((meetingId: string) => {
        dispatch(restoreMeeting(meetingId));
      });
      (updates.deleted ?? []).forEach((meetingId: string) => {
        dispatch(deleteMeeting(meetingId));
      });
      (updates.updated ?? []).forEach((meeting: MeetingFullFieldsFragment) => {
        dispatch(gotMeeting(meeting));
        if (!myMeetings[meeting.id]) {
          const meetings = Object.values(myMeetings);
          dispatch(
            gotMeetings({
              type: MeetingType.MYMEETINGS,
              meetings: [...meetings, meeting],
            })
          );
        }
      });
    },
    () => {
      logger.info('Meeting subscription is complete');
    },
    (error) => {
      logger.error(error);
    }
  );
};

export const watchMeeting = (
  client: (typeof ApolloClientFactory)['_client'],
  dispatch: Dispatch,
  meetingId: string
): Unsubscribe => {
  if (!isProduction()) {
    logger.info('Watching the meeting...');
  }

  return subscribeToMeetingUpdates(
    client,
    meetingId,
    (meeting) => {
      logger.info('Got meeting update through a subscription');
      dispatch(gotMeeting(meeting));
    },
    () => {
      logger.info('Meeting subscription is complete');
    },
    (error) => {
      logger.error(error);
    }
  );
};

export async function fetchScreenshots(
  meetingId: string,
  screenshotIds: string[]
): Promise<Record<string, string> | undefined> {
  const results = await fetchApiV2(`/a/meeting/${meetingId}/screenshots`, {
    method: 'POST',
    body: JSON.stringify({
      screenshotIds,
    }),
  });

  return results as Record<string, string>;
}

export async function requestMeetingAccess(meetingId: string): Promise<void> {
  await fetchApiV2(`/a/meeting/${meetingId}/access`, {
    method: 'POST',
  });
}
