import {
  LabelConfig,
  LabelConfigStyleColor,
  LabelConfigStyleLine,
  LabelConfigStyleVariant,
} from '@tactiq/model';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateLabel as updateLabelMutation } from '../../../../graphql/labels';
import { trackWebEvent } from '../../../../helpers/analytics';
import { updateLabel } from '../../../../redux/modules/global';
import { LabelEditDialog } from './Form/Dialog';

/**
 * Some descvription here
 * @param {unknown} param0 params
 * @param {LabelConfig[]} param0.labels existing labels
 * @param {LabelConfig | null} param0.label label
 * @param {() => void} param0.onClose close handler
 * @returns {React.FC} a component
 */
export const LabelUpdateDialog: React.FC<{
  labels: LabelConfig[];
  label: LabelConfig | null;
  onClose: () => void;
}> = ({ labels, label, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onCreate = useCallback(
    async (labelToUpdate: LabelConfig) => {
      setIsLoading(true);

      trackWebEvent('Labels view - Update');

      await updateLabelMutation(labelToUpdate);
      dispatch(updateLabel(labelToUpdate));

      setIsLoading(false);

      onClose();
    },
    [dispatch, onClose]
  );

  if (!label) return null;

  return (
    <LabelEditDialog
      title={
        <FormattedMessage
          defaultMessage="Edit label"
          id="oO9jJO"
          description="Edit label dialog. Dialog header."
        />
      }
      buttonText={
        <FormattedMessage
          defaultMessage="Save"
          id="/8IX8n"
          description="Edit label dialog. Save button title."
        />
      }
      defaultLabel={
        label || {
          id: '',
          name: '',
          description: '',
          style: {
            color: LabelConfigStyleColor.blue,
            line: LabelConfigStyleLine.solid,
            variant: LabelConfigStyleVariant.outlined,
          },
          filters: [],
        }
      }
      open={!!label}
      showInfo={false}
      existingLabelNames={labels
        .filter((l) => l.id !== label?.id)
        .map((l) => l.name)}
      loading={isLoading}
      onSave={onCreate}
      onClose={onClose}
    />
  );
};
