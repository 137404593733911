import React, { PropsWithChildren, useEffect } from 'react';
import * as routes from '../helpers/routes';
import { Navigate } from 'react-router-dom';
import { useLocation, matchPath } from 'react-router';
import LoadingComponent from './Landing/Loading/LoadingComponent';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { trackWebEvent } from '../helpers/analytics';
import { selectUid } from '../redux/selectors';

const RequireLoggedIn: React.FC<PropsWithChildren> = (props) => {
  const userId = useSelector(selectUid);
  const startedUp = useSelector((state: RootState) => state.global.startedUp);
  const userLoaded = useSelector((state: RootState) => state.global.userLoaded);
  const location = useLocation();

  useEffect(() => {
    if (startedUp && !userId) {
      const origin = matchPath(routes.kMeeting, location.pathname)
        ? routes.kMeeting
        : location.pathname;
      trackWebEvent('User sign-in redirect', {
        origin,
      });
    }
  }, [startedUp, userId, location.pathname]);

  if (!startedUp) {
    return <LoadingComponent />;
  }

  if (!userId) {
    if (location.pathname && location.pathname !== '/') {
      return (
        <Navigate to={`${routes.kSignInPage}?redirect=${location.pathname}`} />
      );
    }
    return <Navigate to={`${routes.kSignInPage}`} />;
  }

  // do not render until user data is fully loaded from the server
  if (!userLoaded) {
    return <LoadingComponent />;
  }

  return props.children as unknown as JSX.Element;
};

export default RequireLoggedIn;
