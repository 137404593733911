import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { trackWebEvent } from '../helpers/analytics';
import { selectUid, selectUserSettings } from '../redux/selectors';
import { setRequestedDeletion } from '../graphql/settings';
import { Button } from '../components/buttons';
import { Alert } from '../components/Alert';

export const AccountDeletionNotification: React.FC = function () {
  const requestedDeletion = useSelector(selectUserSettings).requestedDeletion;
  const userId = useSelector(selectUid);
  if (!requestedDeletion) return null;

  if (requestedDeletion) {
    trackWebEvent('Account deletion notification shown', {
      userId,
      requestedDeletion,
    });
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 m-2">
      <Alert
        severity="error"
        action={
          <div className="mr-16">
            <Button
              color="error"
              onClick={async () => {
                trackWebEvent(
                  'Clicked Button - Cancel account deletion on the account page'
                );
                await setRequestedDeletion({ requested: false });
              }}
            >
              Undo
            </Button>
          </div>
        }
        description={
          <FormattedMessage
            defaultMessage="Your account will be deleted soon. Account deletion requested on: {deletionDate}"
            id="nOh44P"
            values={{
              deletionDate: new Date(requestedDeletion * 1000).toString(),
            }}
          />
        }
      />
    </div>
  );
};
