import React, { ReactElement, useCallback } from 'react';
import AddNodeButton from '../AddNodeButton';
import { Handle, Position, useReactFlow } from '@xyflow/react';
import { BaseNode, WorkflowNodeProps } from './BaseNode';
import { cx } from '../../../helpers/utils';
import { GitFork } from 'lucide-react';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { useWorkflowId } from '../WorkflowIdContext';

/**
 * A node to perform a boolean check and branch the workflows down one of two paths
 */
export function Condition(
  props: WorkflowNodeProps<{
    condition?: string;
  }>
): ReactElement {
  const reactFlow = useReactFlow();
  const { workflowId } = useWorkflowId();
  const { updateNodeData: update } = useReactFlow();
  const autocomplete = useAutocomplete(props);
  const handleChange = useCallback(
    (value: string) => {
      update(props.id, {
        condition: value,
      });
    },
    [props.id, update]
  );

  const nodeId = props.id;
  const node = reactFlow.getNode(nodeId);
  const edges = reactFlow.getEdges();
  const hasTrueTarget = edges.some(
    (e) => e.source === nodeId && e.sourceHandle === 'true'
  );
  const hasFalseTarget = edges.some(
    (e) => e.source === nodeId && e.sourceHandle === 'false'
  );

  const verticalPosition = 'absolute top-full mt-4';
  const execution = node?.data.execution;
  const newY = (node?.position?.y ?? 0) + (node?.measured?.height ?? 0) + 128;
  const newX = node?.position?.x ?? 0;
  const newWidth = (node?.measured?.width ?? 0) * 0.55;

  return (
    <BaseNode
      icon={
        <GitFork className="size-8 rounded-md bg-brand-50 p-2 text-brand" />
      }
      workflowNode={props}
    >
      <div className="flex flex-row items-center">
        {!hasFalseTarget && !execution ? (
          <div
            className={cx(verticalPosition, 'nodrag right-2/3 translate-x-1/2')}
          >
            <AddNodeButton
              source={props.id}
              label={'No'}
              sourceHandle="false"
              position={{
                x: newX - newWidth,
                y: newY,
              }}
            />
          </div>
        ) : null}
        <Handle
          className={cx(
            !hasFalseTarget || execution ? 'opacity-0' : '',
            '!left-1/3'
          )}
          type="source"
          position={Position.Bottom}
          id="false"
        />
      </div>

      <LiquidTemplateInput
        className="min-h-40 flex-grow text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        value={props.data.condition ?? ''}
        onChange={handleChange}
        workflowId={workflowId}
        nodeType={props.type}
      />

      <div>
        {!hasTrueTarget && !execution ? (
          <div
            className={cx(verticalPosition, 'nodrag left-2/3 -translate-x-1/2')}
          >
            <AddNodeButton
              source={props.id}
              label={'Yes'}
              sourceHandle="true"
              position={{
                x: newX + newWidth,
                y: newY,
              }}
            />
          </div>
        ) : null}
        <Handle
          className={cx(
            !hasFalseTarget || execution ? 'opacity-0' : '',
            '!left-2/3'
          )}
          type="source"
          id="true"
          position={Position.Bottom}
        />
      </div>
    </BaseNode>
  );
}
