import React from 'react';

export const SkeletonBlock: React.FC = () => {
  return (
    <div className="flex flex-col gap-4">
      {Array.from({ length: 6 }).map((_, index) => (
        <div
          key={index}
          className="flex h-20 w-full rounded bg-gray-200 motion-safe:animate-pulse"
        />
      ))}
    </div>
  );
};
