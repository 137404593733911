import React, { useEffect } from 'react';
import * as routes from '../helpers/routes';
import { trackWebEvent } from '../helpers/analytics';

export const ExtensionDeletionContainer: React.FC = () => {
  useEffect(() => {
    let search = window.location.search || window.location.hash;
    if (search[0] === '?' || search[0] === '#') {
      search = search.slice(1);
    }
    const searchParams = new URLSearchParams(search);
    const userId = searchParams.get('userId');
    const anonymousId = searchParams.get('anonymousId');
    if (userId) {
      trackWebEvent('Delete Extension', { userId });
    } else if (anonymousId) {
      trackWebEvent('Delete Extension', { anonymousId });
    }

    window.location.replace(routes.kExtensionDeletePageLanding);
  }, []);
  return null;
};
