import React, { ReactElement } from 'react';
import { PaidPlan } from '../../graphql/operations';
import { FormattedMessage } from 'react-intl';

/** Convert a StripePaidPlan into human readable text */
export default function PlanName(props: { plan?: PaidPlan }): ReactElement {
  const { plan } = props;
  const interval = props.plan?.recurrenceInterval ?? 'month';
  const count =
    plan?.__typename === 'StripePaidPlan' ? plan.recurrenceIntervalCount : 1;

  if (count > 1) {
    return interval === 'month' ? (
      <FormattedMessage
        defaultMessage="Every {count} months"
        values={{ count }}
        id="eKku9B"
      />
    ) : (
      <FormattedMessage
        defaultMessage="Every {count} years"
        values={{ count }}
        id="Q60akm"
      />
    );
  }

  return interval === 'month' ? (
    <FormattedMessage defaultMessage="Monthly" id="wYsv4Z" />
  ) : (
    <FormattedMessage defaultMessage="Yearly" id="dqD39h" />
  );
}
