import React, { useRef, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  arrow as arrowMiddleware,
  FloatingArrow,
  useTransitionStyles,
  useClick,
  FloatingPortal,
  Side,
  AlignedPlacement,
} from '@floating-ui/react';
import { cx } from '../../helpers/utils';

const contentStyles = {
  default: 'bg-slate-900/90 text-white',
  brand: 'bg-brand text-white',
};

const arrowStyles = {
  default: 'fill-slate-900/90',
  brand: 'fill-brand',
};

type PopoverProps = {
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
  children: React.ReactElement;
  content: React.ReactNode;
  placement?: Side | AlignedPlacement;
  arrow?: boolean;
  className?: string;
  variant?: 'default' | 'brand';
  onOpenChange?: (open: boolean) => void;
};

const ARROW_HEIGHT = 6;
const GAP = 2;

export const Popover: React.FC<PopoverProps> = ({
  root,
  children,
  content,
  placement,
  arrow = true,
  className,
  variant = 'default',
  onOpenChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const handleOpenChange = (open: boolean) => {
    onOpenChange?.(open);
    setIsOpen(open);
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: handleOpenChange,
    placement: placement ?? 'bottom',
    // Make sure the Popover stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
      arrow &&
        arrowMiddleware({
          element: arrowRef,
        }),
    ].filter(Boolean),
  });
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context);

  // Event listeners to change the open state
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <>
      {React.cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
      })}
      {isOpen && isMounted && (
        <FloatingPortal root={root}>
          <div
            className={cx(
              'z-Menu flex max-w-72 items-center rounded-md px-2 py-2 text-xs shadow-lg outline-none',
              contentStyles[variant],
              className ?? ''
            )}
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...transitionStyles }}
            {...getFloatingProps()}
          >
            <div>{content}</div>
            {arrow && (
              <FloatingArrow
                height={ARROW_HEIGHT}
                width={8}
                ref={arrowRef}
                context={context}
                tipRadius={3}
                className={arrowStyles[variant]}
              />
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
