import { RawTranscript } from '@tactiq/model';
import { fetchApiV2 } from '../helpers';
import { MeetingShareSettings } from '../../../graphql/operations';

interface CreatePageParams {
  meetingId: string;
  title: string;
  rawTranscript: RawTranscript;
  parentId: string;
  connectionKey: string;
  shareSettings: MeetingShareSettings;
}

export async function createNotionPage(
  params: CreatePageParams
): Promise<{ link: string }> {
  return await fetchApiV2('/a/integrations/notion/create-page', {
    method: 'post',
    body: JSON.stringify(params),
  });
}

interface SearchPagesParams {
  connectionKey: string;
  query: string;
}

export interface NotionPage {
  id: string;
  name: string;
  icon: {
    type: string;
    emoji: string;
  };
}

export interface NotionSearchPagesResponse {
  pages: NotionPage[];
}

export async function searchNotionPages(
  params: SearchPagesParams
): Promise<NotionSearchPagesResponse> {
  return await fetchApiV2('/a/integrations/notion/search-pages', {
    method: 'post',
    body: JSON.stringify(params),
  });
}
