import React from 'react';
import { produce } from 'immer';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../../components/buttons';
import { Menu } from '../../../../components/Menu';
import { Combobox } from '../../../../components/Combobox';
import {
  ArrowUpDown,
  Component,
  Languages,
  Plus,
  Tags,
  User,
  Users,
  Webcam,
} from 'lucide-react';
import {
  SearchFacetType,
  SearchFacet,
  useSearchFacets,
} from '../../../../services/Search';
import { Avatar } from '../../../../components/Avatar';
import { DatePicker } from '../../../../components/DatePicker';
import {
  SearchMeetingsQueryVariables,
  SortBy,
} from '../../../../graphql/operations';
import { useSelector } from 'react-redux';
import { selectUserTags } from '../../../../redux/selectors';

type FilterType = keyof SearchMeetingsQueryVariables['filter'];

export const SearchBarFilters: React.FC<{
  filters: SearchMeetingsQueryVariables['filter'];
  setFilters: (next: SearchMeetingsQueryVariables['filter']) => void;
  sortBy: SearchMeetingsQueryVariables['sortBy'];
  setSortBy: (next: SearchMeetingsQueryVariables['sortBy']) => void;
}> = (props) => {
  const { filters, setFilters, sortBy, setSortBy } = props;
  const intl = useIntl();
  const facetData = useSearchFacets();
  const userTags = useSelector(selectUserTags);

  const extraFilters = useExtraFilters();

  // Split the extra filters into two arrays
  const usedExtras: typeof extraFilters = [];
  const extras: typeof extraFilters = [];
  extraFilters.forEach((item) => {
    if (filters[item.type]) {
      usedExtras.push(item);
    } else {
      extras.push(item);
    }
  });

  const facetOptions = (type: SearchFacetType) => {
    return (facetData[type] ?? []).toSorted((a, b) =>
      a.name.localeCompare(b.name)
    );
  };

  const comboProps = (type: Exclude<FilterType, 'created' | 'query'>) => {
    return {
      value:
        filters[type]
          ?.map((id) => facetData[type]?.find((f) => f.id === id))
          .filter((x): x is SearchFacet => Boolean(x)) ?? [],
      onRemove: () => {
        const next = produce(filters, (draft) => {
          delete draft[type];
        });
        setFilters(next);
      },
      onChange: (value: Array<{ id: string }>) => {
        const next = produce(filters, (draft) => {
          draft[type] = value.map((v) => v.id);
        });
        setFilters(next);
      },
    };
  };

  const renderTag = (facet: SearchFacet) => (
    <div className="flex items-center gap-1">
      <span className="text-xs">
        {userTags.find((tag) => tag.name === facet.name)?.icon}
      </span>
      {facet.name}
    </div>
  );

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Combobox<{ id: SortBy; name: string }>
        id={(x) => x.id}
        name={(x) => x.name}
        value={sortByOptions.find((o) => o.id === sortBy)}
        onChange={(o) => setSortBy(o?.id)}
        options={sortByOptions}
        multiple={false}
        hideSearch
        label={intl.formatMessage({ id: '25oM9Q', defaultMessage: 'Sort' })}
        icon={<ArrowUpDown size="1rem" />}
        keepSelected
        highlightWhenSelected={false}
      />
      <DatePicker
        onChange={(next) => {
          setFilters(
            produce(filters, (draft) => {
              if (next) {
                draft.created = {
                  from: next.from.getTime(),
                  to: next.to.getTime(),
                };
              } else {
                draft.created = undefined;
              }
            })
          );
        }}
        value={
          filters.created
            ? {
                from: new Date(filters.created.from),
                to: new Date(filters.created.to),
              }
            : undefined
        }
        label={intl.formatMessage({ defaultMessage: 'Date', id: 'P7PLVj' })}
      />
      <Combobox<SearchFacet>
        {...comboProps('speakers')}
        id={(x) => x.id}
        name={(x) => x.name}
        options={facetOptions('speakers')}
        label={intl.formatMessage({
          id: 'zx0myy',
          defaultMessage: 'Participants',
        })}
        icon={<Users size="1rem" />}
        renderOption={renderAvatar}
        multiple
      />
      <Combobox
        {...comboProps('tags')}
        id={(x) => x.id}
        name={(x) => x.name}
        options={facetOptions('tags')}
        label={intl.formatMessage({ id: '1EYCdR', defaultMessage: 'Tags' })}
        icon={<Tags size="1rem" />}
        renderValue={renderTag}
        renderOption={renderTag}
        multiple
      />
      <Combobox
        {...comboProps('labels')}
        id={(x) => x.id}
        name={(x) => x.name}
        options={facetOptions('labels')}
        label={intl.formatMessage({ id: 'm3QEyd', defaultMessage: 'Labels' })}
        icon={<Tags size="1rem" />}
        multiple
      />
      <Combobox<SearchFacet>
        {...comboProps('spaces')}
        id={(x) => x.id}
        name={(x) => x.name}
        options={facetOptions('spaces')}
        label={intl.formatMessage({ id: 'ZMoczs', defaultMessage: 'Spaces' })}
        icon={<Component size="1rem" />}
        multiple
        renderValue={(x) => (
          <div className="flex items-center gap-1">
            <span className="text-xs">{x.icon}</span>
            {x.name}
          </div>
        )}
        renderOption={(x) => (
          <div className="flex gap-2">
            <span>{x.icon}</span>
            {x.name}
          </div>
        )}
      />
      {usedExtras.map(({ type, label, icon: Icon, renderOption }) => (
        <Combobox
          {...comboProps(type)}
          id={(x) => x.id}
          name={(x) => x.name}
          options={facetOptions(type) ?? []}
          renderOption={renderOption}
          key={type}
          icon={<Icon size="1rem" />}
          label={label}
          multiple
        />
      ))}
      {extras.length > 0 && (
        <Menu>
          <Menu.Trigger>
            <Button
              variant="naked"
              size="small"
              startIcon={<Plus size="1rem" />}
            >
              <FormattedMessage defaultMessage="Add filter" id="M/zZVx" />
            </Button>
          </Menu.Trigger>
          {extras.map(({ type, label, icon: Icon }) => (
            <Menu.Item
              onClick={() =>
                setFilters(
                  produce(filters, (draft) => {
                    draft[type] = [];
                  })
                )
              }
              key={type}
              icon={<Icon size="1rem" />}
            >
              {label}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </div>
  );
};

function useExtraFilters(): Array<{
  type: SearchFacetType;
  label: string;
  icon: React.ComponentType<{ size?: string | number }>;
  renderOption?: (x: SearchFacet) => React.ReactNode;
}> {
  const intl = useIntl();

  return [
    {
      type: 'languages',
      label: intl.formatMessage({ defaultMessage: 'Language', id: 'y1Z3or' }),
      icon: Languages,
    },
    {
      type: 'owners',
      label: intl.formatMessage({ defaultMessage: 'Owner', id: 'zINlao' }),
      icon: User,
      renderOption: renderAvatar,
    },
    {
      type: 'platforms',
      label: intl.formatMessage({ defaultMessage: 'Platform', id: 'Mmuj1R' }),
      icon: Webcam,
    },
  ];
}

const renderAvatar = (x: SearchFacet) => (
  <div className="flex items-center gap-1 overflow-hidden">
    <Avatar name={x.name} src={x.imageUrl} />
    <div className="flex-1 truncate">{x.name}</div>
  </div>
);

const sortByOptions: Array<{ id: SortBy; name: string }> = [
  { id: SortBy.RELEVANCE, name: 'Relevance' },
  { id: SortBy.CREATED_NEWEST_FIRST, name: 'Newest first' },
  { id: SortBy.CREATED_OLDEST_FIRST, name: 'Oldest first' },
];
