import { NamedObject, RawTranscript } from '@tactiq/model';
import { fetchApiV2 } from '../helpers';
import { MeetingShareSettings } from '../../../graphql/operations';

interface CreateEventParams {
  meetingId: string;
  title: string;
  rawTranscript: RawTranscript;
  connectionKey: string;
  shareSettings: MeetingShareSettings;
  contactId: string;
}

export async function createSalesforceEvent(
  params: CreateEventParams
): Promise<{ externalId: string; link: string }> {
  return await fetchApiV2('/a/integrations/salesforce/event', {
    method: 'post',
    body: JSON.stringify(params),
  });
}

interface SearchSalesforceContactsParams {
  connectionKey: string;
  query: string;
}

export interface SearchSalesforceContactsResponse {
  data: NamedObject[];
}

export async function searchSalesforceContacts(
  params: SearchSalesforceContactsParams
): Promise<SearchSalesforceContactsResponse> {
  return await fetchApiV2('/a/integrations/salesforce/contacts', {
    method: 'post',
    body: JSON.stringify(params),
  });
}
