import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components/Alert';
import { useSelector } from 'react-redux';
import { Button } from '../../../../components/buttons';
import { Zap } from 'lucide-react';
import { TierPricingDialog } from '../../../Credits/TierPricing/TierPricingDialog';
import {
  TierPricingDialogSource,
  trackOpenUpgradeDialog,
  trackUserOutOfCreditsAlert,
} from '../../../../helpers/analytics';
import { RootState } from '../../../../redux/store';

export const RanOutOfCredits: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const userPlan = user.plan;
  const userTier = user.tier;
  const teamTier = user.team?.tier;
  const allowance = userPlan.free.aiCredits.allowance;
  const [showUpgradeDialog, setShowUpgradeDialog] = React.useState(false);

  React.useEffect(() => {
    trackUserOutOfCreditsAlert(user.id);
  }, [user.id]);

  return (
    <div>
      <Alert
        variant="light"
        severity="warning"
        description={
          <div className="flex flex-col">
            <FormattedMessage
              defaultMessage="You've used <b>{allowance}/{allowance} AI Credits</b> for the month."
              id="ClrHAo"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
                allowance,
              }}
            />
            <FormattedMessage
              defaultMessage="Upgrade your plan for more credits"
              id="LhYc5Z"
            />
          </div>
        }
        action={
          <Button
            startIcon={
              <div className="rounded bg-yellow-100 p-1 text-yellow-600">
                <Zap
                  className="h-4 w-4"
                  // This is the colour of text-yellow-600
                  fill="rgb(202 138 4 / var(--tw-text-opacity))"
                />
              </div>
            }
            size="small"
            textSize="xs"
            variant="light"
            onClick={() => {
              trackOpenUpgradeDialog(
                user.id,
                user.team?.id,
                userTier,
                teamTier
              );
              setShowUpgradeDialog(true);
            }}
          >
            <FormattedMessage defaultMessage="Upgrade now" id="9tBhzB" />
          </Button>
        }
      />
      {showUpgradeDialog && (
        <TierPricingDialog
          onClose={() => setShowUpgradeDialog(false)}
          userTier={userTier}
          teamTier={teamTier}
          source={TierPricingDialogSource.RAN_OUT_OF_CREDITS}
        />
      )}
    </div>
  );
};
