import { logger } from '@tactiq/model';
import React, { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IconGoogleClasses } from '../../../app/Landing/SignInPage';
import { Button } from '../../../components/buttons';
import { setGoogleDriveStorage } from '../../../graphql/settings';
import { saveMeetingTranscriptToGoogleDrive } from '../../../helpers/api';
import { getGoogleDriveToken } from '../../../helpers/api/integrations/googledrive';
import {
  google_client_id,
  isProduction,
} from '../../../helpers/firebase/config';
import icon from '../../../img/GoogleDrive32px.svg';
import {
  IntegrationCategory,
  IntegrationImplementation,
  IntegrationShare,
} from '../../../models/integration';
import { selectUserSettings } from '../../../redux/selectors';
import { createOAuthConnector } from './connector';
import { Switch } from '../../../components/buttons/Switch';
import { useMutation } from '@apollo/client';
import {
  SetTeamDriveDocument,
  UserIntegrationConnection,
} from '../../../graphql/operations';
import { RootState } from '../../../redux/store';
import { Spinner } from '../../../components/Spinner';

const Viewer: React.FC<{
  connection: UserIntegrationConnection;
}> = ({ connection }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="mb-2">
        <FormattedMessage
          defaultMessage="Connected to {name}"
          id="pDY1jV"
          description="Google Drive connected to"
          values={{
            name: <strong>{connection.name}</strong>,
          }}
        />
      </div>

      <h2 className="mt-4 text-2xl">
        <FormattedMessage
          defaultMessage="Settings"
          id="RqU/8Q"
          description="Google Drive Settings title"
        />
      </h2>

      <AutomaticallySaveTranscriptsToGoogleDriveSetting />
      <UseSharedDriveSetting connection={connection} />
    </div>
  );
};

const UseSharedDriveSetting: React.FC<{
  connection: UserIntegrationConnection;
}> = ({ connection }) => {
  const teamDriveSettings = useSelector(
    (state: RootState) => state.user.settings.teamDrive
  );
  const [openPicker] = useDrivePicker();
  const [accessToken, setAccessToken] = useState('');
  const [setTeamDrive] = useMutation(SetTeamDriveDocument);

  const handleOpenPicker = async () => {
    let access_token = accessToken;

    if (!access_token) {
      const token = await getGoogleDriveToken(connection.connectionKey);
      access_token = token.access_token;
      setAccessToken(token.access_token);
    }

    openPicker({
      clientId: google_client_id,
      developerKey: isProduction()
        ? 'AIzaSyBIibg6zIb1HLFzHjtBdyEj8fuitje9Wus'
        : 'AIzaSyCje8NQSmYgswaQtKIdC980PY1lEgyQ8wM',
      viewId: 'FOLDERS',
      token: access_token,
      setSelectFolderEnabled: true,
      setIncludeFolders: true,
      viewMimeTypes: 'application/vnd.google-apps.folder',
      supportDrives: true,
      multiselect: false,
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          logger.info('User clicked cancel/close button');
          return;
        }

        if (!data.docs || !data.docs[0]) {
          logger.info('No document was selected');
          return;
        }

        return setTeamDrive({
          variables: {
            input: {
              teamDriveId: data.docs[0].id,
              teamDriveLink: data.docs[0].url,
            },
          },
        });
      },
    });
  };
  return (
    <div className="flex justify-between">
      <FormattedMessage
        defaultMessage="Use shared drive"
        id="TY4e4h"
        description="Shared Drive"
      />

      <Switch
        isOn={!!teamDriveSettings?.id}
        onClick={() => {
          if (!teamDriveSettings?.id) {
            handleOpenPicker().catch((e) => logger.error(e));
          } else {
            if (confirm('Stop using Shared Drive?')) {
              return setTeamDrive({
                variables: {
                  input: {
                    teamDriveId: '',
                    teamDriveLink: '',
                  },
                },
              });
            }
          }
        }}
      />
    </div>
  );
};

const AutomaticallySaveTranscriptsToGoogleDriveSetting: React.FC = () => {
  const settings = useSelector(selectUserSettings);
  const dispatch = useDispatch();

  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (!isUpdating) {
      if (
        settings.storage?.__typename !== 'UserSettingsStorageV2' ||
        !settings.storage?.providers
      ) {
        setUpdating(true);
        setGoogleDriveStorage({ enabled: false })
          .then(() => {
            return setUpdating(false);
          })
          .catch(() => {
            return setUpdating(false);
          });
      }
    }
  }, [dispatch, isUpdating, settings]);

  if (isUpdating) return <Spinner />;

  if (settings.storage?.__typename !== 'UserSettingsStorageV2') return null;
  if (!settings.storage?.providers) return null;

  const setting = settings.storage.providers;

  return (
    <div className="flex justify-between">
      <FormattedMessage
        defaultMessage="Automatically save transcripts to Google Drive after the meeting"
        id="t1Ie9C"
        description="Automatically save transcripts to Google Drive setting"
      />

      <Switch
        isOn={settings.storage.providers?.GoogleDrive.enabled}
        onClick={async () => {
          await setGoogleDriveStorage({
            enabled: !setting.GoogleDrive.enabled,
          });
        }}
      />
    </div>
  );
};

const ViewFolderAction: React.FC = () => {
  const settings = useSelector(selectUserSettings);
  const folderId = settings.teamDrive?.id ?? settings.googleDriveFolderId;
  if (!folderId) return null;

  return (
    <Button
      target="_blank"
      href={`https://drive.google.com/drive/folders/${folderId}`}
    >
      <FormattedMessage
        defaultMessage="View Folder"
        id="w4k5wf"
        description="View Folder"
      />
    </Button>
  );
};

const shareImpl: IntegrationShare = async (_connection, options) => {
  const { meetingId, rawTranscript } = options;

  const result = await saveMeetingTranscriptToGoogleDrive(
    meetingId,
    rawTranscript
  );

  return { externalId: result.externalId, link: result.link };
};

function isConnected(conn: UserIntegrationConnection): boolean {
  return (
    conn.type === 'googledrive' &&
    ((conn.scope || '') as string).indexOf('drive.file') > -1
  );
}

const integration: IntegrationImplementation = {
  id: 'googledrive',
  category: IntegrationCategory.Storage,
  order: 99,
  title: 'Google Drive',
  icon,
  description: () => (
    <FormattedMessage
      defaultMessage="Export or automatically save your transcripts to Google Drive."
      description="Google Drive integration. Description."
      id="k1Z0Zf"
    />
  ),
  isConnected: ({ userConnections }) => {
    return Boolean(userConnections?.find(isConnected));
  },
  connector: createOAuthConnector(
    'googledrive',
    'Google Drive',
    <FormattedMessage
      defaultMessage="Connect: Sign in with Google"
      id="4VLfDS"
    />,
    'primary',
    false,
    'outlined',
    <span className={IconGoogleClasses} />
  ),
  viewer: Viewer,
  actions: [
    ViewFolderAction,
    createOAuthConnector(
      'googledrive',
      'Google Drive',
      <FormattedMessage
        defaultMessage="Reconnect: Sign in with Google"
        id="K97CMd"
      />,
      'primary',
      false,
      'outlined',
      <span className={IconGoogleClasses} />
    ),
  ],
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Create a Google Drive document"
      id="HEIzER"
      description="Google Drive integration share description"
    />
  ),
  share: shareImpl,
  connectionFilter: isConnected,
};

export default integration;
