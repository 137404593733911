import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import { TeamUpgradeOption } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import {
  selectTeam,
  selectUid,
  selectUserPlan,
  selectUserTeamUpgradeOption,
} from '../../../redux/selectors';

// User upgrade options cane be slightly confusing in the amount of options and the different user states that they can be in. There is an explainer in teamUpgradeOptions.md which contains a flowchart that explains the different states and options.

export const UpgradeOptionsButton: React.FC<{
  onUpgradeClick: () => void;
}> = ({ onUpgradeClick }) => {
  const userId = useSelector(selectUid);
  const userPlan = useSelector(selectUserPlan);
  const userTeamUpgradeOption = useSelector(selectUserTeamUpgradeOption);
  const team = useSelector(selectTeam);

  const intl = useIntl();

  function userAlreadyRequested() {
    if (team?.members.find((m) => m.uid === userId)?.requestedUpgrade) {
      trackWebEvent(
        'Transcript Page - Request upgrade account in team clicked again',
        {
          teamId: team?.id,
          user: userId,
        }
      );
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'You have already requested an upgrade.',
          id: 'O4hpDV',
        }),
        { variant: 'INFO' }
      );
      return true;
    } else {
      return false;
    }
  }

  let AddMoreCreditsButton = null;

  switch (userTeamUpgradeOption) {
    case TeamUpgradeOption.ALREADY_ON_TEAM_PLAN:
      AddMoreCreditsButton = (
        <Button
          href="https://tactiq.typeform.com/to/gQpEFxex"
          target="_blank"
          variant="naked"
          size="small"
          onClick={() => {
            trackWebEvent('Transcript Page - Request more credits.', {
              team: team?.id,
              teamMembers: team?.members?.length,
            });
          }}
        >
          <FormattedMessage defaultMessage="Talk to us" id="aX9C8R" />
        </Button>
      );
      break;
    case TeamUpgradeOption.CAN_PURCHASE_TEAM_PLAN:
      // User is not in a team (!user.team)
      // Team !isPaid && User is ADMIN (team.isPaid && team.member.find(m => m.uid === user.uid).roles.ADMIN)
      AddMoreCreditsButton = (
        <Button
          variant="naked"
          size="small"
          onClick={() => {
            onUpgradeClick();
            trackWebEvent(
              'Transcript Page - Request more credits, upgrade to team plan',
              {
                isPaidUser: userPlan.paid,
              }
            );
          }}
        >
          <FormattedMessage defaultMessage="Upgrade now" id="9tBhzB" />
        </Button>
      );
      break;
    case TeamUpgradeOption.CAN_PURCHASE_TEAM_PLAN_AND_BECOME_ADMIN:
      // Team !isPaid && User is NOT ADMIN
      AddMoreCreditsButton = (
        <Button variant="naked" size="small" onClick={onUpgradeClick}>
          <FormattedMessage defaultMessage="Upgrade now" id="9tBhzB" />
        </Button>
      );
      break;
    case TeamUpgradeOption.CAN_REQUEST_UPGRADE:
      // User is in team and on PersonalActive Plan but !ADMIN
      // Team isPaid but User is !ADMIN
      AddMoreCreditsButton = (
        <Button
          variant="naked"
          size="small"
          onClick={() => (!userAlreadyRequested() ? onUpgradeClick() : null)}
        >
          <FormattedMessage defaultMessage="Want more?" id="eo6UUT" />
        </Button>
      );
      break;
    case TeamUpgradeOption.CAN_SELF_UPGRADE:
      // User isPersonalPlanActive && IS ADMIN
      // TEAM isPaid && User is ADMIN
      AddMoreCreditsButton = (
        <Button size="small" variant="naked" onClick={() => onUpgradeClick()}>
          <FormattedMessage defaultMessage="Want more?" id="eo6UUT" />
        </Button>
      );
      break;
    case TeamUpgradeOption.MUST_SWITCH_TO_CARD_BILLING:
      AddMoreCreditsButton = (
        <Button variant="naked" size="small" onClick={() => onUpgradeClick()}>
          <FormattedMessage defaultMessage="Upgrade" id="0h/lPM" />
        </Button>
      );
      break;
  }
  return AddMoreCreditsButton;
};
