import { useMutation } from '@apollo/client';
import { MAX_DOMAIN_USERS } from '@tactiq/model';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/buttons';
import { JoinDomainTeamDocument } from '../../graphql/operations';
import { trackWebPage } from '../../helpers/analytics';
import { gotTeam } from '../../redux/modules/user';
import {
  selectDomainUsersCount,
  selectTeam,
  selectUserEmail,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';

/**
 * Shows an alert if the user is able to join an existing team in tactiq
 */
export const JoinTeamAlert: React.FC = () => {
  const domainUsersCount = useSelector(selectDomainUsersCount);
  const dispatch = useDispatch();
  const userEmail = useSelector(selectUserEmail) || '';
  const domain = userEmail.split('@')[1];
  const team = useSelector(selectTeam);
  const [inProgress, setInProgress] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const isNotVisible =
    user?.lastTeamId ||
    (domain &&
      ((team && team.members.length > 1) ||
        domainUsersCount === 0 ||
        domainUsersCount > MAX_DOMAIN_USERS));

  useEffect(() => {
    if (!isNotVisible) {
      trackWebPage('JoinTeamAlert');
    }
  }, [isNotVisible]);

  const [joinDomainTeam] = useMutation(JoinDomainTeamDocument);

  const onJoinTeamClicked = useCallback(async () => {
    setInProgress(true);
    dispatch(gotTeam((await joinDomainTeam()).data?.team_joinDomain));
  }, [dispatch, joinDomainTeam]);

  if (isNotVisible) {
    return null;
  }

  return (
    <Alert
      severity="info"
      title={
        <FormattedMessage
          defaultMessage="Join other Tactiq users in {domain}."
          id="G/c5Jf"
          description="Join team alert. Title"
          values={{
            domain: <b>{domain}</b>,
          }}
        />
      }
      description={
        <>
          <p>
            <FormattedMessage
              defaultMessage="Did you know that {count} of your colleagues already use Tactiq?"
              id="djsmeK"
              description="Join team alert. Description"
              values={{
                count: <b>{domainUsersCount}</b>,
              }}
            />
          </p>
          <p className="mt-2">
            <FormattedMessage
              defaultMessage="Join them now to get access to advanced team features."
              id="rzfWUp"
              description="Join team alert. Description"
            />
          </p>
        </>
      }
      action={
        <Button loading={inProgress} onClick={onJoinTeamClicked}>
          <FormattedMessage
            defaultMessage="Join {domain} team"
            id="MeAiJr"
            description="Join team alert. Button title"
            values={{
              domain,
            }}
          />
        </Button>
      }
    />
  );
};
