import React from 'react';
import iconZapier from '../../../img/zapier.svg';

import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import '../../../styles/zapier.css';

export const integration: IntegrationImplementation = {
  id: 'zapier',
  category: IntegrationCategory.Automation,
  title: 'Zapier',
  icon: iconZapier,
  description: () => {
    return (
      <zapier-app-directory
        app="tactiq"
        link-target="new-tab"
        theme="auto"
        create-without-template="show"
        use-this-zap="show"
      />
    );
  },
  allowMultipleConnections: false,
  waitlist: false,
  getSharingDestinationTitle: (options) => {
    return `${options.contactName}`;
  },
  isConnected: () => true,
};

export default integration;
