import React, { useEffect } from 'react';
import { ModalDialog } from '../../../../../components/modals';

export const ShareModal: React.FC<
  React.PropsWithChildren<{
    onClose: () => void;
  }>
> = ({ onClose, children }) => {
  useEffect(() => {
    document.body.style.setProperty('overflow-y', 'hidden', 'important');
    return () => {
      document.body.style.setProperty('overflow-y', null);
    };
  }, []);

  return (
    <ModalDialog open onClose={onClose}>
      {children}
    </ModalDialog>
  );
};
