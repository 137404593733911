import React, { useCallback, useContext, useMemo } from 'react';
import { BlockGroup, isBlockHighlighted } from '@tactiq/model';
import { ExtendedTranscriptBlock } from '../../types';
import { SelectionObserverContext } from '../selection-observer';
import { HighlightTooltip } from '../selection-observer/highlight';

const HighlightTooltipContainerClasses =
  'absolute flex flex-col md:right-0 top-full z-[100] pb-1';

export interface BlockHighlightTooltipProps {
  group: BlockGroup<ExtendedTranscriptBlock>;
  isEditable: boolean;
}

export const BlockHighlightTooltip: React.FC<BlockHighlightTooltipProps> = ({
  group,
  isEditable,
}) => {
  const isHighlighted = useMemo(() => {
    return isBlockHighlighted(group);
  }, [group]);
  const { messageId: groupId, messageIds } = group;
  const {
    editingGroupId,
    commentParams,
    tags,
    selection,
    onRemoveHighlight,
    onBlockSelectionClear,
    onHighlight,
    onRemoveBlock,
    onEditBlock,
    onCommentBlock,
  } = useContext(SelectionObserverContext);

  const onRemoveBlockClick = useCallback(() => {
    onRemoveBlock(Array.from(messageIds));
  }, [messageIds, onRemoveBlock]);
  const onEditBlockClick = useCallback(() => {
    onEditBlock(groupId);
  }, [groupId, onEditBlock]);
  const onCommentBlockClick = useCallback(() => {
    onCommentBlock({ groupId, commentId: null });
  }, [groupId, onCommentBlock]);

  if (
    editingGroupId !== null ||
    commentParams !== null ||
    !selection ||
    selection.groupId !== groupId
  ) {
    return null;
  }

  return (
    <div className={HighlightTooltipContainerClasses}>
      <HighlightTooltip
        tags={tags}
        onRemoveHighlight={() => {
          onRemoveHighlight(selection.messageIds);
          onBlockSelectionClear();
        }}
        onHighlight={(tag) => {
          if (
            tag &&
            group.tags.length === 1 &&
            group.tags[0].name === tag.name
          ) {
            onRemoveHighlight(Array.from(selection.messageIds));
          } else {
            onHighlight(selection, tag);
          }

          onBlockSelectionClear();
        }}
        onRemoveBlock={onRemoveBlockClick}
        onEditBlock={onEditBlockClick}
        onCommentBlock={onCommentBlockClick}
        isHighlight={isHighlighted}
        isEditable={isEditable}
      />
    </div>
  );
};
