import { useReactFlow, MarkerType, Node, useStore } from '@xyflow/react';
import { Button } from '../../components/buttons';
import {
  PlusIcon,
  Layers as TemplateIcon,
  SquarePen,
  Share2 as ShareIcon,
  Sparkles,
  ThumbsUp as ConfirmationIcon,
  GitFork as ConditionIcon,
} from 'lucide-react';
import { SpaceIcon } from '../Common/icons';
import { Menu } from '../../components/Menu';
import { nanoid } from 'nanoid';
import React, { ReactElement } from 'react';
import { useWorkflowId } from './WorkflowIdContext';
import { CustomPromptData } from './nodes/CustomPrompt';
import { nodeTypes, useNodeTypeToName } from './nodes';
import { trackAddWorkflowNode } from '../../helpers/analytics';

const noOutputNodeTypes = new Set([
  'StartNode',
  'Condition',
  'SaveToSpace',
  'Share',
]);

/**
 * A button that lets you add a node as the target of a node.
 * Adding nodes between nodes is not implemented yet
 */
export default function AddNodeButton({
  source,
  label,
  sourceHandle,
  position,
  iconBefore,
}: {
  source: string;
  label?: string;
  iconBefore?: boolean;
  sourceHandle?: string;
  position?: Node['position'];
}): ReactElement | null {
  const reactFlow = useReactFlow();
  const sourceNode = reactFlow.getNode(source);
  const { newId } = useWorkflowId();
  const nodeTypeToName = useNodeTypeToName();
  const readonly = useStore((state) => !state.nodesConnectable);

  if (!sourceNode) throw new Error('Source node not found');

  const prefillData =
    sourceNode.type && noOutputNodeTypes.has(sourceNode.type)
      ? ''
      : `{{ ${source}.output }}`;

  const onAddNode = <T = Record<string, unknown>,>(
    type: keyof typeof nodeTypes,
    data?: T
  ) => {
    const id = newId();
    const newNodePosition = position ?? {
      x: sourceNode.position.x,
      y: sourceNode.position.y + (sourceNode.measured?.height ?? 0) + 100,
    };
    trackAddWorkflowNode({ workflowId: id, type });

    reactFlow.addNodes({
      id,
      type,
      data: {
        displayName: nodeTypeToName[type] ?? type,
        ...(data ?? {}),
      },
      position: newNodePosition,
    });
    reactFlow.addEdges({
      id: nanoid(),
      sourceHandle,
      source,
      target: id,
      label,
      type: 'smoothstep',
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
    });
  };
  const icon = <PlusIcon />;

  if (readonly) return null;

  return (
    <Menu>
      <Menu.Trigger>
        <Button
          size="small"
          startIcon={iconBefore && icon}
          endIcon={!iconBefore && icon}
        >
          {label}
        </Button>
      </Menu.Trigger>
      <Menu.Item
        icon={<SpaceIcon className="size-4" />}
        onClick={() => onAddNode('SaveToSpace')}
      >
        {nodeTypeToName.SaveToSpace}
      </Menu.Item>
      <Menu.Item
        icon={<Sparkles className="size-4" />}
        onClick={() => onAddNode('RunMeetingKit')}
      >
        {nodeTypeToName.RunMeetingKit}
      </Menu.Item>
      <Menu.Item
        icon={<SquarePen className="size-4" />}
        onClick={() =>
          onAddNode<CustomPromptData>('CustomPrompt', {
            saveToMeeting: true,
            prompt: prefillData,
            aiOutputLanguage: 'en',
            includeTranscriptContext: true,
          })
        }
      >
        {nodeTypeToName.CustomPrompt}
      </Menu.Item>
      <Menu.Item
        icon={<ShareIcon className="size-4" />}
        onClick={() => onAddNode('Share')}
      >
        {nodeTypeToName.Share}
      </Menu.Item>
      <Menu.Item
        icon={<ConfirmationIcon className="size-4" />}
        onClick={() => onAddNode('Confirmation', { prompt: prefillData })}
      >
        {nodeTypeToName.Confirmation}
      </Menu.Item>
      <Menu.Item
        icon={<ConditionIcon className="size-4" />}
        onClick={() =>
          onAddNode('Condition', {
            condition: prefillData,
          })
        }
      >
        {nodeTypeToName.Condition}
      </Menu.Item>
      <Menu.Item
        icon={<TemplateIcon className="size-4" />}
        onClick={() =>
          onAddNode('Template', {
            saveToMeeting: false,
            template: prefillData,
            title: '',
          })
        }
      >
        {nodeTypeToName.Template}
      </Menu.Item>
    </Menu>
  );
}
