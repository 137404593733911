import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../../components/buttons';
import { trackWebEvent } from '../../../../helpers/analytics';
import { MeetingAiPromptOutput } from '../../../../graphql/operations';
import { useMeeting } from '../../common/meeting-hooks';
import { ThumbsUp, ThumbsDown } from 'lucide-react';
import { Tooltip } from '../../../../components/Tooltip';

export const VoteButtons: React.FC<{
  output: MeetingAiPromptOutput;
}> = ({ output }) => {
  const meeting = useMeeting();

  let type: string;

  if (output.isSystemPrompt && output.meetingKitItemId) {
    type = output.meetingKitItemId;
  } else if (output.meetingKitId) {
    type = `${output.meetingKitId}/${output.meetingKitItemId}`;
  } else {
    type = 'custom';
  }

  const intl = useIntl();
  return (
    <>
      <Button
        size="small"
        variant="naked"
        onClick={() => {
          if (!meeting) return;

          trackWebEvent('AI Summary - Like button - clicked', {
            meetingId: meeting.id,
            type,
          });
          enqueueSnackbar(
            intl.formatMessage({
              defaultMessage:
                'Thank you for your feedback! We will use it to improve our AI summary.',
              id: 'I0qp8Q',
            }),
            { variant: 'SUCCESS' }
          );
        }}
      >
        <Tooltip
          title={
            <FormattedMessage defaultMessage="Good AI response" id="DhDN43" />
          }
        >
          <ThumbsUp className="h-4 w-4 text-slate-600" />
        </Tooltip>
      </Button>

      <Button
        size="small"
        variant="naked"
        onClick={() => {
          if (!meeting) return;

          trackWebEvent('AI Summary - Dislike button - clicked', {
            meetingId: meeting.id,
          });
          enqueueSnackbar(
            intl.formatMessage({
              defaultMessage:
                'Thank you for your feedback! We will use it to improve our AI summary.',
              id: 'I0qp8Q',
            }),
            { variant: 'SUCCESS' }
          );
        }}
      >
        <Tooltip
          title={
            <FormattedMessage defaultMessage="Needs Improvement" id="PwsMOO" />
          }
        >
          <ThumbsDown className="h-4 w-4 text-slate-600" />
        </Tooltip>
      </Button>
    </>
  );
};
