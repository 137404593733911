import { v4 as uuid } from 'uuid';
import { RawBlock, logger } from '@tactiq/model';
import uniqBy from 'lodash/uniqBy';
import { TranscriptChanges, TranscriptSelection } from '../models/transcript';
import { trackWebEvent } from './analytics';
import { Tag } from '../graphql/operations';

export const splitBlock = (
  block: RawBlock,
  startOffset: number,
  endOffset: number,
  tag?: Tag
): [RawBlock[], RawBlock[]] => {
  if (block.isDeleted) {
    throw new Error('Cannot split deleted block');
  }

  const newBlocks: RawBlock[] = [];
  const deletedBlocks: RawBlock[] = [];

  if (startOffset !== 0) {
    newBlocks.push({
      ...block,
      version: Date.now(),
      messageId: `new-${uuid()}`,
      isPinned: false,
      tags: [],
      transcript: block.transcript.slice(0, startOffset),
    });
  }

  newBlocks.push({
    ...block,
    messageId: `new-${uuid()}`,
    isPinned: !tag,
    version: Date.now(),
    tags: tag ? [tag.name] : [],
    transcript: block.transcript.slice(
      startOffset,
      endOffset || block.transcript.length
    ),
  });

  if (endOffset !== block.transcript.length && endOffset !== 0) {
    newBlocks.push({
      ...block,
      version: Date.now(),
      messageId: `new-${uuid()}`,
      isPinned: false,
      tags: [],
      transcript: block.transcript.slice(endOffset),
    });
  }

  if (newBlocks.length > 1) {
    deletedBlocks.push({
      ...block,
      isDeleted: true,
    });
  } else {
    newBlocks[0].messageId = block.messageId;
  }

  return [newBlocks, deletedBlocks];
};

export const mergeBlocks = (
  blocks: RawBlock[],
  mixin: Partial<RawBlock> = {}
): { merged: RawBlock[]; deleted: RawBlock[] } => {
  const fields = { ...mixin, version: Date.now() };
  if (!blocks.length) {
    throw new Error('Blocks array is empty');
  }

  const newBlocks = [];
  const deletedBlocks = [];

  let prevBlock = { ...blocks[0], ...fields };

  for (const block of blocks.slice(1)) {
    if (
      prevBlock.type === block.type &&
      prevBlock.speakerName === block.speakerName
    ) {
      if (block.isDeleted) {
        deletedBlocks.push(block);
        continue;
      }
      if (!block.messageId.startsWith('new-')) {
        deletedBlocks.push({
          ...block,
          isDeleted: true,
        });
      }
      prevBlock.transcript =
        `${prevBlock.transcript} ${block.transcript}`.replace(/\s+/g, ' ');
    } else {
      newBlocks.push(prevBlock);
      prevBlock = { ...block, ...fields };
    }
  }

  newBlocks.push(prevBlock);

  return {
    merged: newBlocks,
    deleted: deletedBlocks,
  };
};

const getBlocksSum = (blocks: RawBlock[]): string => {
  return blocks
    .filter((block) => !block.isDeleted)
    .reduce((acc, block) => `${acc}${block.transcript}`, '')
    .replace(/\s+/g, '');
};

export const removeHighlightFromTranscriptBlocks = (
  sourceBlocks: RawBlock[],
  messageIds: string[]
): TranscriptChanges => {
  return {
    oldMessageIds: messageIds,
    newBlocks: sourceBlocks
      .filter((b) => messageIds.includes(b.messageId))
      .map((b) => ({
        ...b,
        isPinned: false,
        tags: [],
      })),
    updatedAt: Date.now(),
  };
};

export const highlightTranscriptBlocks = (
  sourceBlocks: RawBlock[],
  selection: TranscriptSelection,
  tag?: Tag
): TranscriptChanges | undefined => {
  const deletedBlocksSet = new Set(selection.messageIds);

  const firstMessageIndex = sourceBlocks.findIndex(
    (block) => block.messageId === selection.messageIds[0]
  );
  const lastMessageIndex = sourceBlocks.findIndex(
    (block) =>
      block.messageId === selection.messageIds[selection.messageIds.length - 1]
  );

  if (firstMessageIndex === -1 || lastMessageIndex === -1) {
    return;
  }

  const originalBlocks = sourceBlocks.slice(
    firstMessageIndex,
    lastMessageIndex + 1
  );
  const originalText = getBlocksSum(originalBlocks);
  let newBlocks: RawBlock[] = [];

  if (selection.messageIds.length === 1) {
    if (selection.endOffset - selection.startOffset === 1) {
      return;
    }

    const [splittedBlocks, deletedBlocks] = splitBlock(
      originalBlocks[0],
      selection.startOffset,
      selection.endOffset,
      tag
    );

    newBlocks.push(...splittedBlocks);
    deletedBlocks.forEach((b) => deletedBlocksSet.add(b.messageId));
  } else {
    let [[leftBlock, leftMergeBlock], deletedLeftBlocks] = splitBlock(
      originalBlocks[0],
      selection.startOffset,
      originalBlocks[0].transcript.length,
      tag
    );

    const [[rightMergeBlock, rightBlock], deletedRightBlocks] = splitBlock(
      originalBlocks[originalBlocks.length - 1],
      0,
      selection.endOffset,
      tag
    );

    deletedLeftBlocks.forEach((b) => deletedBlocksSet.add(b.messageId));
    deletedRightBlocks.forEach((b) => deletedBlocksSet.add(b.messageId));

    if (leftMergeBlock) {
      newBlocks.push(leftBlock);
    } else {
      leftMergeBlock = leftBlock;
    }

    const mergeResults = mergeBlocks(
      [
        leftMergeBlock,
        ...originalBlocks.slice(1, originalBlocks.length - 1),
        rightMergeBlock,
      ],
      {
        isPinned: !tag,
        tags: tag ? [tag.name] : [],
      }
    );

    newBlocks.push(...mergeResults.merged);

    mergeResults.deleted.forEach((b) => deletedBlocksSet.add(b.messageId));

    if (rightBlock) {
      newBlocks.push(rightBlock);
    }
  }

  const deletedHash: Record<string, boolean> = {};
  newBlocks = uniqBy(
    newBlocks
      .filter((block) => {
        const isDuplicate = block.isDeleted
          ? deletedHash[block.messageId]
          : false;
        deletedHash[block.messageId] = true;

        return !isDuplicate && block.transcript.trim(); // Was along the lines of this: !/^\s+$/.test(block.transcript);
      })
      .map((block) => ({
        ...block,
        messageId: block.messageId.replace('new-', ''),
      })),
    (block) => block.messageId
  );

  const newText = getBlocksSum(newBlocks);

  if (originalText !== newText) {
    trackWebEvent('Meeting View - Transcript - Highlight checksum failure');

    logger.warn('Blocks sum mismatch', {
      originalText,
      newText,
    });

    return;
  }

  return {
    oldMessageIds: Array.from(deletedBlocksSet),
    newBlocks,
    updatedAt: Date.now(),
  };
};
