import { logger } from '@tactiq/model';
import { trackWebEvent } from '../../helpers/analytics';
import { isTactiqMeeting } from '../../helpers/meetings';
import { getRawTranscriptFromExtension } from '../../helpers/storage';
import { BasicMeeting } from '../../models/meeting';
import { TactiqStore } from '../store';
import { getScreenshots, gotTranscript } from './helpers';

export async function fetchTranscript(
  store: TactiqStore,
  meeting: BasicMeeting
): Promise<void> {
  const isTactiq = isTactiqMeeting(meeting);

  if (!isTactiq) {
    try {
      const browserRawTranscript = await getRawTranscriptFromExtension(
        meeting.id
      );

      if (browserRawTranscript) {
        store.dispatch(gotTranscript(meeting.id, browserRawTranscript));

        await getScreenshots(store, meeting.id, browserRawTranscript);
      }
    } catch (err) {
      logger.error(err);

      trackWebEvent('Meeting View - Transcript browser loading failed', {
        error: err instanceof Error ? err.message : err,
      });
    }
  }
}
