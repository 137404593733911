import React from 'react';

export const DescriptionListItem: React.FC<{
  label: React.ReactNode;
  content: React.ReactNode;
}> = ({ label, content }) => {
  return (
    <div className="px-4 py-6 md:grid md:grid-cols-3 md:gap-4 md:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 md:col-span-2 md:mt-0">
        {content}
      </dd>
    </div>
  );
};
