import {
  TranscriptBlock,
  TransformedRawTranscript,
} from '../../../../models/meeting';
import { search } from '../../../../helpers/search';
import { ExtendedTranscriptBlock, ViewMode } from '../types';
import { FuseResult } from 'fuse.js';

export const transformBlocks = (
  sourceBlocks: TranscriptBlock[],
  translation: TransformedRawTranscript['translation'],
  query: string,
  viewMode: ViewMode,
  filteredParticipants: string[]
): [ExtendedTranscriptBlock[], FuseResult<ExtendedTranscriptBlock>[]] => {
  const translationBlocks = translation?.blocks || [];
  let translationBlockIndex = 0;

  let blocks = sourceBlocks.map((block, index): ExtendedTranscriptBlock => {
    const nextBlock = sourceBlocks[index + 1];
    let translationBlock = translationBlocks[translationBlockIndex];
    let translatedTranscript: string | undefined;

    while (
      translationBlock &&
      // eslint-disable-next-line no-unmodified-loop-condition
      (!nextBlock || translationBlock.timestamp < nextBlock.timestamp)
    ) {
      translatedTranscript = `${translatedTranscript ?? ''} ${
        translationBlock.transcript
      }`;
      translationBlockIndex++;
      translationBlock = translationBlocks[translationBlockIndex];
    }

    return {
      refIndex: index,
      ...block,
      translatedTranscript,
    };
  });

  if (filteredParticipants.length) {
    blocks = blocks.filter(
      (b) => !filteredParticipants.includes(b.speakerName)
    );
  }

  if (!query) {
    return [blocks, []];
  }

  const searchFields = ['speakerName'];

  if (viewMode === ViewMode.original) {
    searchFields.push('transcript');
  } else if (viewMode === ViewMode.translated) {
    searchFields.push('translatedTranscript');
  } else {
    searchFields.push('transcript', 'translatedTranscript');
  }

  const filtered: FuseResult<ExtendedTranscriptBlock>[] = search(
    blocks,
    searchFields,
    query
  );

  if (!filtered.length) {
    return [blocks, []];
  }

  let filteredIndex = 0;

  return [
    blocks.map((block, index): ExtendedTranscriptBlock => {
      if (index === filtered[filteredIndex]?.refIndex) {
        const { matches } = filtered[filteredIndex++];
        return {
          ...block,
          matches,
        };
      }

      return block;
    }),
    filtered,
  ];
};
