import React, { ReactElement, useState } from 'react';
import {
  useWorkflowList,
  useWorkflowRun,
  useWorkflowExecutionsMeeting,
} from '../../services/Workflow';
import { Menu } from '../../components/Menu';
import { Button } from '../../components/buttons';
import { FormattedMessage } from 'react-intl';
import { Network, ChevronDown, Compass } from 'lucide-react';
import { WorkflowsType } from '../../graphql/operations';
import { WorkflowDiscoveryModal } from './WorkflowDiscovery';
import {
  workflowItemActivity,
  workflowList as workflowListRoute,
} from '../../helpers/routes';
import { WorkflowStatusIcon } from './WorkflowStatusIcon';
import { Link } from 'react-router-dom';
import { WorkflowConfirmation } from '../Transcripts/view/WorkflowConfirmation';
import { WorkflowStatus } from '@tactiq/model';
import { BadgeNew } from '../Common/BadgeNew';
import { formatDistance } from 'date-fns';
import { Tooltip } from '../../components/Tooltip';
import {
  trackMyMeetingClickedWorkflowMenu,
  trackWorkflowLibraryClickedWorkflowMenu,
  trackWorkflowMenuOpened,
  trackWorkflowStarted,
} from '../../helpers/analytics';
import { Skeleton } from '../../components/Skeleton';

export default function WorkflowRunMenu(props: {
  meetingId: string;
}): ReactElement {
  const { meetingId } = props;
  const [open, setOpen] = useState(false);
  const usedWorkflows = useWorkflowList({
    refetch: false,
    input: { type: WorkflowsType.USED },
  });
  const workflowList = useWorkflowList({
    refetch: false,
    input: { type: WorkflowsType.MINE },
  });

  const executionData = useWorkflowExecutionsMeeting({ meetingId });
  const workflowRun = useWorkflowRun('meeting');
  const loading = workflowRun.loading;

  const workflows = workflowList.data?.workflows ?? [];

  const used = usedWorkflows.data?.workflows ?? [];
  const usedIds = new Set(used.map((ii) => ii.id));

  const executions = executionData.data?.workflowExecutionsMeeting ?? [];

  const fetching =
    usedWorkflows.loading || workflowList.loading || executionData.loading;

  if (fetching) return <Skeleton className="h-24 shrink-0" />;

  let workflowMenuContent = null;

  if (workflows?.length > 0) {
    workflowMenuContent = (
      <Menu width="100%">
        <Menu.Trigger>
          <Button
            variant="secondaryOutline"
            size="small"
            loading={loading}
            className="flex w-full max-w-full justify-between"
            onClick={() => trackWorkflowMenuOpened({ meetingId })}
          >
            <FormattedMessage defaultMessage="Run workflow" id="7TOSCq" />
            <ChevronDown className="w-5" />
          </Button>
        </Menu.Trigger>
        {used.length > 0 && (
          <>
            <Menu.Label>
              <FormattedMessage defaultMessage="Recently Used" id="ciiINm" />
            </Menu.Label>
            {used.slice(0, 10).map((w) => (
              <Menu.Item
                key={w.id}
                onClick={() =>
                  workflowRun.request({
                    input: { id: w.id, meetingId },
                  })
                }
              >
                {w.name}
              </Menu.Item>
            ))}
            <Menu.Divider />
          </>
        )}
        <Menu.Label>
          <FormattedMessage defaultMessage="My workflows" id="KjL2+I" />
        </Menu.Label>
        {workflows
          .filter((w) => !usedIds.has(w.id))
          .slice(0, 10)
          .map((w) => (
            <Menu.Item
              key={w.id}
              onClick={() => {
                trackWorkflowStarted({
                  workflowId: w.id,
                  meetingId,
                  source: 'meeting',
                });

                workflowRun.request({ input: { id: w.id, meetingId } });
              }}
            >
              {w.name}
            </Menu.Item>
          ))}
        <Menu.Divider />
        <Menu.Item
          icon={<Network className="size-4 stroke-slate-700" />}
          as="link"
          to={workflowListRoute}
          onClick={() => trackMyMeetingClickedWorkflowMenu({ meetingId })}
        >
          <FormattedMessage defaultMessage="My workflows" id="KjL2+I" />
        </Menu.Item>
        <Menu.Item
          icon={<Compass className="size-4 stroke-slate-700" />}
          onClick={() => {
            trackWorkflowLibraryClickedWorkflowMenu({ meetingId });
            setOpen(true);
          }}
        >
          <FormattedMessage defaultMessage="Workflow Library" id="mDVer5" />
        </Menu.Item>
      </Menu>
    );
  } else {
    workflowMenuContent = (
      <Button
        variant="secondaryOutline"
        size="small"
        fullWidth
        className="max-w-full"
        onClick={() => setOpen(true)}
      >
        <FormattedMessage defaultMessage="Workflow Library" id="mDVer5" />
      </Button>
    );
  }

  return (
    <>
      <WorkflowDiscoveryModal
        meetingId={meetingId}
        open={open}
        setOpen={setOpen}
      />
      <div className="flex flex-col gap-3 rounded-lg border border-slate-200/70 p-2.5 text-slate-600">
        <div className="flex items-center gap-2 text-sm font-medium text-slate-700">
          <Network className="size-7 rounded-lg bg-slate-100 p-1.5 text-slate-600" />
          <FormattedMessage defaultMessage="Workflows" id="6La3aS" />
          <BadgeNew visible />
        </div>
        {workflowMenuContent}
        {executions.length > 0 && (
          <>
            <div className="mt-3 text-sm font-medium text-slate-500">
              <FormattedMessage defaultMessage="Recent runs" id="MJeHfX" />
            </div>
            <div>
              {executions.map((ex) => {
                const activityPath = workflowItemActivity.replace(
                  ':id',
                  ex.workflowId
                );

                return (
                  <>
                    <Link
                      key={ex.id}
                      to={`${activityPath}/${ex.id}`}
                      relative="path"
                      className="flex cursor-pointer items-center gap-3 rounded-md p-2 hover:bg-slate-100"
                    >
                      <div className="flex-shrink-0">
                        <WorkflowStatusIcon status={ex.status} />
                      </div>
                      <div className="mr-auto flex-grow truncate">
                        {ex.workflowName}
                      </div>
                      <div className="flex gap-2 truncate text-sm tabular-nums text-gray-400">
                        <Tooltip
                          title={new Date(ex.updatedAt).toLocaleString()}
                        >
                          <span className="truncate">
                            {formatDistance(
                              new Date(ex.updatedAt),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </Link>
                    {ex.status === WorkflowStatus.WAITING_FOR_CONFIRMATION && (
                      <div className="mb-4 mt-2 flex flex-col gap-2">
                        <WorkflowConfirmation execution={ex} />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}
