import { gotRawTranscript, gotScreenshots } from '../modules/global';
import { TactiqStore } from '../store';
import { RawTranscript, sortBlocks } from '@tactiq/model';
import { getScreenshotsFromTranscript } from '../../helpers/storage';
import { fetchScreenshots } from '../../helpers/api';
import uniqBy from 'lodash/uniqBy';

export const gotTranscript = (
  meetingId: string,
  transcript: RawTranscript
): ReturnType<typeof gotRawTranscript> => {
  return gotRawTranscript({
    meetingId,
    transcript: {
      ...transcript,
      blocks: sortBlocks(removeDuplicateBlocks(transcript.blocks)),
    },
  });
};

export async function getScreenshots(
  store: TactiqStore,
  meetingId: string,
  browserRawTranscript: RawTranscript
): Promise<void> {
  const state = store.getState();
  const screenshots = getScreenshotsFromTranscript(browserRawTranscript);
  if (screenshots.length) {
    const existingScreenshots = state.global.screenshotUrls;
    const missingShots = screenshots.filter(
      (s) => !(existingScreenshots ?? {})[`${meetingId}/${s}`]
    );

    if (missingShots.length) {
      const data = await fetchScreenshots(meetingId, screenshots);
      if (data) {
        store.dispatch(
          gotScreenshots({
            meetingId,
            screenshots: data,
          })
        );
      }
    }
  }
}

export const removeDuplicateBlocks = (
  blocks: RawTranscript['blocks']
): RawTranscript['blocks'] => {
  return uniqBy(blocks, 'messageId');
};
