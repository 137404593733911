import { BlockGroup, LanguageType } from '@tactiq/model';
import { FuseResultMatch } from 'fuse.js';
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import {
  Tag,
  UserSettingsAutosaveTimestampOption,
} from '../../../../graphql/operations';
import { isMeetingEditable } from '../../../../helpers/meetings';
import { useQuery } from '../../../../helpers/router';
import {
  FullMeeting,
  MeetingParticipant,
  TranscriptBlock,
  TransformedRawTranscript,
} from '../../../../models/meeting';
import { TranscriptSelection } from '../../../../models/transcript';
import { PreviewOverlay } from '../PreviewOverlay';
import { ExtendedTranscriptBlock, ViewMode } from '../types';
import { Block } from './block';
import { SelectionObserver } from './selection-observer';

interface Props {
  detectedLanguage?: LanguageType;
  meeting: FullMeeting;
  timestampOption: UserSettingsAutosaveTimestampOption;
  blocks: TranscriptBlock[];
  transformedBlocks: BlockGroup<ExtendedTranscriptBlock>[];
  currentMatches: readonly FuseResultMatch[] | undefined;
  translation?: TransformedRawTranscript['translation'];
  viewMode: ViewMode;
  firstTimestamp: number;
  tags: Tag[];
  onHighlight: (selection: TranscriptSelection, tags?: Tag) => void;
  onRemoveHighlight: (messageIds: string[]) => void;
  onChangeBlock: (messageIds: string[], newTranscript: string) => void;
  onRemoveBlock: (messageIds: string[]) => void;
}

export interface Ref {
  scrollTo: (messageId: string) => void;
}

const findBlockIndex = (
  blocks: BlockGroup<ExtendedTranscriptBlock>[],
  predicate: (block: ExtendedTranscriptBlock) => boolean
): number | undefined => {
  let realIndex: number | undefined;
  blocks.find((group, index) => {
    const realBlock = group.blocks.find(predicate);

    if (realBlock) {
      realIndex = index;
      return true;
    }

    return false;
  });

  return realIndex;
};

const findBlockMinValueIndex = (
  blocks: BlockGroup<ExtendedTranscriptBlock>[],
  evaluate: (block: BlockGroup<ExtendedTranscriptBlock>) => number
): number | undefined => {
  let minValue = Number.MAX_VALUE;
  let minValueIndex = 0;
  for (let i = 0; i < blocks.length; i++) {
    const value = evaluate(blocks[i]);
    if (value < minValue) {
      minValue = value;
      minValueIndex = i;
    }
  }
  return minValueIndex;
};

const Transcript: ForwardRefRenderFunction<Ref | undefined, Props> = (
  props,
  ref
) => {
  const {
    blocks: sourceBlocks,
    transformedBlocks,
    meeting,
    firstTimestamp,
    timestampOption,
    tags,
    viewMode,
    currentMatches,
    onHighlight,
    onRemoveHighlight,
    onChangeBlock,
    onRemoveBlock,
  } = props;

  const isPreview = meeting.isPreview ?? false;
  const isArchived = !!meeting.archivedAt;
  const isEditable = isMeetingEditable(meeting);

  const [scrollFocusHighlight, setScrollFocusHighlight] = useState<
    [number, number] | undefined
  >(undefined);

  const virtuoso = useRef<VirtuosoHandle>();
  const scrollTo = useCallback(
    (messageId: string) => {
      const realIndex = findBlockIndex(
        transformedBlocks,
        (block) => block.messageId === messageId
      );

      if (realIndex === undefined) {
        return;
      }

      setScrollFocusHighlight([realIndex, realIndex]);

      virtuoso.current?.scrollToIndex({
        index: realIndex,
        align: 'start',
        offset: -150,
        behavior: 'smooth',
      });
    },
    [transformedBlocks, virtuoso]
  );

  const scrollToTimestamp = useCallback(
    (startTimestamp: number, endTimestamp: number) => {
      const startIndex = findBlockMinValueIndex(transformedBlocks, (block) => {
        const offset = block.timestamp - firstTimestamp;
        return Math.abs(startTimestamp - offset);
      });

      if (startIndex === undefined) {
        return;
      }

      let endIndex = findBlockMinValueIndex(transformedBlocks, (block) => {
        const offset = block.timestamp - firstTimestamp;
        return Math.abs(endTimestamp - offset);
      });

      if (endIndex === undefined) {
        endIndex = startIndex;
      }

      setScrollFocusHighlight([startIndex, endIndex]);
      virtuoso.current?.scrollToIndex({
        index: startIndex,
        align: 'start',
        offset: -150,
      });
    },
    [firstTimestamp, transformedBlocks, virtuoso]
  );

  // subscribe to a global event to scroll to a specific message
  useEffect(() => {
    const handleScrollToMessageId = (e: CustomEvent) => {
      scrollTo(e.detail.messageId);
    };
    const handleScrollToMessageTimestamp = (
      e: CustomEvent<{
        startTimestamp: number;
        endTimestamp: number;
      }>
    ) => {
      scrollToTimestamp(e.detail.startTimestamp, e.detail.endTimestamp);
    };

    document.addEventListener(
      'scrollToTranscriptMessageById',
      handleScrollToMessageId
    );
    document.addEventListener(
      'scrollToTranscriptMessageByTimestamp',
      handleScrollToMessageTimestamp
    );

    return () => {
      document.removeEventListener(
        'scrollToTranscriptMessageById',
        handleScrollToMessageId
      );
      document.removeEventListener(
        'scrollToTranscriptMessageByTimestamp',
        handleScrollToMessageTimestamp
      );
    };
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      scrollTo,
    }),
    [scrollTo]
  );

  const searchParams = useQuery();
  useEffect(() => {
    const messageId = searchParams.get('s') ?? searchParams.get('b');

    if (messageId) {
      scrollTo(messageId);
    }
  }, [scrollTo, searchParams]);

  return (
    <div className="relative min-h-[200px] pb-[85px]">
      <SelectionObserver
        blocks={sourceBlocks}
        tags={tags}
        onHighlight={onHighlight}
        onRemoveHighlight={onRemoveHighlight}
        onRemoveBlock={onRemoveBlock}
      >
        <Virtuoso
          overscan={500}
          ref={virtuoso as any}
          data={isPreview ? transformedBlocks.slice(0, 5) : transformedBlocks}
          itemContent={(index, x) => {
            const prevSpeakerName = transformedBlocks[index - 1]?.speakerName;
            const hasScrollHighlight =
              scrollFocusHighlight &&
              index >= scrollFocusHighlight[0] &&
              index <= scrollFocusHighlight[1];
            return (
              <div
                className={
                  hasScrollHighlight ? 'motion-safe:animate-focushighlight' : ''
                }
                onAnimationEnd={() => setScrollFocusHighlight(undefined)}
              >
                <Block
                  group={x}
                  timestampOption={timestampOption}
                  firstTimestamp={firstTimestamp}
                  viewMode={viewMode}
                  speakerColor={
                    (meeting.participants as MeetingParticipant[]).find(
                      (participant: MeetingParticipant) =>
                        participant.name === x.speakerName
                    )?.color ?? '#000'
                  }
                  showSpeakerName={x.speakerName !== prevSpeakerName}
                  currentMatches={currentMatches}
                  isEditable={isEditable}
                  isArchived={isArchived}
                  onChange={onChangeBlock}
                />
              </div>
            );
          }}
          useWindowScroll
        />
      </SelectionObserver>

      {isPreview && <PreviewOverlay />}
    </div>
  );
};

export default forwardRef(Transcript);
