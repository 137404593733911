import React, { useEffect, useState } from 'react';
import { ModalDialog } from '../../components/modals';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/buttons';
import { SpaceMember, TeamMember } from '../../graphql/operations';
import { AddMembers } from './AddMembers';
import { useSpaceUpateMutation } from './helpers';
import { trackOpenSpaceSettingsMembers } from '../../helpers/analytics';
import { useSpace } from '../../services/Space';

export const AddMembersDialog: React.FC<{
  onClose: () => void;
  open: boolean;
  spaceId: string;
  currentMembers: SpaceMember[];
}> = ({ onClose, spaceId, currentMembers, open }) => {
  const intl = useIntl();
  const [selectedMembers, setSelectedMembers] = useState<Array<TeamMember>>([]);
  const [canAddMeetings, setCanAddMeetings] = useState(true);
  const [canManageMeetings, setCanManageMeetings] = useState(false);

  const { onUpdate, updateResult } = useSpaceUpateMutation();
  const space = useSpace({ id: spaceId });

  useEffect(() => {
    if (open) trackOpenSpaceSettingsMembers();
  }, [open]);

  const onSubmit = async () => {
    await onUpdate({
      id: spaceId,
      members: [
        ...selectedMembers.map((user) => ({
          uid: user.uid,
          permissions: {
            ADD: canAddMeetings,
            MANAGE: canManageMeetings,
          },
        })),
        ...currentMembers.map(({ uid, permissions }) => ({
          uid,
          permissions: {
            ADD: permissions.ADD,
            MANAGE: permissions.MANAGE,
          },
        })),
      ],
    });
    onClose();
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title=""
      actions={
        <div className="flex flex-1 justify-between">
          <Button onClick={onClose} variant="light" color="primary">
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
          <Button
            loading={updateResult.loading}
            onClick={onSubmit}
            variant="filled"
            color="primary"
          >
            <FormattedMessage defaultMessage="Add" id="2/2yg+" />
          </Button>
        </div>
      }
      size="regular"
    >
      <AddMembers
        isPersonalSpace={!space.data?.space?.teamId}
        label={intl.formatMessage({
          defaultMessage: 'Add members',
          id: 'OCiHj6',
        })}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        canManageMeetings={canManageMeetings}
        canAddMeetings={canAddMeetings}
        setCanAddMeetings={setCanAddMeetings}
        setCanManageMeetings={setCanManageMeetings}
        excludedUsers={currentMembers.map(({ uid }) => uid)}
        onClose={onClose}
      />
    </ModalDialog>
  );
};
