import { FuseResultMatch } from 'fuse.js';
import { TranscriptBlock } from '../../../models/meeting';

export enum ViewMode {
  original = 'original',
  translated = 'translated',
  combined = 'combined',
}

export interface ExtendedTranscriptBlock extends TranscriptBlock {
  refIndex: number;
  matches?: ReadonlyArray<FuseResultMatch>;
  translatedTranscript?: string;
}
