import { useSelector } from 'react-redux';
import { selectUid } from '../redux/selectors';
import { useMutation } from '@apollo/client';
import { UpdateUserDisplayNameDocument } from '../graphql/operations';
import { MutationResult } from './helpers';

/**
 * The id of the currently logged in user
 */
export function useCurrentUserId(): string {
  const userId = useSelector(selectUid);
  if (!userId) throw new Error('useCurrentUserId used while unauthenticated');
  return userId;
}

/** Updating users displayName */
export function useUpdateUserDisplayName(): MutationResult<
  typeof UpdateUserDisplayNameDocument
> {
  const [fn, { data, loading, error }] = useMutation(
    UpdateUserDisplayNameDocument
  );

  return {
    request: (variables) => fn({ variables: { input: variables.input } }),
    data,
    error,
    loading,
  };
}
