import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import iconHubspot from '../../../img/hubspot.png';
import { LoadingContent } from '../../Landing/Loading/LoadingComponent';

import {
  IntegrationCategory,
  IntegrationImplementation,
  IntegrationShare,
  ShareDialogProps,
} from '../../../models/integration';
import { fetchApiV2 } from '../../../helpers/api/helpers';
import { NamedObject, SharingResult } from '@tactiq/model';
import { createOAuthConnector } from './connector';
import {
  ShareDialogFormClasses,
  ShareDialogListWrapperClasses,
  ShareDialogWrapperClasses,
} from './styles';
import { trackWebEvent } from '../../../helpers/analytics';
import { useAsyncEffect } from 'use-async-effect';
import { Search, User2 } from 'lucide-react';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/buttons';

const ShareDialog: React.FC<ShareDialogProps> = (props) => {
  const { connection, share } = props;
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState<NamedObject[]>([]);
  const intl = useIntl();

  useAsyncEffect(
    async () => {
      if (connection && isLoading) {
        const result = (await fetchApiV2('/a/integrations/hubspot/contacts', {
          method: 'POST',
          body: JSON.stringify({
            query,
            connectionKey: connection.connectionKey,
          }),
        })) as {
          contacts: NamedObject[];
        };
        setContacts(result.contacts);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connection, isLoading]
  );

  const listContent = contacts.length ? (
    contacts.map((x) => (
      <li key={x.id}>
        <Button
          variant="naked"
          className="w-full"
          onClick={() => {
            trackWebEvent('Selected a contact in HubSpot to share into');
            return share(integration, connection, {
              user_id: connection.user_id,
              contactId: x.id,
              contactName: x.name,
            });
          }}
          startIcon={<User2 size="1rem" />}
        >
          {x.name}
        </Button>
      </li>
    ))
  ) : (
    <li>
      <FormattedMessage
        defaultMessage="No contacts was found. Try another search term."
        id="OzILQH"
        description="HubSpot integration. No contacts found message text."
      />
    </li>
  );

  return (
    <div className={ShareDialogWrapperClasses}>
      <div>
        <FormattedMessage
          defaultMessage="Please select contact. Your new meeting log will be created for it."
          description="HubSpot integration. Select contact message."
          id="x4vnhj"
        />
      </div>
      <form
        className={ShareDialogFormClasses}
        onSubmit={(event) => {
          setIsLoading(true);

          event.preventDefault();
        }}
      >
        <TextInput
          placeholder={intl.formatMessage({
            defaultMessage: 'Search HubSpot contacts',
            id: 'xr3IyP',
            description:
              'HubSpot integration. Search contacts input placeholder.',
          })}
          inputProps={{
            'aria-label': intl.formatMessage({
              defaultMessage: 'search HubSpot contacts',
              id: 'Gj0uJY',
              description:
                'HubSpot integration. Search contacts input aria-label.',
            }),
            name: 'query',
          }}
          disabled={isLoading}
          value={query}
          onChange={setQuery}
        />
        <Button
          variant="icon"
          type="submit"
          aria-label={intl.formatMessage({
            defaultMessage: 'Search',
            id: 'QMAOeb',
            description: 'HubSpot integration. Search button icon aria-label.',
          })}
        >
          <Search className="h-6 w-6" />
        </Button>
      </form>
      {isLoading ? (
        <LoadingContent />
      ) : (
        <ul className={ShareDialogListWrapperClasses}>{listContent}</ul>
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options) => {
  const {
    title,
    meetingDetails,
    highlights,
    footerLink,
    footerLinkText,
    isSharingDetails,
    isSharingHighlights,
    isSharingTranscript,
  } = options;
  const { start, startMillis, duration, durationMillis, participants } =
    meetingDetails;

  const lines = [];

  if (isSharingDetails) {
    lines.push(`<p>Started: ${start}</p>
      <p>Lasted: ${duration}</p>
      <p>Participants: ${participants}</p>`);
  }

  if (isSharingHighlights) {
    lines.push(
      `<p>Highlights:</p>
      <ul>${highlights
        .map(
          (block) =>
            `<li>${block.blocks[0].fromStart} <b>${block.speakerName}</b>: ${block.transcript}</li>`
        )
        .join('')}
      </ul>`
    );
  }

  if (isSharingTranscript) {
    lines.push(`<p><a href="${footerLink}">${footerLinkText}</a></p>`);
  }

  const data = {
    engagement: {
      type: 'MEETING',
      timestamp: startMillis,
    },
    associations: {
      contactIds: [options.contactId],
      companyIds: [],
      dealIds: [],
      ownerIds: [],
      ticketIds: [],
    },
    metadata: {
      // no title property here to allow editing in HubSpot
      body: `<b>${title}</b>${lines.join(' ')}`,
      startTime: startMillis,
      endTime: Math.ceil((startMillis + durationMillis) / 60000) * 60000,
    },
  };

  const result = (await fetchApiV2('/a/integrations/hubspot/activity', {
    method: 'POST',
    body: JSON.stringify({
      connectionKey: connection.connectionKey,
      user_id: connection.user_id,
      content: {
        vid: options.contactId,
        data,
      },
    }),
  })) as SharingResult;

  return { externalId: result.externalId, link: result.link };
};

export const integration: IntegrationImplementation = {
  id: 'hubspot',
  category: IntegrationCategory.CRM,
  title: 'HubSpot',
  icon: iconHubspot,
  description: () => (
    <FormattedMessage
      defaultMessage="Link your transcripts with contacts and deals in Hubspot, and create tasks based on your highlights and tags."
      description="Hubspot integration description"
      id="fKRUtE"
    />
  ),
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Attach to a record"
      id="2T36+/"
      description="HubSpot integration share description"
    />
  ),
  connector: createOAuthConnector('hubspot', 'Hubspot'),
  allowMultipleConnections: false,
  share: shareImpl,
  ShareDialog,
  waitlist: false,
  getSharingDestinationTitle: (options) => `${options.contactName}`,
};

export default integration;
