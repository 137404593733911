import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/buttons';
import { trackWebEvent } from '../../helpers/analytics';
import { Alert } from '../../components/Alert';

export const AlertZoomWebSettingsInfo: React.FC = () => {
  return (
    <Alert
      severity="info"
      action={
        <Button
          href="https://zoom.us/profile/setting"
          onClick={() =>
            trackWebEvent(
              'Clicked Enable (Settings) link in the Zoom requirements alert'
            )
          }
          color="info"
          target="_blank"
        >
          Enable
        </Button>
      }
      description={
        <FormattedMessage
          defaultMessage="You need to enable captions in Zoom Settings"
          id="sR8FEw"
        />
      }
    />
  );
};
