import { fetchApiV2 } from '../helpers';

export async function createOauthRedirectUrl(
  integrationType: string
): Promise<{ url: string }> {
  return await fetchApiV2(
    `/a/integrations/${integrationType}/redirect/create`,
    {
      method: 'post',
      body: JSON.stringify({
        returnUrl: window.location.href,
      }),
    }
  );
}
