import Tactiq from './tactiq';
import GoogleMeet from './google-meet';
import GoogleDrive from './google-drive';
import GoogleCalendar from './google-calendar';
import Video from './video';
import Quip from './quip';
import Notion from './notion';
import Zoom from './zoom';
import ZoomAPI from './zoomapi';
import ZoomAPP from './zoomapp';
import Slack from './slack';
import Link from './link';
import Email from './email';
import HubSpot from './hubspot';
import Webex from './webex';
import MSTeams from './msteams';
import Pipedrive from './pipedrive';
import Confluence from './confluence';
import Salesforce from './salesforce';
import Zapier from './zapier';

import Waitlist from './waitlist';
import { IntegrationImplementation } from '../../../models/integration';

const ImplementedIntegrations: IntegrationImplementation[] = [
  Tactiq,
  GoogleMeet,
  GoogleDrive,
  GoogleCalendar,
  Video,
  Quip,
  Notion,
  Confluence,
  Zoom,
  ZoomAPI,
  ZoomAPP,
  Slack,
  Link,
  Email,
  HubSpot,
  MSTeams,
  Webex,
  Pipedrive,
  Salesforce,
  Zapier,
];

export const AllIntegrations: IntegrationImplementation[] =
  ImplementedIntegrations.concat(Waitlist).sort((a, b) => {
    if ((a.connector || b.connector) && !(a.connector && b.connector)) {
      return (a.connector ? 0 : 1) - (b.connector ? 0 : 1);
    } else {
      if (a.order && !b.order) {
        return -1;
      } else if (!a.order && b.order) {
        return 1;
      } else if (a.order !== b.order) {
        return (a.order ?? 0) - (b.order ?? 0);
      } else {
        return a.title.localeCompare(b.title);
      }
    }
  });
