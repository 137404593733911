import { MutationResult, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  UpdateSpaceDocument,
  UpdateSpaceInput,
  UpdateSpaceMutation,
} from '../../graphql/operations';
import { gotSpace } from '../../redux/modules/global';

export const useSpaceUpateMutation = (): {
  onUpdate: (input: UpdateSpaceInput) => Promise<void>;
  updateResult: MutationResult<UpdateSpaceMutation>;
} => {
  const dispatch = useDispatch();
  const [updateSpace, updateResult] = useMutation(UpdateSpaceDocument);

  const onUpdate = async (input: UpdateSpaceInput) => {
    const response = await updateSpace({
      variables: {
        input,
      },
    });

    // Error handing
    if (response.data?.updateSpace.success) {
      const updatedSpace = response.data?.updateSpace.space;
      updatedSpace && dispatch(gotSpace(updatedSpace));
    }
  };

  return { onUpdate, updateResult };
};
