import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { trackWebEvent } from '../../../helpers/analytics';
import { IntegrationImplementation } from '../../../models/integration';
import { Alert } from '../../../components/Alert';
import { PlusIcon, Video } from 'lucide-react';
import { Link } from '../../../components/Link';

const VideoConnector: React.FC = () => {
  return (
    <div className="flex flex-col gap-2">
      <Alert
        severity="info"
        description={
          <>
            <FormattedMessage
              defaultMessage="Tactiq addon for Google Calendar allows to attach video recordings to your transcripts."
              description="Google Calendar integration. Description"
              id="52aslA"
            />
            <br />
            <Link
              to="https://help.tactiq.io/en/articles/5820600-link-meeting-video-recording-to-the-transcript"
              target="_blank"
              blue
            >
              <FormattedMessage
                defaultMessage="Learn more about Google Calendar integration"
                description="Google Calendar integration. Help center link"
                id="f+Oyr8"
              />
            </Link>
          </>
        }
      />

      <Button
        color="primary"
        startIcon={<PlusIcon className="h-6 w-6" />}
        onClick={() => {
          trackWebEvent(
            'Clicked Link - Install Google Calendar Addon for video'
          );
          window.open(
            'https://workspace.google.com/u/0/marketplace/app/tactiqio/399035273123',
            '_blank'
          );
        }}
      >
        <FormattedMessage
          defaultMessage="Install Tactiq for Google Calendar"
          description="Google Calendar integration. Install button label."
          id="p3zRBq"
        />
      </Button>
    </div>
  );
};

const integration: IntegrationImplementation = {
  id: 'video',
  order: 2,
  title: 'Video',
  icon: () => <Video className="m-2 ml-3 mr-1 size-8" />,
  description: () => (
    <FormattedMessage
      defaultMessage="Attach meeting recordings with the transcripts, so you can revisit the key moments of the video"
      description="Video integration. Description."
      id="9A8O66"
    />
  ),
  connector: VideoConnector,
  isConnected: () => false,
};

export default integration;
