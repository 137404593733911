import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserSettingsSharingParticipants } from '../../../../graphql/operations';
import {
  getMeetingParticipantEmails,
  createSelectRawTranscriptSelector,
  selectUserEmail,
  selectUserSettings,
} from '../../../../redux/selectors';
import { AutomaticShareDialog } from './AutomaticShareDialog';
import { QuickShare as QuickShareImpl } from './QuickShare';
import { RootState } from '../../../../redux/store';
import { isMeetingShared } from '../../../../helpers/meetings';
import { useFullMeeting } from '../../common/meeting-hooks';

export const QuickShare: React.FC<{ meetingId: string }> = ({ meetingId }) => {
  const meeting = useFullMeeting();
  const isShared = isMeetingShared(meeting);
  const [isAutomaticShareDialogOpen, setIsAutomaticShareDialogOpen] =
    useState(false);
  const userEmail = useSelector(selectUserEmail);
  const userSettings = useSelector(selectUserSettings);
  const suggestedParticipants = getMeetingParticipantEmails(meeting, userEmail);
  const selectRawTranscript = useMemo(createSelectRawTranscriptSelector, []);
  const transcript = useSelector((state: RootState) =>
    selectRawTranscript(state, meetingId)
  );

  let content = null;

  const onShare = () => {
    if (
      userSettings.sharing?.participants !==
        UserSettingsSharingParticipants.AUTO &&
      !userSettings.lastAutomaticShareDialogTimestamp
    ) {
      setIsAutomaticShareDialogOpen(true);
    }
  };

  if (isShared || !suggestedParticipants.length || !transcript) {
    content = null;
  } else {
    content = (
      <QuickShareImpl
        meetingId={meetingId}
        rawTranscript={transcript}
        suggestedParticipants={suggestedParticipants}
        onShare={onShare}
      />
    );
  }

  return (
    <div>
      {content}
      <AutomaticShareDialog
        open={isAutomaticShareDialogOpen}
        onClose={() => setIsAutomaticShareDialogOpen(false)}
      />
    </div>
  );
};
