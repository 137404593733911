import { WorkflowStatus } from '@tactiq/model';
import {
  CircleCheck,
  CircleDashed,
  AppWindow,
  CircleX,
  CircleAlert,
} from 'lucide-react';
import React, { ReactElement } from 'react';
import { Spinner } from '../../components/Spinner';

export function WorkflowStatusIcon(props: {
  status: WorkflowStatus;
}): ReactElement {
  switch (props.status) {
    case WorkflowStatus.PENDING:
      return <Spinner className="m-1" />;

    case WorkflowStatus.COMPLETE:
      return <CircleCheck className="text-green-500" />;

    case WorkflowStatus.NOT_STARTED:
      return <CircleDashed className="text-gray-300" />;

    case WorkflowStatus.WAITING_FOR_CONFIRMATION:
      return <AppWindow className="text-green-500" />;

    case WorkflowStatus.TIMED_OUT:
      return <CircleAlert className="text-gray-400" />;

    case WorkflowStatus.FAILED:
      return <CircleX className="text-red-500" />;
  }
}
