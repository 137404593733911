import Picker, { Emoji, PickerProps } from 'emoji-picker-react';
import React from 'react';
import { Popover } from '@headlessui/react';
import { Button } from '../../../components/buttons';

export const EmojiField: React.FC<{
  onChange?: PickerProps['onEmojiClick'];
  EmojiProps?: Parameters<typeof Emoji>[0];
  value: string;
  defaultIcon: React.ReactElement;
}> = ({ onChange, defaultIcon, value, EmojiProps }) => {
  const icon = value ? <Emoji {...EmojiProps} unified={value} /> : defaultIcon;

  return onChange ? (
    <Popover>
      <Popover.Button as={Button} variant="naked" size="icon">
        {icon}
      </Popover.Button>

      <Popover.Panel className="absolute z-20 mt-2 w-56 max-w-sm px-4 sm:px-0">
        {({ close }) => (
          <Picker
            lazyLoadEmojis={true}
            onEmojiClick={(emoji, event) => {
              onChange?.(emoji, event);
              close();
            }}
          />
        )}
      </Popover.Panel>
    </Popover>
  ) : (
    icon
  );
};
