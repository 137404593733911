import React from 'react';
import { FormattedMessage } from 'react-intl';
import TranscriptSection, {
  TranscriptSectionProps,
} from '../TranscriptSection';
import { TranscriptCommentList } from './CommentList';

export type TranscriptCommentsComponentProps = Omit<
  TranscriptSectionProps,
  'header'
> & {
  meetingId: string;
  canDelete: boolean;
};

export const TranscriptCommentsComponent: React.FC<
  TranscriptCommentsComponentProps
> = ({ meetingId, canDelete, ...transcriptSectionProps }) => {
  return (
    <TranscriptSection
      header={
        <FormattedMessage
          defaultMessage="Comments"
          id="OUT4Ad"
          description="Meeting view. Comments header."
        />
      }
      {...transcriptSectionProps}
    >
      <TranscriptCommentList meetingId={meetingId} canDelete={canDelete} />
    </TranscriptSection>
  );
};
