import {
  RawBlock as WidgetTranscriptBlock,
  MeetingParticipant as TactiqMeetingParticipant,
  RawTranscript,
  SystemTags,
} from '@tactiq/model';
import {
  MeetingBasicFieldsFragment,
  MeetingFullFieldsFragment,
  Tag,
} from '../graphql/operations';

export interface MeetingParticipant extends TactiqMeetingParticipant {
  color: string;
}

export interface Block
  extends Omit<WidgetTranscriptBlock, 'isDirty' | 'messageVersion'> {
  deviceId?: string;
  speakerName: string;
  transcript: string;
  timestamp: number;
  fromStart: string;
  fromStartHM: string;
}

export interface TransformedRawTranscript extends RawTranscript<Block> {
  participants: string[];
}

export interface TranscriptBlock extends Omit<Block, 'tags'> {
  tags: Tag[];
  index: number;
}

export type FullMeeting = MeetingFullFieldsFragment;
export type BasicMeeting = MeetingBasicFieldsFragment;
export type AnyMeeting = (BasicMeeting | FullMeeting) & {
  uploadProgress?: number;
};

export const HIGHLIGHT_TAG_GQL: Tag = {
  icon: '✏️',
  name: 'Highlight',
  isSystem: true,
};

export const SystemTagsGQL: Tag[] = SystemTags.map((t) => ({
  ...t,
  isSystem: true,
}));
