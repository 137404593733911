import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { liquid } from '@codemirror/lang-liquid';
import { linter, Diagnostic } from '@codemirror/lint';
import { markdown } from '@codemirror/lang-markdown';
import { EditorView } from '@codemirror/view';
import type { Completion, CompletionContext } from '@codemirror/autocomplete';
import type { EditorState } from '@codemirror/state';
import { cx } from '../../helpers/utils';

import { githubLight as syntaxTheme } from '@uiw/codemirror-theme-github';
import { Button } from '../buttons';
import { Menu } from '../Menu';
import { getFlatVariableList } from '../../app/Workflows/nodes/useAutocomplete';
import { FormattedMessage } from 'react-intl';
import { TokenizationError, ParseError, Liquid } from 'liquidjs';
import { trackAddWorkflowTemplateVariable } from '../../helpers/analytics';

export { Completion, CompletionContext, EditorState };

const slate800 = '#1e293b';
const slate500 = '#64748b';
const slate200 = '#e2e8f0';

const styleOverrides = EditorView.theme({
  '&': {
    background: `transparent`,
    left: '0px',
    right: '0px',
    top: '0px',
    bottom: '0px',
    position: 'absolute !important',
    borderRadius: '0.25rem',
    padding: '0.125em 0.25rem',
    fontFamily: 'inherit',
    fontSize: '.9rem',
  },
  '&.cm-focused': {
    outline: 'none',
  },
  '.cm-scroller': { fontFamily: 'monospace' },
  '.cm-gutters': { border: 'none' },
  '.cm-activeLineGutter': { background: 'transparent' },
  '.cm-tooltip-autocomplete > ul > li': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: '.25rem',
    fontFamily: 'inherit',
  },
  '.cm-tooltip': {
    fontFamily: 'inherit',
  },
  '.cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected="true"]': {
    fontFamily: 'inherit',
    background: slate200,
    color: slate800,
  },
  '.cm-tooltip.cm-tooltip-autocomplete > ul': {
    fontFamily: 'inherit',
  },
  '.cm-tooltip-autocomplete': {
    border: `1px solid ${slate200}`,
    borderRadius: '.5rem',
    padding: '.5rem',
    overflow: 'hidden',
    background: 'white',
  },
  '.cm-completionIcon': {
    display: 'none',
  },
  '.cm-completionLabel': {
    color: slate800,
    padding: '.25rem .5rem',
  },
  '.cm-completionDetail': {
    color: slate500,
    fontSize: '.8rem',
    fontStyle: 'normal',
    marginLeft: 'auto',
    padding: '.25rem .5rem',
  },
});

function parseError(error: TokenizationError | ParseError): {
  from: number;
  to: number;
  message: string;
} {
  const parts = error.message.split(',');
  const message = parts.slice(0, -2).join(',');
  return {
    from: error.token?.begin || 0,
    to: error.token?.end || 0,
    message,
  };
}

const liquidLinter =
  () =>
  (view: EditorView): Diagnostic[] => {
    const liquidEngine = new Liquid();
    try {
      const value = view.state.doc.toString();
      liquidEngine.parse(value);
    } catch (e) {
      const { from, to, message } = parseError(e);
      return [
        {
          from,
          message,
          severity: 'error',
          to,
        },
      ];
    }
    return [];
  };

const _LiquidTemplateInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  tags?: Completion[];
  filters?: Completion[];
  variables?: Completion[];
  properties?: (
    path: readonly string[],
    state: EditorState,
    context: CompletionContext
  ) => Completion[];
  style?: React.CSSProperties;
  className?: string;
  lineNumbers?: boolean;
  highlightActiveLine?: boolean;
  foldGutter?: boolean;
  workflowId: string;
  nodeType: string | undefined;
}> = ({
  value,
  onChange,
  style,
  className,
  tags,
  filters,
  variables,
  properties,
  workflowId,
  nodeType,
  lineNumbers = false,
  highlightActiveLine = false,
  foldGutter = false,
}) => {
  const liquidConfig = {
    tags,
    filters,
    variables,
    properties,
    lineWrap: true,
    base: markdown(),
    closePercentBrace: true,
  };

  const extensions = [
    liquid(liquidConfig),
    syntaxTheme,
    EditorView.lineWrapping,
    linter(liquidLinter()),
  ];

  return (
    <div
      className={cx(
        'nodrag relative grow rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600',
        className
      )}
      style={style}
    >
      <CodeMirror
        basicSetup={{
          lineNumbers,
          highlightActiveLine,
          foldGutter,
          searchKeymap: true,
        }}
        theme={styleOverrides}
        value={value}
        extensions={extensions}
        onChange={onChange}
      />
      <div className="absolute bottom-1 right-1">
        <Menu>
          <Menu.Trigger>
            <Button variant="naked" size="tiny">
              <FormattedMessage defaultMessage="Variables" id="tr0YPg" />
            </Button>
          </Menu.Trigger>
          {getFlatVariableList(variables ?? []).map(
            ({ group, templates }, index, list) => {
              if (templates.length === 0) return null;
              return (
                <React.Fragment key={group}>
                  {index !== 0 && list[index - 1]?.templates?.length > 0 && (
                    <Menu.Divider />
                  )}
                  <Menu.Label>{group}</Menu.Label>

                  {templates.map(({ label, template }) => (
                    <Menu.Item
                      key={group + template}
                      onClick={() => {
                        trackAddWorkflowTemplateVariable({
                          workflowId,
                          type: nodeType,
                          template,
                        });
                        onChange(`${value} ${template}`);
                      }}
                    >
                      <div className="flex w-full items-center justify-between gap-2 truncate">
                        {label}
                        <span className="truncate text-xs text-slate-400">
                          {template}
                        </span>
                      </div>
                    </Menu.Item>
                  ))}
                </React.Fragment>
              );
            }
          )}
        </Menu>
      </div>
    </div>
  );
};

export const LiquidTemplateInput = React.memo(_LiquidTemplateInput);
