import { LabelConfig } from '@tactiq/model';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeam, selectUid } from '../../../../redux/selectors';
import { AddLabelButton } from '../../../Common/AddLabelButton';
import { Label } from '../../../Common/Label';
import { useMeeting } from '../../common/meeting-hooks';
import { removeMeetingLabel } from '../../../../graphql/meetings';
import { isMeetingOwner } from '../../../../helpers/meetings';
import { kTranscripts } from '../../../../helpers/routes';
import { useNavigate } from 'react-router';
import { removeLabelFromMeeting } from '../../../../redux/modules/global';
import { trackWebEvent } from '../../../../helpers/analytics';

export const MeetingHeaderLabels: React.FC = () => {
  const meeting = useMeeting();
  const userId = useSelector(selectUid);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOwner = userId && isMeetingOwner(userId, meeting);
  const team = useSelector(selectTeam);
  const areLabelsEditable =
    isOwner && meeting && meeting.id !== 'tutorial' && !meeting.isPreview;

  const onRemoveLabel = async (label: LabelConfig) => {
    if (areLabelsEditable) {
      dispatch(
        removeLabelFromMeeting({ meetingId: meeting.id, labelId: label.id })
      );
      await removeMeetingLabel(meeting.id, label.id);
      trackWebEvent('Transcript Label Removed', { team_id: team?.id });
    }
  };

  if (!meeting) return null;

  return (
    <div className="mt-2 flex w-full flex-row flex-wrap items-center justify-start gap-2">
      {meeting.labels.map((label) => (
        <Label
          {...label}
          key={label.id}
          onClick={() => {
            if (areLabelsEditable) {
              navigate({
                pathname: kTranscripts,
                search: `l=${label.name}`,
              });
            }
          }}
          onDelete={!label.isAuto ? () => onRemoveLabel(label) : undefined}
        />
      ))}

      {areLabelsEditable && (
        <AddLabelButton
          meetingId={meeting.id}
          meetingLabels={meeting.labels}
          small={false}
        />
      )}
    </div>
  );
};
