import React from 'react';
import { RawBlock } from '@tactiq/model';
import { FuseResultMatch } from 'fuse.js';
import { cx } from '../../../helpers/utils';
import { Tooltip } from '../../../components/Tooltip';

interface Props {
  messageId?: string;
  text: string;
  refIndex?: number;
  matchKey: string | string[];
  matches?: readonly FuseResultMatch[];
  currentMatches?: readonly FuseResultMatch[];
  type?: RawBlock['type'];
  borderBottomColor?: string;
  tooltip?: string;
}

export const Highlight: React.FC<Props> = (props) => {
  const {
    messageId,
    text,
    refIndex,
    matchKey,
    matches,
    currentMatches,
    borderBottomColor,
    tooltip,
  } = props;
  const keys = typeof matchKey === 'string' ? [matchKey] : matchKey;
  const match = matches?.find(
    (m) => m.key && keys.includes(m.key) && refIndex === m.refIndex
  );
  const isSelected = Boolean(
    currentMatches &&
      match &&
      currentMatches?.find((currentMatch) => currentMatch.refIndex === refIndex)
  );
  const additionalProps = {
    ...(messageId ? { 'data-tactiq-message-id': messageId } : {}),
  };

  if (match) {
    const parts = [];
    let previousEnd = 0;

    for (let i = 0; i < match.indices.length; i++) {
      const index = match.indices[i];

      const apperitif = text.substring(previousEnd, index[0]);
      const meat = text.substring(index[0], index[1] + 1);
      previousEnd = index[1] + 1;

      apperitif && parts.push({ key: `a-${i}`, text: apperitif });
      parts.push({ key: `h-${i}`, text: meat, highlight: true });
    }

    if (previousEnd < text.length) {
      parts.push({
        key: `a-${match.indices.length}`,
        text: text.substring(previousEnd),
      });
    }

    return (
      <span>
        {parts.map((p) =>
          p.highlight ? (
            <Tooltip key={p.key} title={tooltip ?? ''}>
              <span
                className={cx(
                  isSelected ? 'bg-orange-600' : `bg-[#FFC107]`,
                  borderBottomColor ? `border-b-[${borderBottomColor}]` : ''
                )}
                {...additionalProps}
              >
                {p.text}
              </span>
            </Tooltip>
          ) : (
            <span key={p.key} {...additionalProps}>
              {p.text}
            </span>
          )
        )}
      </span>
    );
  } else {
    return <span {...additionalProps}>{text}</span>;
  }
};
