import * as routes from '../helpers/routes';
import React, { PropsWithChildren, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { consumeTeamInvitationLink } from '../helpers/api';
import { useAsyncEffect } from 'use-async-effect';
import LoadingComponent from '../app/Landing/Loading/LoadingComponent';
import { selectUid } from '../redux/selectors';

const RequireLoggedOut: React.FC<PropsWithChildren> = (props) => {
  const userId = useSelector(selectUid);
  const startedUp = useSelector((state: RootState) => state.global.startedUp);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useAsyncEffect(async () => {
    const hasTeamInvitationLink =
      window.location.hash.match(/\?teamLink=(.*)$/);

    if (startedUp) {
      if (hasTeamInvitationLink && userId) {
        const teamInvitationLink = hasTeamInvitationLink[1];

        await consumeTeamInvitationLink(teamInvitationLink);
      }

      setIsLoading(false);
    }
  }, [startedUp, userId]);

  if (!startedUp || isLoading) {
    return <LoadingComponent />;
  }

  if (userId) {
    let redirectTo = routes.kTranscripts;
    if (location.search) {
      const params = new URLSearchParams(location.search.substr(1));
      redirectTo = params.get('redirect') || routes.kBilling;
    }
    return <Navigate to={redirectTo} />;
  }

  return props.children as unknown as JSX.Element;
};

export default RequireLoggedOut;
