import React from 'react';

export const DescriptionList: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="mt-6 border-t border-gray-100">
      <dl className="divide-y divide-gray-100">{children}</dl>
    </div>
  );
};
