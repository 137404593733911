import { sendMessage } from './extension';
import {
  ExtensionTranscript,
  ExtensionTranscriptSummary,
  incidentApr21SanitizeText,
  RawTranscript,
  TactiqMessageType,
} from '@tactiq/model';

export async function getRawTranscriptFromExtension(
  meetingId: string
): Promise<RawTranscript | null> {
  const result = await sendMessage<{
    success: boolean;
    transcript?: ExtensionTranscript;
  }>({
    type: TactiqMessageType.getTranscript,
    meetingId,
  });

  if (!result) return null;

  const { transcript } = result;

  if (!transcript?.transcript?.blocks?.length) return null;

  return {
    ...transcript.transcript,
    blocks: transcript.transcript.blocks.map((block) => ({
      ...block,
      transcript: incidentApr21SanitizeText(block.transcript),
    })),
  };
}

export async function getTranscriptSummariesFromExtension(): Promise<
  ExtensionTranscriptSummary[]
> {
  const result = await sendMessage<{
    success: boolean;
    transcripts?: ExtensionTranscriptSummary[];
  }>({
    type: TactiqMessageType.getTranscriptSummaries,
  });

  if (!result || !result.success || !result.transcripts) return [];

  return result.transcripts;
}

export function getScreenshotsFromTranscript(
  transcript: RawTranscript
): string[] {
  return transcript.blocks
    .filter(
      (b) =>
        b.type === 'screenshot' &&
        !b.transcript.startsWith('https://drive.google.com')
    )
    .map((b) => b.timestamp.toString());
}
