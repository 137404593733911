import { ExternalLink, Grip } from 'lucide-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { trackWebEvent } from '../../../../helpers/analytics';
import { ShareCardWrapper } from './ShareCardWrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Button } from '../../../../components/buttons';

export const GoogleWorkspaceAddOnCard: React.FC = () => {
  const intl = useIntl();

  const hasGoogleWorkspaceAddon = useSelector(
    (state: RootState) => state.user?.hasGoogleWorkspaceAddon
  );
  if (hasGoogleWorkspaceAddon) return null;

  return (
    <ShareCardWrapper
      imageComponent={
        <Grip strokeWidth={2} size={16} className="text-slate-600" />
      }
      title={intl.formatMessage({
        defaultMessage: 'Install Google Workspace Addon',
        id: 'mFMgI/',
      })}
      actions={
        <Button
          size="tiny"
          variant="icon"
          href="https://workspace.google.com/u/0/marketplace/app/tactiqio/399035273123"
          target="_blank"
          onClick={() =>
            trackWebEvent(
              'Clicked Link - Install Google Workspace Addon from the meeting'
            )
          }
        >
          <ExternalLink strokeWidth={2} size={18} className="text-slate-600" />
        </Button>
      }
    />
  );
};
