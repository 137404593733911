import React, { useContext } from 'react';
import { useFullMeeting } from '../../../../common/meeting-hooks';
import { SelectionObserverContext } from '../../selection-observer';
import { CommentList } from './List';
import { CommentEditor } from './editor';

export const BlockCommentList: React.FC<{
  groupId: string;
  isCommenting: boolean;
}> = ({ groupId, isCommenting }) => {
  const meeting = useFullMeeting();
  const { onCommentBlock } = useContext(SelectionObserverContext);
  const comments = [...(meeting?.comments ?? [])].filter(
    (c) => c.relatedBlockId === groupId
  );
  comments.sort((a, b) => a.createdAt - b.createdAt);

  return (
    <CommentList
      comments={comments}
      isCommenting={isCommenting}
      onReply={(commentId: string) => {
        onCommentBlock({ groupId, commentId });
      }}
    >
      {isCommenting && <CommentEditor />}
    </CommentList>
  );
};
