import React, { useMemo } from 'react';
import { BasicMeeting } from '../../../../models/meeting';
import { useMeeting } from '../../common/meeting-hooks';
import RelatedMeetingsImpl from './RelatedMeetings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

export const useRelatedMeetings = (meetingId: string): BasicMeeting[] => {
  const meeting = useMeeting(meetingId);
  const meetings = Object.values(
    useSelector((state: RootState) => state.global.meetings.my)
  );

  return useMemo(() => {
    if (!meeting) {
      return [];
    }

    return meetings.filter((m) => {
      return (
        m.id !== meetingId &&
        m.pathnameHash &&
        m.pathnameHash === meeting.pathnameHash
      );
    });
  }, [meetingId, meetings, meeting]);
};

const RelatedMeetings: React.FC<{ meetingId: string }> = ({ meetingId }) => {
  const relatedMeetings = useRelatedMeetings(meetingId);

  return <RelatedMeetingsImpl relatedMeetings={relatedMeetings} />;
};

export default RelatedMeetings;
