import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Node,
  Edge,
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
  applyEdgeChanges,
  applyNodeChanges,
  OnNodesChange,
  OnEdgesChange,
  getOutgoers,
  getConnectedEdges,
  reconnectEdge,
  OnNodesDelete,
  addEdge,
  MarkerType,
  OnReconnect,
  IsValidConnection,
  OnConnect,
} from '@xyflow/react';
// The docs say that you need this for functionality - i'm not sure why though
import '@xyflow/react/dist/style.css';

import { nanoid } from 'nanoid';
import { nodeTypes } from './nodes';
import { WorkflowExecution } from '../../graphql/operations';
import { ConfirmActionDialog } from '../../components/modals';
import { FormattedMessage } from 'react-intl';
import { useBlocker } from 'react-router';
import {
  trackConnectWorkflowEdge,
  trackDeleteWorkflowEdge,
  trackDeleteWorkflowNode,
  viewWorkflowEditPage,
} from '../../helpers/analytics';
import { NodeValidator } from '@tactiq/model';

type Props = {
  workflowId: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  nodeDragThreshold: number;
  nodeCounter: number;
  recentExecution?: WorkflowExecution;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  readonly?: boolean;
};

const GRID_RESOLUTION = 8;

export default function WorkflowEditor(props: Props): ReactElement {
  const { nodes, edges, setNodes, setEdges, workflowId } = props;
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    viewWorkflowEditPage({ workflowId });
  }, []);

  const onNodesChange: OnNodesChange<Node> = useCallback(
    (changes) => {
      for (const change of changes) {
        // Snap dimension change to grid.
        if (
          change.type === 'dimensions' &&
          change.resizing &&
          change.dimensions
        ) {
          change.dimensions.width =
            Math.ceil(change.dimensions.width / GRID_RESOLUTION) *
            GRID_RESOLUTION;
          change.dimensions.height =
            Math.ceil(change.dimensions.height / GRID_RESOLUTION) *
            GRID_RESOLUTION;
        }
      }
      return setNodes(
        (nds) =>
          applyNodeChanges(changes, nds).map(NodeValidator.validate) as Node[]
      );
    },
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange<Edge> = useCallback(
    (changes) => setEdges((eds: Edge[]) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect: OnConnect = useCallback(
    (params) => {
      trackConnectWorkflowEdge({ workflowId });
      return setEdges((eds) => addEdge(params, eds));
    },
    [setEdges, workflowId]
  );

  const onReconnect: OnReconnect = useCallback(
    (oldEdge, newConnection) =>
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els)),
    [setEdges]
  );

  const isValidConnection: IsValidConnection = useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target?.id === connection.source) return false;
      return Boolean(target && !hasCycle(target));
    },
    [nodes, edges]
  );

  const onNodesDelete: OnNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          trackDeleteWorkflowNode({ workflowId, type: node.type });
          const connectedEdges = getConnectedEdges([node], edges);
          const remainingEdges = acc.filter((e) => !connectedEdges.includes(e));
          const incomingEdge = connectedEdges.find((e) => e.target === node.id);
          const outgoingEdge = connectedEdges.find((e) => e.source === node.id);

          // tip of the branch node is deleted,
          // no need to reconnect anything
          if (!outgoingEdge) {
            return remainingEdges;
          }

          // this should not be possible. We only allow start node not to have incoming edge
          // and start node if filtered in onBeforeDelete
          if (!incomingEdge) {
            return remainingEdges;
          }

          const createdEdge = {
            id: nanoid(),
            type: 'smoothstep',
            source: incomingEdge.source,
            target: outgoingEdge.target,
            label: incomingEdge.label,
            sourceHandle: incomingEdge.sourceHandle,
            markerEnd: incomingEdge.markerEnd,
          };

          return [...remainingEdges, createdEdge];
        }, edges)
      );
    },
    [edges, setEdges, workflowId]
  );

  const nodesWithExecutionData = props.recentExecution
    ? nodes.map((node) => {
        const recentExecutionData = props.recentExecution?.nodeData.find(
          (nd) => nd.id === node.id
        );

        return {
          ...node,
          data: {
            ...node.data,
            recentExecutionData,
          },
        };
      })
    : nodes;

  return (
    <div className="relative h-full w-full flex-grow bg-slate-25">
      <UnsavedChangeDetector
        initialised={initialised}
        nodes={nodes}
        edges={edges}
        hasUnsavedChanges={props.hasUnsavedChanges}
        setHasUnsavedChanges={props.setHasUnsavedChanges}
      />
      <ReactFlow
        className="border-0"
        defaultEdgeOptions={{ type: 'smoothstep' }}
        nodes={nodesWithExecutionData}
        edges={edges.map((e) => ({
          ...e,
          style: { strokeWidth: 2 },
          markerEnd: { type: MarkerType.ArrowClosed },
        }))}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onReconnect={onReconnect}
        isValidConnection={isValidConnection}
        onConnect={onConnect}
        nodeOrigin={[0.5, 0]}
        maxZoom={1}
        zoomOnDoubleClick={false}
        fitView
        snapToGrid
        snapGrid={[GRID_RESOLUTION, GRID_RESOLUTION]}
        onInit={() => setInitialised(true)}
        onBeforeDelete={async (data) => {
          if (data.nodes.length) {
            return data.nodes.some((n) => {
              if (n.type === 'StartNode') return false;
              return true;
            });
          }
          return true;
        }}
        onEdgesDelete={() => trackDeleteWorkflowEdge({ workflowId })}
        onNodesDelete={onNodesDelete}
        nodeTypes={nodeTypes}
        proOptions={{ hideAttribution: true }}
      >
        <Controls />
        <Background color="#F1F4F8" variant={BackgroundVariant.Lines} />
      </ReactFlow>
    </div>
  );
}

function UnsavedChangeDetector({
  nodes,
  edges,
  initialised,
  hasUnsavedChanges,
  setHasUnsavedChanges,
}: {
  nodes: Node[];
  edges: Edge[];
  initialised: boolean;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
}): ReactElement | null {
  const blocker = useBlocker(hasUnsavedChanges);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!initialised) {
      return;
    }
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    setHasUnsavedChanges(true);
  }, [nodes, edges, initialised, setHasUnsavedChanges]);

  useEffect(() => {
    const beforeunload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [hasUnsavedChanges]);

  return blocker.state === 'blocked' ? (
    <ConfirmActionDialog
      open
      title={<FormattedMessage defaultMessage="Unsaved changes" id="j7WSfa" />}
      text={
        <FormattedMessage
          defaultMessage="Are you sure you want to navigate away from this page? Any unsaved changes will be lost."
          id="SfL4cN"
        />
      }
      yes={<FormattedMessage defaultMessage="Discard" id="nmpevl" />}
      yesProps={{ color: 'error' }}
      noProps={{ color: 'info' }}
      onYes={() => blocker.proceed()}
      onNo={() => blocker.reset()}
    />
  ) : null;
}

export function WorkflowPreview(props: {
  nodes: Node[];
  edges: Edge[];
}): ReactElement {
  const { nodes, edges } = props;
  return (
    <div className="relative h-full w-full flex-grow bg-slate-25">
      <ReactFlow
        className="border-0"
        nodes={nodes}
        edges={edges.map((e) => ({
          ...e,
          style: { strokeWidth: 2 },
          markerEnd: { type: MarkerType.ArrowClosed },
        }))}
        nodeOrigin={[0.5, 0]}
        maxZoom={1}
        draggable={false}
        edgesFocusable={false}
        edgesReconnectable={false}
        elementsSelectable={false}
        nodesConnectable={false}
        nodesDraggable={false}
        nodesFocusable={false}
        zoomOnDoubleClick={false}
        fitView
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
      >
        <Controls />
        <Background color="#F1F4F8" variant={BackgroundVariant.Lines} />
      </ReactFlow>
    </div>
  );
}
