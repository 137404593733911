import { useMutation, useQuery } from '@apollo/client';
import { MutationResult, QueryResult } from './helpers';
import {
  UpdateMeetingParticipantNamesDocument,
  GetMeetingDocument,
} from '../graphql/operations';

/** Updates participant names on a meeting */
export function useUpdateMeetingParticipantNames(): MutationResult<
  typeof UpdateMeetingParticipantNamesDocument
> {
  const [fn, { data, loading, error }] = useMutation(
    UpdateMeetingParticipantNamesDocument
  );

  return {
    request: (variables) => fn({ variables: { input: variables.input } }),
    data,
    error,
    loading,
  };
}

/**
 * Fetch a single meeting
 */
export function useMeeting(options: {
  id: string | undefined;
  refetch?: boolean;
}): QueryResult<typeof GetMeetingDocument> {
  const { data, error, loading } = useQuery(GetMeetingDocument, {
    skip: !options.id,
    variables: { meetingId: options.id ?? '' },
    fetchPolicy: options.refetch ? 'cache-and-network' : 'cache-first',
  });

  return { data, error, loading };
}
