import { useMutation } from '@apollo/client';
import { TactiqMessageType } from '@tactiq/model';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../components/buttons';
import { UpdateUserSettingDocument } from '../../graphql/operations';
import { getArePermissionsGranted } from '../../graphql/user';
import { trackWebEvent } from '../../helpers/analytics';
import { sendMessage } from '../../helpers/extension';
import { gotArePermissionsGranted } from '../../redux/modules/global';

export const ButtonRequestAllPermissions: React.FC<{
  event: string;
  label: React.ReactNode;
  startIcon?: React.ReactNode;
}> = ({ event, label, startIcon }) => {
  const dispatch = useDispatch();

  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  return (
    <Button
      color="warning"
      startIcon={startIcon}
      size="small"
      loading={updateUserSettingMutation.loading}
      onClick={async () => {
        trackWebEvent(`Clicked Link - ${event}`);

        const result = await sendMessage<{ granted: boolean }>({
          type: TactiqMessageType.openOptionsPage,
          path: '/Permissions?return=/setup',
        });

        if (result?.granted) {
          await updateUserSetting({
            variables: {
              input: { zoomwcredirect: true },
            },
          });
        }

        const granted = await getArePermissionsGranted(dispatch);
        dispatch(gotArePermissionsGranted(granted));
      }}
    >
      {label}
    </Button>
  );
};
