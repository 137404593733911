import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IntegrationImplementation } from '../../../../../models/integration';
import { Dispatch } from 'redux';
import { Alert } from '../../../../../components/Alert';
import { Link } from '../../../../../components/Link';

interface Props {
  integration: IntegrationImplementation;
  dispatch: Dispatch;
}

export const ConnectionDialog: React.FC<Props> = (props) => {
  const { integration } = props;

  return (
    <div className="flex flex-col items-center justify-center p-12">
      <div className="h-24 w-24">
        <img src={integration.icon} alt="Integration logo" />
      </div>
      <p className="mt-6 text-2xl">{integration.title}</p>
      {integration.helpUrl ? (
        <div className="mt-6">
          <Alert
            severity="info"
            description={
              <FormattedMessage
                defaultMessage="See <link>how it works</link>"
                id="OaiqDQ"
                description="Connection dialog help link text."
                values={{
                  link: (chunks) => (
                    <Link to={integration.helpUrl ?? ''} target="_blank">
                      {chunks}
                    </Link>
                  ),
                }}
              />
            }
          />
        </div>
      ) : null}
      <div className="mt-6">
        {integration.connector ? <integration.connector /> : null}
      </div>
    </div>
  );
};
