import { MeetingShareData } from '@tactiq/model';
import { fetchApiV2 } from '../helpers';

export async function saveMeetingShare(
  meetingId: string,
  data: MeetingShareData
): Promise<{ link: string }> {
  return await fetchApiV2(`/a/meeting/${meetingId}/share`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}
