import React, { useEffect, useState } from 'react';
import { createPopup, Popup } from '@typeform/embed';
// This is not being added into the page when it is built to environment or production
// import '@typeform/embed/build/css/popup.css';
// This is not being added into the page when it is built to environment or production
import { trackWebEvent } from '../../../helpers/analytics';

export interface RateModalProps {
  open: boolean;
  uid: string;
  onClose: () => void;
  onSubmit: () => void;
}

/**
 * Reason for signup Modal
 * @param {unknown} param0 params
 * @param {boolean} param0.open open
 * @param {string} param0.uid uid
 * @param {() => void} param0.onClose onClose
 * @param {() => void} param0.onSubmit onSubmit
 * @returns {React.FC} component
 */
export const SignupReasonModal: React.FC<RateModalProps> = ({
  open,
  uid,
  onClose,
  onSubmit,
}) => {
  const [popup, setPopup] = useState<Popup | null>(null);

  useEffect(() => {
    setPopup(
      createPopup('XUyKuulS', {
        container: document.body,
        hidden: {
          uid,
        },
        onClose,
        onSubmit,
      })
    );

    return () => {
      if (popup) {
        trackWebEvent('Sign up reason modal closed', {
          user: uid,
        });
        popup.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, uid]);

  useEffect(() => {
    if (popup && open) {
      trackWebEvent('Viewed Sign up reason modal', { user: uid });
      popup.open();
    }
  }, [popup, open, uid]);

  return null;
};
