import React, { ReactElement } from 'react';
import { cx } from '../../helpers/utils';

/**
 * Pulsing loading elements
 */
export function Skeleton(props: {
  count?: number;
  className?: string;
}): ReactElement {
  return (
    <>
      {Array.from({ length: props.count ?? 1 }).map((_, index) => (
        <div
          key={index}
          className={cx(
            props.className,
            'flex rounded bg-slate-200 motion-safe:animate-pulse'
          )}
        />
      ))}
    </>
  );
}
