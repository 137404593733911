import { BlockGroup, RawTranscript, TranscriptNotes } from '@tactiq/model';
import { MeetingShare, UserIntegrationConnection } from '../graphql/operations';
import { FullMeeting, TranscriptBlock } from './meeting';

export interface ShareOptions {
  meetingId: string;
  title: string;
  rawTranscript: RawTranscript;
  highlights: BlockGroup<TranscriptBlock>[];
  blocks: TranscriptBlock[];
  notes?: TranscriptNotes;
  footerLink: string;
  footerLinkText: string;
  isSharingDetails: boolean;
  isSharingHighlights: boolean;
  isSharingNotes: boolean;
  isSharingTranscript: boolean;
  meetingDetails: Record<string, any>;
}

export type IntegrationShare = (
  connection: UserIntegrationConnection,
  options: ShareOptions & Record<string, any>,
  keepOpen?: boolean
) => Promise<{
  link: string;
  saveRawTranscript?: boolean;
  externalId?: string;
  keepOpen?: boolean;
}>;

export enum IntegrationCategory {
  Meetings = 'Meetings',
  Automation = 'Automation',
  Storage = 'Storage',
  CRM = 'CRM',
  Other = 'Other',
}
export interface IntegrationImplementation {
  id: string;
  title: string;
  icon: any;
  description?: React.FC;
  waitlist?: boolean;
  connector?: React.FC;
  viewer?: any;
  actions?: any[];
  shareDescription?: React.FC;
  share?: IntegrationShare;
  helpUrl?: string;
  order?: number;
  allowMultipleConnections?: boolean;
  showReconnectButton?: boolean;
  ShareDialog?: React.ComponentType<ShareDialogProps>;
  getSharingDestinationTitle?: (options: any) => string;
  isConnected?: (options: {
    hasGoogleCalendar?: boolean;
    areAllPermissionsGranted?: boolean;
    userConnections?: UserIntegrationConnection[];
  }) => boolean;
  disableShareDialogBackground?: boolean;
  unshare?: (meetingId: string, share: MeetingShare) => any;
  category?: IntegrationCategory;
  hidden?: boolean;
  connectionFilter?: (connection: UserIntegrationConnection) => boolean;
}

export interface RecentDestination {
  sharedAt: any;
  connection: UserIntegrationConnection;
  integration: IntegrationImplementation;
  options: any;
}

export type ShareDialogProps = {
  meeting: FullMeeting;
  connection: UserIntegrationConnection;
  share: (
    integration: IntegrationImplementation,
    connection: UserIntegrationConnection,
    options: any,
    keepOpen?: boolean
  ) => Promise<void>;
} & Record<string, any>;
