import { CreditCard } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/buttons';
import { createCheckoutSession_Personal_SwitchToPayByCard } from '../../graphql/billing';
import { trackWebEvent } from '../../helpers/analytics';
import { ModalDialog } from '../../components/modals';

export const SwitchToCardBillingDialog: React.FC<{
  open: boolean;
  message?: React.ReactNode;
  onClose: () => void;
}> = ({ open, message = null, onClose }) => {
  const [isLoading, setLoading] = useState(false);

  const onConfirm = async () => {
    trackWebEvent('SwitchToCardBillingDialog - confirmed');
    setLoading(true);
    try {
      window.location.href =
        await createCheckoutSession_Personal_SwitchToPayByCard({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    open && trackWebEvent('SwitchToCardBillingDialog - viewed');
  }, [open]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      icon={
        <CreditCard className="h-6 w-6 text-green-600" aria-hidden="true" />
      }
      color="success"
      title={
        <FormattedMessage
          defaultMessage="Switch from PayPal to Pay by Card?"
          id="y5iZk5"
        />
      }
      text={
        <FormattedMessage
          defaultMessage="For your initial payment, you will pay pro-rated difference according to your current subscription."
          id="h7wx7s"
        />
      }
      actions={
        <>
          <Button loading={isLoading} color="success" onClick={onConfirm}>
            <FormattedMessage defaultMessage="Continue" id="acrOoz" />
          </Button>
          <Button variant="outlined" onClick={onClose}>
            <FormattedMessage defaultMessage="Stay on PayPal" id="wNa5ZV" />
          </Button>
        </>
      }
    >
      {message}
    </ModalDialog>
  );
};
