import { useMutation } from '@apollo/client';
import { Locale } from '@tactiq/i18n';
import { differenceInDays } from 'date-fns';
import { X } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Banner } from '../../components/Banner';
import { Tooltip } from '../../components/Tooltip';
import { Button } from '../../components/buttons';
import { UpdateUserSettingDocument } from '../../graphql/operations';
import {
  trackDismissLanguageBanner,
  trackReshowLanguageBanner,
  trackSupportTranslationLanguageBanner,
  trackUpdatePreferredLanguage,
} from '../../helpers/analytics';
import { RootState } from '../../redux/store';

export const LanguageSupportBanner: React.FC = () => {
  const lang = document.documentElement.lang;
  const uid = useSelector((state: RootState) => state.user.id);
  const preferredLang = useSelector(
    (state: RootState) => state.user.settings?.lang
  );
  const previouslyDismissedDate = localStorage.getItem(
    'dismissedLanguageBannerAt'
  );
  const shouldShowBanner = !previouslyDismissedDate
    ? true
    : differenceInDays(new Date(), previouslyDismissedDate) >= 30;
  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  if (
    previouslyDismissedDate &&
    differenceInDays(new Date(), previouslyDismissedDate) >= 30
  ) {
    trackReshowLanguageBanner(uid);
  }

  let language = null;
  let supportedUILanguage = null;
  switch (lang) {
    case Locale.es:
      language = Locale.es;
      supportedUILanguage = 'Spanish';
      break;
    case Locale.pt:
      language = Locale.pt;
      supportedUILanguage = 'Portuguese';
      break;
    case Locale.fr:
      language = Locale.fr;
      supportedUILanguage = 'French';
      break;
    case Locale.de:
      language = Locale.de;
      supportedUILanguage = 'German';
      break;
    case Locale.it:
      language = Locale.it;
      supportedUILanguage = 'Italian';
      break;
    case Locale.ja:
      language = Locale.ja;
      supportedUILanguage = 'Japanese';
      break;
  }

  React.useEffect(() => {
    if (language !== null) {
      trackSupportTranslationLanguageBanner(language);
    }
  }, [language]);

  // Users UI is not in English
  if (preferredLang !== Locale.en || !shouldShowBanner) {
    return null;
  }

  async function handleDismiss() {
    // Dismiss the banner and save time of it being dismissed
    const dismissedAt = Date.now();
    trackDismissLanguageBanner(uid);
    localStorage.setItem('dismissedLanguageBannerAt', dismissedAt.toString());
  }

  // The language being translated to is not one we support in the UI
  if (!(lang in Locale) || language === null) {
    return null;
  }

  // if the userLang is en but the lang is not en AND a lang that matches our supported languages, show the banner
  return (
    <Banner>
      <div className="flex-rows flex w-full flex-grow items-center justify-center gap-x-3">
        <div className="flex flex-col items-start gap-4 md:flex-row md:items-center">
          <div className="text-sm font-medium text-slate-600">
            <FormattedMessage
              defaultMessage="Did you know we support {language}?"
              id="WA42Ap"
              values={{
                language: supportedUILanguage,
              }}
            />
          </div>
          <Button
            size="small"
            loading={updateUserSettingMutation.loading}
            onClick={async () => {
              trackUpdatePreferredLanguage(language, 'languageBanner');
              await updateUserSetting({
                variables: {
                  input: { lang: language },
                },
              });
              localStorage.removeItem('dismissedLanguageBannerAt');
            }}
          >
            <FormattedMessage
              defaultMessage="Update preferred language"
              id="Xaa6O9"
            />
          </Button>
        </div>
      </div>

      <Tooltip title="Dismiss" arrow>
        <Button variant="icon" size="small" onClick={handleDismiss}>
          <X className="size-5" />
        </Button>
      </Tooltip>
    </Banner>
  );
};
