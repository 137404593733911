import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/buttons';
import {
  PaypalPaidPlanFieldsFragment,
  StripePaidPlanFieldsFragment,
  TeamPaidPlanFieldsFragment,
  TeamPlan,
} from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { TextInput } from '../../../components/TextInput';
import ReasonSvg from '../../../img/Feeling-Lonely-1.svg';
import CouponSvg from '../../../img/Being-Happy-2.svg';
import { ModalDialog } from '../../../components/modals';

import { Radio, RadioGroup } from '../../../components/Radio';

const ActionButtonWrapperClasses =
  'flex flex-col-reverse items-center justify-between gap-2 md:gap-3 md:flex-row md:items-start';

export interface CancellationDialogProps {
  open: boolean;
  plan:
    | PaypalPaidPlanFieldsFragment
    | StripePaidPlanFieldsFragment
    | TeamPaidPlanFieldsFragment
    | TeamPlan;
  isLoading: boolean;
  isDiscountApplied: boolean;
  isAnnual: boolean;
  isTeam: boolean;
  onClose: () => void;
  onCancel: (reason: string, reasonText: string) => void;
  onApplyCoupon: (reason: string, reasonText: string) => void;
}

enum CancellationStage {
  REASON = 'REASON',
  COUPON = 'COUPON',
}

const CancellationCouponComponent: React.FC<{
  isTeam: boolean;
  isDiscountApplied: boolean;
  isAnnual: boolean;
  isLoading: boolean;
  onApplyCoupon: () => void;
  onCancelClick: () => void;
}> = ({
  isTeam,
  isDiscountApplied,
  isAnnual,
  isLoading,
  onApplyCoupon,
  onCancelClick,
}) => {
  return (
    <div className="flex flex-col items-center gap-3 p-6 text-center md:gap-6 md:p-8">
      <div>
        <p className="text-2xl text-indigo-950">
          <FormattedMessage defaultMessage="Before you cancel..." id="B4/Bey" />
        </p>
        {!isDiscountApplied && (
          <p className="">
            <FormattedMessage
              defaultMessage="How about {percent} for {period}"
              values={{
                percent: (
                  <b>
                    <FormattedMessage defaultMessage="50% off" id="7pMUZ3" />
                  </b>
                ),
                period: (
                  <b>
                    <FormattedMessage defaultMessage="3 months!" id="6X777+" />
                  </b>
                ),
              }}
              id="wZEyCC"
            />
          </p>
        )}
      </div>
      <img
        className="h-32 w-32 md:h-48 md:w-48"
        src={CouponSvg}
        alt="Happy person jumping"
      />
      <div className="text-sm">
        <p>
          {isTeam ? (
            <FormattedMessage
              defaultMessage="By canceling, you and all team members will lose access to unlimited transcripts and many other Tactiq Pro features."
              id="U4O77q"
            />
          ) : (
            <FormattedMessage
              defaultMessage="By canceling, you will lose access to unlimited transcriptions and many other Pro features."
              id="xiQw0o"
            />
          )}
        </p>
        {!isDiscountApplied && (
          <p className="mt-4">
            <FormattedMessage
              defaultMessage="You can continue using Tactiq and receive a {discount} discount for the next {term}."
              id="GL7XiV"
              values={{
                discount: <b>50%</b>,
                term: isAnnual ? (
                  <b>
                    <FormattedMessage defaultMessage="year" id="d57f4E" />
                  </b>
                ) : (
                  <b>
                    <FormattedMessage defaultMessage="3 months" id="D0xe/T" />
                  </b>
                ),
              }}
            />
          </p>
        )}
      </div>
      <div className={ActionButtonWrapperClasses}>
        <Button
          variant="outlined"
          size="large"
          loading={isLoading}
          onClick={onCancelClick}
        >
          <FormattedMessage defaultMessage="Cancel now" id="AmzBg2" />
        </Button>
        {!isDiscountApplied && (
          <Button
            color="primary"
            size="large"
            loading={isLoading}
            onClick={onApplyCoupon}
          >
            <FormattedMessage defaultMessage="Get the offer!" id="S9AldZ" />
          </Button>
        )}
      </div>
    </div>
  );
};

const CancelationReasonComponent: React.FC<{
  reason: string | null;
  setReason: (value: string) => void;
  reasonText: string;
  setReasonText: (reasonText: string) => void;
}> = ({ reason, setReason, reasonText, setReasonText }) => {
  const intl = useIntl();

  const reasons: { id: string; label: string }[] = [
    {
      id: 'missing-features',
      label: intl.formatMessage({
        defaultMessage: 'Missing features I need',
        id: '2z/4I/',
      }),
    },
    {
      id: 'switched-service',
      label: intl.formatMessage({
        defaultMessage: 'Switched to another product',
        id: 'x2Ja2R',
      }),
    },
    {
      id: 'too-expensive',
      label: intl.formatMessage({
        defaultMessage: 'Too expensive',
        id: 'JHv61X',
      }),
    },
    {
      id: 'technical-issues',
      label: intl.formatMessage({
        defaultMessage: 'Technical issues',
        id: 'nFTv9Y',
      }),
    },
    {
      id: 'unused',
      label: intl.formatMessage({
        defaultMessage: 'Have not used the service enough',
        id: 'SAcUHD',
      }),
    },
    {
      id: 'other',
      label: intl.formatMessage({
        defaultMessage: 'Other',
        id: '/VnDMl',
      }),
    },
  ];

  return (
    <div className="flex flex-col gap-3 text-left md:gap-6">
      <div className="flex flex-col gap-1">
        <img
          className="h-24 w-24 md:h-48 md:w-48"
          src={ReasonSvg}
          alt="Sad person on a swing"
        />
        <p className="text-xl font-semibold text-slate-900 md:text-2xl">
          <FormattedMessage
            defaultMessage="We're sorry to see you go..."
            id="NLVxgm"
          />
        </p>

        <p className="text-xs text-slate-700 md:text-sm">
          <FormattedMessage
            defaultMessage="Before you cancel, please let us know the reason you are leaving. Every bit of feedback helps us improve!"
            id="nZRBvS"
          />
        </p>
      </div>
      <div>
        <RadioGroup
          name="cancelation reason"
          value={reason}
          onChange={setReason}
          className="flex flex-col gap-2"
        >
          {reasons.map((r) => (
            <Radio key={r.id} value={r.id} label={r.label} />
          ))}

          <TextInput
            label={
              <p className="mt-2 text-sm text-slate-900 md:mt-4">
                <FormattedMessage defaultMessage="Add a comment" id="vPCMyI" />
              </p>
            }
            type="textarea"
            textAreaProps={{ rows: 1 }}
            required={reason === 'other'}
            value={reasonText}
            onChange={setReasonText}
          />
        </RadioGroup>
      </div>
    </div>
  );
};

/**
 *
 * @param {unknown} param0 params
 * @param {boolean} param0.open open
 * @param {PaypalPaidPlanFieldsFragment | StripePaidPlanFieldsFragment | TeamPaidPlanFieldsFragment | TeamPlan} param0.plan plan
 * @param {boolean} param0.isLoading isLoading
 * @param {boolean} param0.isAnnual isAnnual
 * @param {boolean} param0.isTeam isTeam
 * @param {boolean} param0.isDiscountApplied isDiscountApplied
 * @param {() => void} param0.onCancel onCancel
 * @returns {React.ReactNode} CancelationDialog component
 */
export const CancellationDialog: React.FC<CancellationDialogProps> = ({
  open,
  plan,
  isLoading,
  isAnnual,
  isTeam,
  isDiscountApplied,
  onCancel,
  ...rest
}) => {
  const [reason, setReason] = useState<string | null>(null);
  const [reasonText, setReasonText] = useState('');
  const [stage, setStage] = useState<CancellationStage>(
    CancellationStage.REASON
  );

  const onCancelClick = () => {
    if (!isValid) {
      return;
    }
    if (
      (stage === CancellationStage.COUPON && reason) ||
      plan.__typename === 'PaypalPaidPlan'
    ) {
      trackWebEvent(
        'Cancel subscription dialog - Cancel subscription button - clicked',
        { isTeam }
      );

      onCancel(reason || '', reasonText);
    } else {
      setStage(CancellationStage.COUPON);
    }
  };

  const onApplyCoupon = () => {
    if (!reason) {
      return;
    }

    trackWebEvent(
      'Cancel subscription dialog - Apply coupon button - clicked',
      { isTeam, reason }
    );

    rest.onApplyCoupon(reason, reasonText);
  };

  const onClose = () => {
    trackWebEvent('Cancel subscription dialog - closed', { isTeam });

    rest.onClose();
  };

  const isValid = reason && (reason !== 'other' || reasonText.length);

  useEffect(() => {
    if (open) {
      trackWebEvent('Cancel subscription dialog - viewed', { isTeam });
    }
  }, [isTeam, open]);

  // reset state when re-opened
  useEffect(() => {
    if (open) {
      setReason(null);
      setReasonText('');
      setStage(CancellationStage.REASON);
    }
  }, [open]);

  if (!open) return null;

  let content = null;
  let actions = null;

  if (stage === CancellationStage.REASON) {
    content = (
      <CancelationReasonComponent
        reason={reason}
        setReason={setReason}
        reasonText={reasonText}
        setReasonText={setReasonText}
      />
    );
    actions = (
      <div className={ActionButtonWrapperClasses}>
        <Button variant="outlined" size="large" onClick={onClose}>
          <FormattedMessage defaultMessage="I changed my mind!" id="QHsqpp" />
        </Button>
        <Button color="error" size="large" onClick={onCancelClick}>
          <FormattedMessage
            defaultMessage="Cancel my subscription"
            id="jemxPW"
          />
        </Button>
      </div>
    );
  } else if (stage === CancellationStage.COUPON) {
    content = (
      <CancellationCouponComponent
        isTeam={isTeam}
        isAnnual={isAnnual}
        isDiscountApplied={isDiscountApplied}
        isLoading={isLoading}
        onApplyCoupon={onApplyCoupon}
        onCancelClick={onCancelClick}
      />
    );
    actions = (
      <div className={ActionButtonWrapperClasses}>
        <Button
          variant="outlined"
          size="large"
          loading={isLoading}
          onClick={onCancelClick}
        >
          <FormattedMessage defaultMessage="Cancel now" id="AmzBg2" />
        </Button>
        {!isDiscountApplied && (
          <Button
            color="primary"
            size="large"
            loading={isLoading}
            onClick={onApplyCoupon}
          >
            <FormattedMessage defaultMessage="Get the offer!" id="S9AldZ" />
          </Button>
        )}
      </div>
    );
  }

  return (
    <ModalDialog
      title={
        <FormattedMessage defaultMessage="Cancel your account" id="RxPohU" />
      }
      open={open}
      onClose={onClose}
      text={content}
      actions={actions}
    />
  );
};
