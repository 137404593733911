import { TactiqMessageType, logger } from '@tactiq/model';
import {
  ExtensionIsNotAvailableError,
  sendMessage,
} from '../helpers/extension';
import {
  gotArePermissionsGranted,
  gotLabels,
  updateExtensionAvailability,
} from '../redux/modules/global';
import { AppDispatch } from '../redux/store';
import { ApolloClientFactory } from './client';
import { getLabels } from './labels';
import {
  GetUpcomingInvoiceDetailsDocument,
  UpcomingUserInvoice,
  UserUpdatesDocument,
  UserUpdatesSubscription,
  UserUpdatesFallbackDocument,
  GetDomainUsersCountDocument,
} from './operations';
import { gotDomainUsersCount } from '../redux/modules/user';

export const subscribeToUserUpdates = (
  client: (typeof ApolloClientFactory)['_client'],
  onNext: (user: UserUpdatesSubscription['user']) => void,
  onComplete: () => void,
  onError: (err: Error) => void
): (() => void) => {
  // Load the user via http if the websocket is taking longer than 10 seconds
  const loadUserAnyway = setTimeout(async () => {
    const { data } = await client.query({ query: UserUpdatesFallbackDocument });
    onNext(data.user);
  }, 10_000);

  const observable = client.subscribe({
    query: UserUpdatesDocument,
  });

  const subscription = observable.subscribe({
    next: ({ data, errors }) => {
      // Stop the fallback from happening if we beat it
      if (loadUserAnyway) clearTimeout(loadUserAnyway);

      if (errors?.length) {
        onError(new Error(errors[0].message));
        return;
      }

      if (!data?.user) {
        return;
      }

      onNext(data.user);
    },
    error: onError,
    complete: onComplete,
  });

  return () => subscription.unsubscribe();
};

export const getUpcomingInvoice =
  async (): Promise<UpcomingUserInvoice | null> => {
    const { data, errors } = await (
      await ApolloClientFactory.getClient()
    ).query({
      query: GetUpcomingInvoiceDetailsDocument,
    });

    if (errors?.length) {
      throw new Error(errors[0].message);
    }

    return data.upcomingInvoice ?? null;
  };

export const getEverything = async (
  uid: string,
  dispatch: AppDispatch
): Promise<void> => {
  logger.configure({ parameters: { uid } });

  const [labels, arePermissionsGranted, domainUsersCount] = await Promise.all([
    getLabels(),
    getArePermissionsGranted(dispatch),
    getDomainUsersCount(),
  ]);

  dispatch(gotLabels(labels));
  dispatch(gotArePermissionsGranted(arePermissionsGranted));
  dispatch(gotDomainUsersCount(domainUsersCount));
};

export const getDomainUsersCount = async (): Promise<number> => {
  const { data, errors } = await (
    await ApolloClientFactory.getClient()
  ).query({
    fetchPolicy: 'network-only',
    query: GetDomainUsersCountDocument,
  });

  if (errors) {
    throw new Error(errors[0].message);
  }

  return data.getDomainUsersCount;
};

export async function getArePermissionsGranted(
  dispatch: AppDispatch
): Promise<boolean> {
  try {
    const result = !!(
      await sendMessage<{ enabled: boolean }>(
        {
          type: TactiqMessageType.areAllPermissionsGranted,
        },
        5
      )
    )?.enabled;

    dispatch(updateExtensionAvailability(true));
    return result;
  } catch (e) {
    if (
      (e as ExtensionIsNotAvailableError).isUnavailable ||
      ((e as Error)?.message ?? e)?.indexOf('Could not establish connection.') >
        -1
    ) {
      dispatch(updateExtensionAvailability(false));
    }
  }

  return false;
}
