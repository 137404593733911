import { RawTranscript } from '@tactiq/model';
import React from 'react';
import { useMeeting } from '../../common/meeting-hooks';
import { RecordingCard } from './RecordingCard';
import { ShareCard } from './ShareCard';
import { SharingPermissionsCard } from './SharingPermissionsCard';
import { SaveToTactiqCard } from './SaveToTactiqCard';
import { GoogleWorkspaceAddOnCard } from './GoogleWorkspaceAddOnCard';
import { SharedInSpaceCard } from './SharedInSpaceCard';
import { ScreenshotsCard } from './ScreenshotsCard';
import { Ref as TranscriptRef } from '../transcript/Transcript';

export const TranscriptsAndShares: React.FC<{
  rawTranscript?: RawTranscript;
  transcriptViewer: React.MutableRefObject<TranscriptRef | undefined>;
}> = ({ rawTranscript, transcriptViewer }) => {
  const meeting = useMeeting();

  if (!meeting) {
    return null;
  }

  const sectionShares =
    rawTranscript &&
    meeting.shares?.map((s) => (
      <ShareCard key={s.externalId ?? s.sharedAt} share={s} />
    ));

  return (
    <div className="flex flex-col gap-2">
      <SharingPermissionsCard />

      <SharedInSpaceCard />

      <SaveToTactiqCard />

      {sectionShares}

      <RecordingCard />

      <GoogleWorkspaceAddOnCard />

      <ScreenshotsCard transcriptViewer={transcriptViewer} />
    </div>
  );
};
