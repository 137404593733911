import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/buttons';
import { trackWebEvent } from '../../../../helpers/analytics';
import { selectGoogleMeetingTranscript } from '../../../../helpers/meetings';
import { useMeeting } from '../../common/meeting-hooks';
import TranscriptSection from '../TranscriptSection';

const TranscriptGDoc: React.FC<{ meetingId: string }> = ({ meetingId }) => {
  const meeting = useMeeting(meetingId);
  const transcriptLink = selectGoogleMeetingTranscript(meeting);

  const onLinkClick = useCallback(() => {
    trackWebEvent('Meeting View - Transcript - GDoc - Link clicked', {
      meetingId,
    });
  }, [meetingId]);

  if (!transcriptLink) {
    return null;
  }

  return (
    <TranscriptSection
      header={
        <FormattedMessage
          defaultMessage="Transcript"
          description="Meeting view. Transcript section header"
          id="ZTsNem"
        />
      }
      place="gdoc"
    >
      <div className="flex justify-center">
        <Button
          href={transcriptLink.externalLink}
          target="_blank"
          size="large"
          onClick={onLinkClick}
        >
          <FormattedMessage
            defaultMessage="Open Google Document"
            description="Meeting view. Open Google Document button"
            id="dGJiUd"
          />
        </Button>
      </div>
    </TranscriptSection>
  );
};

export default TranscriptGDoc;
