import React from 'react';
import { ErrorComponent } from '../../../Common/ErrorBoundary';
import { ErrorWidget } from '../../../ErrorPage';
import { Alert } from '../../../../components/Alert';

export const SharingError: ErrorComponent = ({ message }) => {
  return (
    <Alert
      severity="error"
      description={
        <>
          {message}
          <br />
          <ErrorWidget />
        </>
      }
    />
  );
};
