import React from 'react';

export const PageHeader: React.FC<{
  breadcrumbs?: React.ReactNode;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  chips?: React.ReactNode[];
}> = ({ breadcrumbs, title, actions, chips }) => {
  const hasChips = Boolean(chips?.length);
  return (
    <div className="flex flex-col space-y-4">
      {breadcrumbs}
      <div className="pb-4 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-[#052A57] sm:tracking-tight md:truncate md:text-3xl">
            {title}
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 items-center space-x-4 md:ml-4 md:mt-0">
          {actions}
        </div>
      </div>
      {hasChips && (
        <div className="flex flex-col md:flex-row md:flex-wrap md:space-x-6">
          {chips}
        </div>
      )}
    </div>
  );
};
