import { useQuery } from '@apollo/client';
import { ExternalLink, Plus, Settings } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AvatarGroup } from '../../components/Avatar';
import { Chip } from '../../components/Chips';
import { DeadEnd } from '../../components/DeadEnd';
import { Button } from '../../components/buttons';
import {
  ListMeetingsDocument,
  MeetingType,
  Space,
} from '../../graphql/operations';
import { trackWebPage } from '../../helpers/analytics';
import { kTranscripts } from '../../helpers/routes';
import AddToSpaceDeadEnd from '../../img/add-to-space-dead-end.png';
import AstronautSvg from '../../img/astronaut.svg';
import { gotMeetings } from '../../redux/modules/global';
import { selectCanManageSpace, selectUid } from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { AddMembersDialog } from '../Spaces/AddMembersDialog';
import { SpaceSettingsDialog } from '../Spaces/SpaceSettingsDialog';
import { SearchPopup } from './common/SearchBar';
import { TranscriptsList } from './list';
import { useSpace } from '../../services/Space';

const SpacePage: React.FC = () => {
  const { id: spaceId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false);

  const storeMeetings = useSelector(
    (state: RootState) => state.global.meetings.spaces[spaceId ?? '']
  );

  if (!spaceId) {
    // This should only throw if the routes are poorly configured.
    throw new Error('SpacePage was used outside of a route with /:id param');
  }
  const {
    loading: spaceLoading,
    error,
    data: spaceData,
  } = useSpace({ id: spaceId, refetch: true });
  const space = spaceData?.space;

  useEffect(() => {
    if (space?.id && space?.name) {
      trackWebPage('View Space', {
        space_id: space.id,
        space_name: space.name,
      });
    }
  }, [space?.id, space?.name]);

  const {
    previousData,
    data = previousData,
    loading: listLoading,
    fetchMore,
  } = useQuery(ListMeetingsDocument, {
    variables: {
      type: MeetingType.INSPACE,
      spaceId,
      filter: {},
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(newData) {
      dispatch(
        gotMeetings({
          type: MeetingType.INSPACE,
          meetings: newData.meetings.meetings,
          spaceId,
        })
      );
    },
  });

  const meetings =
    storeMeetings != null
      ? Object.values(storeMeetings)
      : (data?.meetings.meetings ?? []);

  if (error) {
    if (
      error.message === 'Permission denied' ||
      error.message === 'Space not found'
    ) {
      const title = intl.formatMessage({
        defaultMessage: 'Uh-oh, this space cannot be found!',
        id: 'AB6SoN',
      });
      return (
        <Page title={title} maxWidth="sm" grow>
          <DeadEnd
            imgSrc={AstronautSvg}
            title={title}
            description={intl.formatMessage({
              defaultMessage:
                'It may be that it doesn’t exist or you might not have access to it. Get in touch with your team admin if you need access or have questions.',
              id: 'zsJ2nf',
            })}
            action={
              <Button variant="light" href={kTranscripts}>
                <FormattedMessage
                  defaultMessage="Go to my meetings"
                  id="5nKGwH"
                />
              </Button>
            }
          />
        </Page>
      );
    }
    throw error;
  }

  return (
    <Page title={space?.name ?? ''} maxWidth={'md'}>
      {space && <SpaceDetails space={space} />}
      <TranscriptsList
        meetings={meetings}
        loadMore={async () => {
          if (data?.meetings.hasMore) {
            setIsLoadingMore(true);
            const result = await fetchMore({
              variables: { offset: data.meetings.meetings.length },
            });

            dispatch(
              gotMeetings({
                type: MeetingType.INSPACE,
                spaceId,
                meetings: [...meetings, ...result.data.meetings.meetings],
              })
            );
            setIsLoadingMore(false);
          }
        }}
        isLoading={(listLoading || spaceLoading) && !meetings.length}
        isLoadingMore={isLoadingMore}
        searchBar={<SearchPopup />}
        emptyMessage={<EmptySpace />}
      />
    </Page>
  );
};

function EmptySpace() {
  const intl = useIntl();
  const goToMyMeetingsButton = (
    <Button variant="light" href={kTranscripts}>
      <FormattedMessage defaultMessage="Go to my meetings" id="5nKGwH" />
    </Button>
  );
  return (
    <div className="my-8">
      <DeadEnd
        imgSrc={AddToSpaceDeadEnd}
        imgClassName="h-64"
        title={intl.formatMessage({
          defaultMessage: 'Welcome to your new space!',
          id: 'pD1hdm',
        })}
        description={
          <div className="flex items-center">
            <FormattedMessage
              defaultMessage="Add some meetings to get started."
              id="KqONsY"
            />
            <Button
              endIcon={<ExternalLink size="1rem" />}
              variant="naked"
              href="https://help.tactiq.io/en/articles/9323061-how-to-collaborate-on-tactiq-spaces"
              target="_blank"
            >
              <FormattedMessage defaultMessage="Learn more" id="TdTXXf" />
            </Button>
          </div>
        }
        action={goToMyMeetingsButton}
      />
    </div>
  );
}

const SpaceDetails: React.FC<{ space: Space }> = ({ space }) => {
  const [isManageSpaceDialogOpen, setIsManageSpaceDialogOpen] = useState(false);
  const [isAddMembersDialogOpen, setIsAddMembersDialogOpen] = useState(false);
  const currentUserId = useSelector(selectUid);
  const canCurrentUserManage = useSelector((state: RootState) =>
    selectCanManageSpace(state, space.id, currentUserId!)
  );

  const members = space?.members;
  const isArchived = space?.isArchived;

  if (!members || members.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col flex-wrap items-center gap-2 md:flex-row">
        <h1 className="my-0 space-x-2 text-3xl">
          <span className="font-['emoji']">{space?.icon}</span>
          <span>{space?.name}</span>
        </h1>
        <div className="flex flex-grow justify-between">
          {!isArchived && (
            <Button
              onClick={() => setIsManageSpaceDialogOpen(true)}
              variant="naked"
              startIcon={<Settings className="size-5" />}
            />
          )}
          {isArchived && (
            <Chip color="red">
              <FormattedMessage defaultMessage="Archived" id="0HT+Ib" />
            </Chip>
          )}
          {canCurrentUserManage ? (
            <Button
              size="small"
              startIcon={<Plus />}
              onClick={() => setIsAddMembersDialogOpen(true)}
            >
              <FormattedMessage defaultMessage="Add members" id="OCiHj6" />
            </Button>
          ) : null}
        </div>
      </div>
      <div className="flex items-center py-4">
        <div className="mr-4">
          <AvatarGroup
            avatars={members?.map((m) => ({
              name: m.metadata.name,
              src: m.metadata.photoURL,
            }))}
          />
        </div>
        <div className="flex gap-1 text-sm">
          <span>{members.length}</span>
          <span>
            <FormattedMessage id="bppUxN" defaultMessage="member(s)" />
          </span>
        </div>
      </div>
      <SpaceSettingsDialog
        key={space.id}
        setIsOpen={setIsManageSpaceDialogOpen}
        isOpen={isManageSpaceDialogOpen}
        spaceId={space.id}
        canCurrentUserManage={canCurrentUserManage}
      />
      {isAddMembersDialogOpen && (
        <AddMembersDialog
          onClose={() => setIsAddMembersDialogOpen(false)}
          spaceId={space.id}
          open={isAddMembersDialogOpen}
          currentMembers={members}
        />
      )}
    </>
  );
};

export default SpacePage;
