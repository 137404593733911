import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { resumeSubscription } from '../../graphql/billing';
import { Button } from '../../components/buttons';
import { useMutation } from '@apollo/client';
import { ResumeTeamSubscriptionDocument } from '../../graphql/operations';
import { gotTeam } from '../../redux/modules/user';
import { Alert } from '../../components/Alert';
import {
  selectTeam,
  selectUid,
  selectUserPlan,
  selectUserPlanCancelledAfterCurrentPeriod,
} from '../../redux/selectors';

/**
 * Subscription about to cancel alert
 */
export const SubscriptionAboutToCancelAlert: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [resumeTeamSubscription] = useMutation(ResumeTeamSubscriptionDocument);
  const userId = useSelector(selectUid);
  const userPlan = useSelector(selectUserPlan);
  const paidUserPlan = userPlan.paid;
  const canResume = paidUserPlan?.__typename === 'StripePaidPlan';
  const team = useSelector(selectTeam);
  const teamPlan = team?.plan;
  const isAdmin =
    (team && team.members.some((m) => m.uid === userId && m.roles.ADMIN)) ??
    false;
  const isTeam = Boolean(isAdmin && teamPlan && teamPlan.cancelAtPeriodEnd);
  const cancelled = useSelector(selectUserPlanCancelledAfterCurrentPeriod);

  const onResume = useCallback(async () => {
    setIsLoading(true);

    if (isTeam) {
      dispatch(
        gotTeam(
          (await resumeTeamSubscription()).data?.team_billing_resumeSubscription
        )
      );
    } else {
      await resumeSubscription();
    }

    setIsLoading(false);
  }, [dispatch, isTeam, resumeTeamSubscription]);

  if (!cancelled) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      action={
        canResume ? (
          <Button onClick={onResume} loading={isLoading}>
            <FormattedMessage
              defaultMessage="Resume subscription"
              id="9CnHy1"
            />
          </Button>
        ) : null
      }
      description={
        <FormattedMessage
          defaultMessage="Your subscription will be cancelled at the end of the billing period."
          id="ma8OKQ"
          description="Subscription cancelled warning title."
        />
      }
    />
  );
};
