import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { trackWebEvent } from '../../../../helpers/analytics';
import { formatTimeAgo } from '../../../../helpers/i18n';
import { useMeeting } from '../../common/meeting-hooks';
import { Tooltip } from '../../../../components/Tooltip';
import { Avatar, AvatarGroup } from '../../../../components/Avatar';

export const Avatars: React.FC<{ meetingId: string }> = ({ meetingId }) => {
  const meeting = useMeeting(meetingId);
  const intl = useIntl();

  if (!meeting || !('user' in meeting)) {
    return null;
  }

  const owner = meeting.user;
  const views = meeting.views.filter(
    (v) => !!v.user && v.user.id !== owner?.id
  );

  return (
    <div className="flex flex-row items-center justify-between">
      {owner && (
        <div className="flex flex-row items-center">
          <Avatar
            name={owner.displayName}
            src={owner.photoURL}
            onClick={() => {
              trackWebEvent('Owner avatar clicked', {
                meetingId,
              });
            }}
          />
          <span className="ml-2 text-sm">
            <FormattedMessage
              defaultMessage="Recorded by {name}"
              id="d+zHuq"
              values={{ name: owner.displayName }}
            />
            {', '}
            {new Date(meeting.created).toLocaleString()}
            {', '}
            {Math.floor((meeting.speechDuration || meeting.duration) / 60)} min
            {', '}
            <FormattedMessage
              defaultMessage="Last updated {date}"
              id="T33gk/"
              values={{
                date: (
                  <Tooltip
                    title={
                      <FormattedMessage
                        defaultMessage="Updated by {name}"
                        id="le+d1V"
                        values={{
                          name:
                            meeting.changes[0]?.user?.displayName ??
                            owner.displayName,
                        }}
                      />
                    }
                  >
                    <span>
                      {formatTimeAgo(intl.locale, new Date(meeting.modified))}
                    </span>
                  </Tooltip>
                ),
              }}
            />
          </span>
        </div>
      )}

      <AvatarGroup
        max={3}
        avatars={views.map((v) => ({
          src: v.user?.photoURL,
          name: v.user?.displayName ?? 'Tactiq User',
        }))}
        onClick={() => {
          trackWebEvent('Views avatars clicked', {
            meetingId,
          });
        }}
      />
    </div>
  );
};
