import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/buttons';
import { trackWebEvent, trackWebPage } from '../../helpers/analytics';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { IntegrationIconClasses } from './IntegrationComponents';
import { AllIntegrations } from './integrations';
import { Alert } from '../../components/Alert';
import { deleteConnection } from '../../helpers/api/integrations/connection';
import { AlertCircle } from 'lucide-react';
import { Tooltip } from '../../components/Tooltip';
import { Link } from '../../components/Link';

const IntegrationDetails: React.FC = () => {
  const dispatch = useDispatch();
  const match = useParams<{ id: string }>();
  const userConnections = useSelector(
    (state: RootState) => state.user.connections
  );
  const intl = useIntl();
  const integration = AllIntegrations.find((x) => x.id === match.id);

  useEffect(() => {
    trackWebPage('Integration Details', {
      integration: match.id,
    });
  }, [match]);

  if (!integration) {
    return null;
  }

  const integrationConnections = userConnections?.filter((x) =>
    integration.connectionFilter
      ? integration.connectionFilter(x)
      : x.type === integration.id
  );

  const connections = integrationConnections?.length ? (
    <div className="flex flex-col gap-4">
      {integrationConnections.map((connection) => (
        <div
          key={connection.connectionKey}
          className="flex items-start justify-between gap-4"
        >
          {integration.viewer ? (
            <integration.viewer dispatch={dispatch} connection={connection} />
          ) : (
            <div className="flex-1">{connection.name}</div>
          )}
          <div className="flex items-center gap-4 pt-1">
            {integration.actions?.map((Action, index) => (
              <Action key={index} connection={connection} />
            ))}
            {connection.error ? (
              <Tooltip
                title={intl.formatMessage({
                  defaultMessage: 'This connection has a problem',
                  id: 'uIL/rj',
                })}
              >
                <AlertCircle className="mt-[4px] h-6 w-6 text-red-600" />
              </Tooltip>
            ) : null}
            {integration.showReconnectButton && integration.connector ? (
              <integration.connector />
            ) : null}
            <Button
              size="small"
              color="error"
              onClick={async () => {
                if (
                  confirm(
                    intl.formatMessage({
                      defaultMessage: 'Are you sure?',
                      id: '+eqBXG',
                      description: 'Dialog confirmation message.',
                    })
                  )
                ) {
                  await deleteConnection(connection.connectionKey);
                  trackWebEvent('Integration - Delete', {
                    type: connection.type,
                  });
                }
              }}
            >
              <FormattedMessage
                defaultMessage="Disconnect"
                id="Jf5DEc"
                description="Disconnect Integration button"
              />
            </Button>
          </div>
        </div>
      ))}
    </div>
  ) : null;

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Integrations',
        id: 'rvcwqf',
        description: 'Integrations page navigation title',
      })}
    >
      <div className="flex flex-col gap-4">
        <h1 className="text-3xl">
          <div className="flex flex-row items-center gap-2">
            <div className={IntegrationIconClasses}>
              {typeof integration.icon === 'string' ? (
                <img src={integration.icon} alt="Integration Icon" />
              ) : (
                <integration.icon />
              )}
            </div>
            {integration.title}
          </div>
        </h1>

        {integration.description && (
          <div>
            <integration.description />
          </div>
        )}

        {integration.helpUrl && (
          <Alert
            severity="info"
            description={
              <FormattedMessage
                defaultMessage="See <link>how it works</link>"
                id="tG1ZnF"
                description="How it works link text"
                values={{
                  link: (chunks) => (
                    <Link to={integration.helpUrl ?? ''} target="_blank">
                      {chunks}
                    </Link>
                  ),
                }}
              />
            }
          />
        )}

        {connections}

        <div>
          {(!connections || integration.allowMultipleConnections) &&
          integration.connector ? (
            <integration.connector />
          ) : null}
        </div>
      </div>
    </Page>
  );
};

export default IntegrationDetails;
