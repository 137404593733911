import React, { ReactElement } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from '@xyflow/react';
import { WorkflowNode } from '../nodes/BaseNode';
import { WorkflowStatus } from '../../../graphql/operations';
import { cx } from '../../../helpers/utils';

/**
 * A custom edge for rendering workflow exections.
 * The main purpose is to change the color when the previous node is complete
 */
export default function ExecutionEdge(props: EdgeProps): ReactElement {
  const reactFlow = useReactFlow();
  const { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition } =
    props;
  const sourceNode = reactFlow.getNode(props.source) as WorkflowNode;
  const targetNode = reactFlow.getNode(props.target) as WorkflowNode;
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const isComplete =
    sourceNode.data.execution?.status === WorkflowStatus.COMPLETE &&
    (targetNode.data.execution?.status === WorkflowStatus.COMPLETE ||
      targetNode.data.execution?.status === WorkflowStatus.PENDING ||
      targetNode.data.execution?.status ===
        WorkflowStatus.WAITING_FOR_CONFIRMATION);

  return (
    <>
      <BaseEdge
        path={edgePath}
        {...props}
        markerEnd={
          isComplete ? `url('#execution-edge-complete')` : props.markerEnd
        }
        className={isComplete ? 'fill-green-500 !stroke-green-500' : ''}
      />
      {props.label && (
        <EdgeLabelRenderer>
          <div
            className={cx(
              'nodrag nopan absolute bg-[var(--xy-edge-label-background-color-default)] p-2 font-bold',
              isComplete ? 'text-green-500' : ''
            )}
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
          >
            {props.label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

/**
 * React flow doesn't have a great way to extend markers.
 * So we need to export these defs and reference them with and id
 */
export function ExecutionEdgeMarkerDefs(): ReactElement {
  return (
    <svg style={{ position: 'absolute', top: 0, left: 0 }}>
      <defs>
        <marker
          id="execution-edge-complete"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            points="-5,-4 0,0 -5,4 -5,-4"
            className={'fill-green-500 stroke-green-500 stroke-1'}
          ></polyline>
        </marker>
      </defs>
    </svg>
  );
}
