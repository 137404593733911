import React, { useState } from 'react';
import { TextInput } from '.';
import { CheckIcon, Pencil, XCircle } from 'lucide-react';
import { Button } from '../buttons';

export type EditableTextProps = {
  value?: string;
  onSave: (s: string) => void;
  isValid?: (draftValue: string) => boolean;
  errorLabel?: string;
  onClick?: () => boolean;
};

export const EditableText: React.FC<EditableTextProps> = ({
  value,
  onSave,
  isValid,
  onClick,
  errorLabel,
}) => {
  const [currentValue, setCurrentValue] = useState(value ?? '');
  const [editing, setEditing] = useState(false);

  return (
    <div className="flex flex-row items-center">
      {editing ? (
        <TextInput
          value={currentValue}
          onChange={setCurrentValue}
          error={isValid && !isValid(currentValue)}
          autoFocus
          errorLabel={
            isValid && !isValid(currentValue) ? errorLabel : undefined
          }
        />
      ) : (
        <p>{currentValue}</p>
      )}

      <Button
        className="ml-2"
        variant="naked"
        size="tiny"
        onClick={() => {
          if (onClick && !onClick()) {
            return;
          }

          if (isValid && !isValid(currentValue)) {
            return;
          }
          if (editing && currentValue) {
            onSave(currentValue);
          }
          setEditing(!editing);
        }}
      >
        {editing ? (
          <CheckIcon className="h-4 w-4 text-slate-600" />
        ) : (
          <Pencil className="h-4 w-4 text-slate-600" />
        )}
      </Button>

      {editing ? (
        <Button
          variant="naked"
          size="tiny"
          onClick={() => {
            setCurrentValue(value ?? '');
            setEditing(false);
          }}
        >
          <XCircle className="h-4 w-4 text-slate-600" />
        </Button>
      ) : null}
    </div>
  );
};
