import { fetchApiV2 } from '../helpers';

export async function getGoogleDriveToken(
  connectionKey: string
): Promise<{ access_token: string }> {
  return await fetchApiV2(`/a/integrations/googledrive/token`, {
    method: 'POST',
    body: JSON.stringify({ connectionKey }),
  });
}
