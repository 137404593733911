import { RawTranscript } from '@tactiq/model';
import { fetchApiV2 } from '../helpers';
import { MeetingShareSettings } from '../../../graphql/operations';

interface CreatePageParams {
  meetingId: string;
  title: string;
  rawTranscript: RawTranscript;
  parentId: string;
  spaceKey: string;
  cloudId: string;
  connectionKey: string;
  shareSettings: MeetingShareSettings;
}

export async function createConfluencePage(
  params: CreatePageParams
): Promise<{ link: string }> {
  return await fetchApiV2('/a/integrations/confluence/create-page', {
    method: 'post',
    body: JSON.stringify(params),
  });
}

interface SearchPagesParams {
  connectionKey: string;
  cloudId: string;
  query: string;
}

export interface AtlassianSite {
  id: string;
  name: string;
  url: string;
  scopes: string[];
  avatarUrl: string;
}

export interface ConfluencePage {
  id: string;
  title: string;
  link: string;
  space: {
    id: number;
    key: string;
    name: string;
  };
}

export async function getAccessibleAtlassianSites(
  connectionKey: string
): Promise<AtlassianSite[]> {
  return await fetchApiV2('/a/integrations/confluence/accessible-sites', {
    method: 'get',
    query: {
      connectionKey,
    },
  });
}

export interface ConfluenceSearchPagesResponse {
  pages: ConfluencePage[];
}

export async function searchConfluencePages(
  params: SearchPagesParams
): Promise<ConfluenceSearchPagesResponse> {
  return await fetchApiV2('/a/integrations/confluence/search-pages', {
    method: 'get',
    query: {
      ...params,
    },
  });
}
