import React, { ReactElement } from 'react';
import AstronautSvg from '../../img/astronaut.svg';
import { cx } from '../../helpers/utils';

export function DeadEnd(props: {
  /** The main text of the dead end */
  title: React.ReactNode;
  /** Furthur text explaining why the customer found this dead end */
  description?: React.ReactNode;
  /** A react node allowing the customer to exit out to something that works */
  action: React.ReactNode;
  /** Optional override of the default 404 image */
  imgSrc?: string;
  /** Set the height of the image */
  imgClassName?: string;
  imgAlt?: string;
}): ReactElement {
  const { title, description, action, imgSrc, imgClassName, imgAlt } = props;
  return (
    <div className="flex h-full flex-grow flex-col items-center justify-center gap-3 text-center">
      <img
        src={imgSrc ?? AstronautSvg}
        alt={imgAlt}
        className={cx('max-h-96', imgClassName)}
      />
      <h1 className="text-2xl font-semibold leading-6 text-gray-900">
        {title}
      </h1>
      {description && <span>{description}</span>}
      {action}
    </div>
  );
}
