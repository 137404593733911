import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelectRawTranscriptSelector } from '../../../../redux/selectors';
import { useMeeting } from '../../common/meeting-hooks';
import { TranscriptCommentsComponent } from './TranscriptComments';
import { RootState } from '../../../../redux/store';
import { isMeetingEditable } from '../../../../helpers/meetings';

export const TranscriptComments: React.FC<{ meetingId: string }> = ({
  meetingId,
}) => {
  const meeting = useMeeting(meetingId);
  const selectRawTranscript = useMemo(createSelectRawTranscriptSelector, []);
  const transcript = useSelector((state: RootState) =>
    selectRawTranscript(state, meetingId)
  );
  const isEditable = isMeetingEditable(meeting);

  if (meeting?.archivedAt) {
    return null;
  }

  if (!transcript || !meeting || meeting.id === 'tutorial') {
    return null;
  }

  return (
    <TranscriptCommentsComponent
      place="comments"
      meetingId={meetingId}
      canDelete={isEditable}
    />
  );
};
