import { enqueueSnackbar } from 'notistack';
import { fetchApiV2 } from './helpers';
import { TeamInvitationLink } from '../../graphql/operations';

const handlePaypalError = async (promise: Promise<void>) => {
  try {
    await promise;
  } catch (err) {
    if (
      err instanceof Error &&
      err.message.includes('Unsupported payment type')
    ) {
      enqueueSnackbar(
        'Team management is not available for PayPal subscriptions. Tactiq team will reach out to you to enable this feature.',
        { variant: 'WARNING' }
      );
    } else {
      throw err;
    }
  }
};

export const addTeamMember = async (
  teamId: string,
  memberEmail: string
): Promise<void> => {
  return handlePaypalError(
    fetchApiV2(`/a/teams/${teamId}/members`, {
      method: 'POST',
      body: JSON.stringify({
        memberEmail,
      }),
    })
  );
};

export const removeTeamMember = async (
  teamId: string,
  memberId: string
): Promise<void> => {
  return await fetchApiV2(`/a/teams/${teamId}/members/${memberId}`, {
    method: 'DELETE',
  });
};

export const upgradePendingTeamMember = async (
  teamId: string,
  memberEmail: string
): Promise<void> => {
  return await fetchApiV2(`/a/teams/${teamId}/invitations/${memberEmail}`, {
    method: 'POST',
  });
};

export const removeTeamInvitation = async (
  teamId: string,
  memberEmail: string
): Promise<void> => {
  return await fetchApiV2(`/a/teams/${teamId}/invitations/${memberEmail}`, {
    method: 'DELETE',
  });
};

export const createTeamInvitationLink = async (
  teamId: string
): Promise<TeamInvitationLink> => {
  return await fetchApiV2(`/a/teams/${teamId}/invitation-link`, {
    method: 'POST',
  });
};

export const consumeTeamInvitationLink = async (
  link: string
): Promise<void> => {
  return await fetchApiV2(`/a/teams/invitations/${link}`, {
    method: 'POST',
  });
};
