import React, { PropsWithChildren } from 'react';
import { MeetingComment } from '../../../../../../graphql/operations';
import { CommentBox } from './Box';
import { cx } from '../../../../../../helpers/utils';

/**
 * Comment list
 */
export const CommentList: React.FC<
  PropsWithChildren<{
    comments: MeetingComment[];
    /* reply handler */
    onReply?: (commentId: string) => void;
    isCommenting?: boolean;
  }>
> = ({ comments, onReply, children, isCommenting }) => {
  return (
    <div
      className={cx(
        comments.length > 0 || isCommenting
          ? 'mb-4 space-y-8 bg-slate-50 p-4 sm:ml-16'
          : ''
      )}
    >
      {comments.map((comment) => {
        const onReplyClick = onReply && (() => onReply(comment.id));

        return (
          <CommentBox
            key={comment.id}
            comment={comment}
            onReply={onReplyClick}
          />
        );
      })}
      {children}
    </div>
  );
};
