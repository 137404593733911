import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import { trackWebEvent } from '../../helpers/analytics';
import { api2post } from '../../helpers/api2';
import { copyToClipboard } from '../../helpers/clipboard';
import { baseURL } from '../../helpers/firebase/config';
import { RootState } from '../../redux/store';
import { Loading } from '../Landing/Loading/LoadingComponent';
import { selectTeam } from '../../redux/selectors';

const SettingInputRowClasses = 'flex flex-nowrap content-start pt-2.5';

export const SettingAddButtonRowClasses =
  'flex flex-wrap items-center content-start pl-1.5';

/**
 * Invitation link component
 * @returns {React.FC} Component
 */
export const InvitationLink: React.FC = () => {
  const userLoaded = useSelector((state: RootState) => state.global.userLoaded);
  const invitationlink = useSelector(
    (state: RootState) => state.user.invitationLink
  );
  const operations = useSelector((state: RootState) => state.global.operations);
  const team = useSelector(selectTeam);
  const intl = useIntl();

  const link = invitationlink
    ? {
        id: invitationlink.id,
        url: `${baseURL}/x/u/l/${invitationlink.id}`,
        used: invitationlink.used,
      }
    : null;

  if (!userLoaded) return null;

  let content;

  if (!link) {
    if (operations?.isCreatingInvitationLink) {
      content = <Loading />;
    } else {
      content = (
        <div className={SettingInputRowClasses}>
          <div className={SettingAddButtonRowClasses}>
            <Button
              onClick={async () => {
                trackWebEvent('Created invitation link');
                await api2post('a/invitations/link/create', {});
              }}
            >
              <FormattedMessage
                defaultMessage="Create a link"
                id="PKjALt"
                description="Button title."
              />
            </Button>
          </div>
        </div>
      );
    }
  } else {
    content = (
      <div className="flex items-start gap-2">
        <TextInput
          value={link.url}
          inputProps={{
            readOnly: true,
            onFocus: (e) => e.target.select(),
          }}
        />
        <div>
          <Button
            onClick={async () => {
              trackWebEvent('Copied invitation link', { team_id: team?.id });
              await copyToClipboard(
                link.url,
                intl.formatMessage({
                  defaultMessage: 'Copied!',
                  id: 'abQhGK',
                  description: 'Toast copy success message',
                })
              );
            }}
          >
            <FormattedMessage
              defaultMessage="Copy"
              id="wG+mcz"
              description="Button title."
            />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="text-sm font-medium text-slate-600">
        <FormattedMessage
          defaultMessage="Share your unique link"
          id="2zwwmi"
          description="Share link header."
        />
      </div>
      {content}
    </>
  );
};
