import React from 'react';

import { Highlight } from '../../../common/Highlight';
import { ViewMode } from '../../types';
import { RawBlock } from '@tactiq/model';
import { Screenshot } from './screenshot';
import { Tag } from '../../../../../graphql/operations';
import { FuseResultMatch } from 'fuse.js';
import { cx } from '../../../../../helpers/utils';

const QuoteWrapperClasses =
  'rounded-sm text-slate-800 tracking-body whitespace-pre-wrap';

const CombinedQuoteWrapperClasses = 'flex flex-row gap-5';

/**
 * Quote component
 * @param {unknown} props params
 * @param {number} props.refIndex refIndex
 * @param {string} props.messageId messageId
 * @param {number} props.timestamp timestamp
 * @param {string} props.transcript transcript
 * @param {RawBlock['type']} props.type type
 * @param {string} props.translatedTranscript translatedTranscript
 * @param {boolean} props.isPinned isPinned
 * @param {boolean} props.isHighlight isHighlight
 * @param {Tag[]} props.tags tags
 * @param {ViewMode} props.viewMode viewMode
 * @param {FuseResultMatch[]} props.matches matches
 * @param {FuseResultMatch[]} props.currentMatches currentMatches
 * @returns {React.FC} component
 */
export const Quote: React.FC<{
  refIndex?: number;
  messageId: string;
  timestamp: number;
  transcript: string;
  type: RawBlock['type'];
  translatedTranscript?: string;
  isPinned: boolean;
  tags?: Tag[];
  viewMode: ViewMode;
  matches?: readonly FuseResultMatch[];
  currentMatches?: readonly FuseResultMatch[];
}> = (props) => {
  const {
    refIndex,
    messageId,
    timestamp,
    transcript,
    type,
    translatedTranscript,
    isPinned,
    viewMode,
    matches,
    currentMatches,
  } = props;

  const sharedProps = {
    color: isPinned ? 'yellow' : 'transparent',
  };

  if (type === 'screenshot') {
    return (
      <span>
        <span
          className={cx(
            QuoteWrapperClasses,
            `select-none bg-[${sharedProps.color}]`
          )}
        >
          <Screenshot timestamp={timestamp} link={transcript} />
        </span>
      </span>
    );
  }

  switch (viewMode) {
    case ViewMode.translated: {
      return (
        <span>
          <span
            className={cx(
              QuoteWrapperClasses,
              `select-none bg-[${sharedProps.color}]`
            )}
          >
            {translatedTranscript ? (
              <Highlight
                messageId={messageId}
                refIndex={refIndex}
                text={translatedTranscript}
                matchKey="translatedTranscript"
                matches={matches}
                currentMatches={currentMatches}
                type={type}
              />
            ) : null}
          </span>
        </span>
      );
    }
    case ViewMode.combined: {
      return (
        <span className={CombinedQuoteWrapperClasses}>
          <div className="min-w-[50%]">
            <span
              className={cx(
                QuoteWrapperClasses,
                `select-text bg-[${sharedProps.color}]`
              )}
            >
              <Highlight
                messageId={messageId}
                refIndex={refIndex}
                text={transcript}
                matchKey="transcript"
                matches={matches}
                currentMatches={currentMatches}
                type={type}
              />
            </span>
          </div>
          <div className="min-w-[50%]">
            <span
              className={cx(
                QuoteWrapperClasses,
                `select-none bg-[${sharedProps.color}]`
              )}
            >
              {translatedTranscript ? (
                <Highlight
                  messageId={messageId}
                  refIndex={refIndex}
                  text={translatedTranscript}
                  matchKey="translatedTranscript"
                  matches={matches}
                  currentMatches={currentMatches}
                  type={type}
                />
              ) : null}
            </span>
          </div>
        </span>
      );
    }
    default: {
      return (
        <span>
          <span
            className={cx(
              QuoteWrapperClasses,
              `select-text bg-[${sharedProps.color}] text-slate-600`
            )}
          >
            <Highlight
              messageId={messageId}
              refIndex={refIndex}
              text={transcript}
              matchKey="transcript"
              matches={matches}
              currentMatches={currentMatches}
              type={type}
            />
          </span>
        </span>
      );
    }
  }
};
