import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import { Eye, EyeOff } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Tooltip } from '../../../../components/Tooltip';
import { Button } from '../../../../components/buttons';
import { trackWebEvent } from '../../../../helpers/analytics';
import { setFilteredParticipants } from '../../../../redux/modules/global';
import { useMeeting } from '../../common/meeting-hooks';
import { ParticipantStatsBar } from './Bar';
import { ExpandParticipants } from './ExpandParticipants';

export interface Participant {
  name: string;
  color: string;
  analytics: {
    textLength: number;
  };
}

const MAX_PARTICIPANTS = 3;

const getTextDuration = (textLength: number) => {
  // calculated based on average of 150 w/min and 4.7 symbols/word (English)
  return (textLength * 60) / (4.7 * 150);
};

/**
 * Participant Stats
 * @param {unknown} param0 params
 * @param {Participant[]} param0.participants participants
 * @param {string[]} param0.filteredParticipants filtered participants
 * @returns {React.FC} component
 */
export const ParticipantStats: React.FC<{
  participants: Participant[];
  filteredParticipants?: string[];
}> = ({ participants, filteredParticipants = [] }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  const meeting = useMeeting();
  const dispatch = useDispatch();

  const totalTextLength = sumBy(participants, (p) =>
    get(p, 'analytics.textLength', 0)
  );
  const totalDuration = getTextDuration(totalTextLength);
  const sortedParticipants = sortBy(
    participants,
    ({ analytics: { textLength } }) => -textLength
  );

  if (!meeting) return null;

  return (
    <ul className="divide-y divide-gray-100">
      {(showMore
        ? sortedParticipants
        : sortedParticipants.slice(0, MAX_PARTICIPANTS)
      ).map(({ name, color, analytics: { textLength } }) => {
        const participantDuration = getTextDuration(textLength);
        return (
          <li className="flex flex-col py-1" key={name}>
            <div className="flex flex-row gap-2">
              {filteredParticipants.includes(name) ? (
                <Tooltip
                  title={
                    <FormattedMessage
                      defaultMessage="Click to show the participant in the transcript"
                      id="4w0RCW"
                    />
                  }
                >
                  <Button
                    variant="icon"
                    size="small"
                    onClick={() => {
                      trackWebEvent('Meeting participant filtered', {
                        meetingId: meeting.id,
                        status: 'visible',
                      });

                      dispatch(
                        setFilteredParticipants({
                          meetingId: meeting.id,
                          participants: filteredParticipants.filter(
                            (fp) => fp !== name
                          ),
                        })
                      );
                    }}
                  >
                    <EyeOff className="h-4 w-4 text-blue-600" />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <FormattedMessage
                      defaultMessage="Click to hide the participant in the transcript"
                      id="iX+B2g"
                    />
                  }
                >
                  <Button
                    size="small"
                    variant="icon"
                    onClick={() => {
                      trackWebEvent('Meeting participant filtered', {
                        meetingId: meeting.id,
                        status: 'hidden',
                      });

                      dispatch(
                        setFilteredParticipants({
                          meetingId: meeting.id,
                          participants: [...filteredParticipants, name],
                        })
                      );
                    }}
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                </Tooltip>
              )}
              <span>{name}</span>
            </div>
            <div className="flex flex-row items-center gap-2">
              <ParticipantStatsBar
                color={color}
                width={`${Math.round(
                  (participantDuration / totalDuration) * 100
                )}`}
              />
              <p>{Math.round((participantDuration / totalDuration) * 100)}%</p>
            </div>
          </li>
        );
      })}
      {participants.length > MAX_PARTICIPANTS ? (
        <ExpandParticipants isExpanded={showMore} onClick={toggleShowMore} />
      ) : null}
    </ul>
  );
};
