import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideRateUsModal } from '../../../redux/modules/global';
import { selectShowRateUsModal, selectUid } from '../../../redux/selectors';
import { RateModal as RateModalImpl } from './RateModal';
import { useMutation } from '@apollo/client';
import { UpdateUserSettingDocument } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';

/**
 * Rate us modal
 * @returns {React.FC} a component
 */
const RateModal: React.FC = () => {
  const showRateUsModal = useSelector(selectShowRateUsModal);
  const uid = useSelector(selectUid);
  const dispatch = useDispatch();

  const [updateUserSetting] = useMutation(UpdateUserSettingDocument);

  const onClose = useCallback(async () => {
    const now = Date.now();
    trackWebEvent('Rate us modal onClose clicked');
    dispatch(hideRateUsModal());
    await updateUserSetting({
      variables: {
        input: {
          lastRateUsModalTimestamp: Math.ceil(now / 1000),
        },
      },
    });
  }, [updateUserSetting, dispatch]);

  const onSubmit = useCallback(async () => {
    const now = Date.now();
    trackWebEvent('Rate us modal onSubmit clicked');
    dispatch(hideRateUsModal());
    await updateUserSetting({
      variables: {
        input: {
          lastRateUsModalTimestamp: Math.ceil(now / 1000),
        },
      },
    });
  }, [updateUserSetting, dispatch]);

  if (!uid) return null;
  if (!showRateUsModal) return null;

  return (
    <RateModalImpl
      uid={uid}
      open={showRateUsModal}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default RateModal;
