import React, { useCallback, ReactElement } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { Checkbox } from '../../../components/Checkbox';
import { FormattedMessage } from 'react-intl';
import { AiOutputLanguageSelector } from '../../Common/AiOutputLanguageSelector';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { SquarePen } from 'lucide-react';
import { useWorkflowId } from '../WorkflowIdContext';

export type CustomPromptData = {
  prompt: string;
  saveToMeeting: boolean;
  aiOutputLanguage: string;
  includeTranscriptContext: boolean;
};

export function CustomPrompt(
  props: WorkflowNodeProps<CustomPromptData>
): ReactElement {
  const { id } = props;
  const { updateNodeData: update } = useReactFlow();
  const value = props.data.prompt ?? '';
  const autocomplete = useAutocomplete(props);
  const { workflowId } = useWorkflowId();
  const handleChange = useCallback(
    (prompt: string) => {
      update(id, { prompt });
    },
    [id, update]
  );

  const input = (
    <div className="flex grow flex-col gap-3">
      <LiquidTemplateInput
        className="min-h-48 text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        value={value}
        onChange={handleChange}
        workflowId={workflowId}
        nodeType={props.type}
      />

      <div className="flex flex-col gap-2">
        <Checkbox
          id={`${props.id}-applyToMeeting`}
          checked={props.data.saveToMeeting}
          label={
            <FormattedMessage
              defaultMessage="Save the result to the meeting"
              id="6306sX"
            />
          }
          onChange={(saveToMeeting) => update(id, { saveToMeeting })}
        />
        <Checkbox
          id={`${props.id}-includeTranscriptContext`}
          checked={props.data.includeTranscriptContext}
          label={
            <FormattedMessage
              defaultMessage="Include the entire transcript in the context (uncheck to make this faster)"
              id="/Jlf5s"
            />
          }
          onChange={(includeTranscriptContext) =>
            update(id, { includeTranscriptContext })
          }
        />
        <AiOutputLanguageSelector
          onChange={(aiOutputLanguage) => update(id, { aiOutputLanguage })}
          value={props.data.aiOutputLanguage}
          context="meeting"
        />
      </div>
    </div>
  );

  return (
    <SingleSourceNode
      icon={
        <SquarePen className="size-8 rounded-md bg-brand-50 p-2 text-brand" />
      }
      workflowNode={props}
      minHeight={392}
    >
      {input}
    </SingleSourceNode>
  );
}
