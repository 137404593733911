import { PrivacySettings } from '..';

export interface MeetingParticipant {
  name: string;
  analytics: {
    textLength: number;
    speechDuration?: number;
  };
}

export enum MeetingTranscriptType {
  googlemeet = 'googlemeet',
  googledrive = 'googledrive',
  tactiq = 'tactiq',
  confluence = 'confluence',
  notion = 'notion',
}

export enum GoogleMeetLangId {
  en = 1,
  es = 2,
  fr = 3,
  de = 4,
  it = 5,
  pt = 6,
}

// @deprecated use GoogleDriveMeetingTranscript
export interface GoogleMeetingTranscript {
  type: MeetingTranscriptType.googlemeet;
  externalId: string;
  externalLink: string;
  langId: GoogleMeetLangId;
}

export interface GoogleDriveMeetingTranscript {
  type: MeetingTranscriptType.googledrive;
  externalId: string;
  externalLink: string;
  isPreview?: boolean;
}

export interface TactiqMeetingTranscript {
  type: MeetingTranscriptType.tactiq;
  externalId: string;
  externalLink: string;
}

export interface ConfluenceMeetingTranscript {
  type: MeetingTranscriptType.confluence;
  externalId: string;
  externalLink: string;
  connection: {
    key: string;
    options: {
      cloudId: string;
      parentId: string;
      spaceKey: string;
    };
  };
}

export interface NotionMeetingTranscript {
  type: MeetingTranscriptType.notion;
  externalId: string;
  externalLink: string;
  connection: {
    key: string;
    options: {
      parentId: string;
    };
  };
}

export interface IntegrationMeetingTranscript {
  type: string;
  externalId: string;
  externalLink: string;
  integrationId: string;
}

export type MeetingTranscript =
  | GoogleMeetingTranscript
  | GoogleDriveMeetingTranscript
  | TactiqMeetingTranscript
  | IntegrationMeetingTranscript
  | ConfluenceMeetingTranscript
  | NotionMeetingTranscript;

export interface MeetingShareSettings {
  isSharingDetails: boolean;
  isSharingHighlights: boolean;
  isSharingTranscript: boolean;
  isSharingNotes: boolean;
}

export enum MeetingReach {
  restricted = 'restricted',
  public = 'public',
}

export interface GenericMeetingShareData {
  connectionKey: string;
  integrationId: string;
  link: string;
  externalId?: string;
  meetingId: string;
  title: string;
  sharedAt?: number;
  options: MeetingShareSettings & {
    // @deprecated
    reach?: MeetingReach;
    // @deprecated
    emails?: string[];
    // @deprecated
    key?: string;
  };
}

export enum IntegrationId {
  link = 'link',
  atlassian = 'atlassian',
  notion = 'notion',
  slack = 'slack',
  dropbox = 'dropbox',
  hubspot = 'hubspot',
  pipedrive = 'pipedrive',
}

export type MeetingShareData =
  | GenericMeetingShareData
  | ConfluenceMeetingShareData
  | NotionMeetingShareData
  | HubspotMeetingShareData
  | DropboxMeetingShareData
  | SlackMeetingShareData;

export interface ConfluenceMeetingShareData extends GenericMeetingShareData {
  integrationId: IntegrationId.atlassian;
  options: MeetingShareSettings & {
    parentId: string;
    spaceKey: string;
    cloudId: string;
    parentName: string;
  };
}

export interface NotionMeetingShareData extends GenericMeetingShareData {
  integrationId: IntegrationId.notion;
  options: MeetingShareSettings & {
    parentId: string;
    parentName: string;
  };
}

export interface HubspotMeetingShareData extends GenericMeetingShareData {
  integrationId: IntegrationId.hubspot;
  options: MeetingShareSettings & {
    contactId: string;
    contactName: string;
  };
}

export interface DropboxMeetingShareData extends GenericMeetingShareData {
  intergrationId: IntegrationId.dropbox;
  options: MeetingShareSettings;
}

export interface SlackMeetingShareData extends GenericMeetingShareData {
  integrationId: IntegrationId.slack;
  options: MeetingShareSettings & {
    channelId: string;
    channelName: string;
  };
}

export interface PipedriveMeetingShareData extends GenericMeetingShareData {
  integrationId: IntegrationId.pipedrive;
  options: MeetingShareSettings & {
    contactName: string;
    dealId: number;
  };
}

export type MeetingGoogleMeetRecording = {
  fileId: string;
  fileUrl: string;
  iconLink: string;
  mimeType: string;
  title: string;
};

export interface MeetingCustomRecording {
  url: string;
}

export interface MeetingDataTag {
  name: string;
  count: number;
}

export interface MeetingPermission {
  reach: MeetingReach;
  emails: string[];
  teams?: string[];
  spaces?: string[];
  settings: MeetingShareSettings;
}

export enum MeetingPlatform {
  GOOGLE_MEET = 'GOOGLE_MEET',
  ZOOM = 'ZOOM',
  ZOOM_API = 'ZOOM_API',
  MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
  WEBEX_API = 'WEBEX_API',
  UPLOADED_TRANSCRIPT = 'UPLOADED_TRANSCRIPT',
  UPLOADED_AUDIO = 'UPLOADED_AUDIO',
  UPLOADED_VIDEO = 'UPLOADED_VIDEO',
  UNKNOWN = 'UNKNOWN',
}

export interface MeetingPermissions {
  allow: MeetingPermission;
}

export enum MeetingAccess {
  read = 'read',
  write = 'write',
  admin = 'admin',
}

export interface MeetingCalendarDataParticipant {
  email: string;
  displayName: string;
  optional: boolean;
  organizer: boolean;
  responseStatus: string | null;
}

export enum CalendarPlatform {
  google = 'google',
  webex = 'webex',
}

export interface MeetingCalendarData {
  calendarId: string;
  platform?: CalendarPlatform;
  eventId: string | null;
  participants: MeetingCalendarDataParticipant[];
  organizer?: MeetingCalendarDataParticipant;
  creator?: MeetingCalendarDataParticipant;
  start: number;
  end: number;
  isRecurring?: boolean;
}

export type MeetingSettings = {
  privacy?: PrivacySettings;
  storage?: {
    screenshotsFolderId: string;
  };
};

export interface MeetingData extends WidgetMeeting {
  meetingId: string;
  draft?: boolean;
  uploading?: boolean;
  processingError?: boolean;
  title: string;
  transcripts?: MeetingTranscript[];
  tags?: MeetingDataTag[];
  labels?: string[];
  screenshotsCount?: number;
  processed?: boolean;
  platform: MeetingPlatform;
  languageCode: string;
  /**
   * @deprecated
   */
  processed_sendIndividualMeetingLink?: true;
  pathnameSHA512?: string;
  duration: number;
  speechDuration?: number;
  created: {
    seconds: number;
    nanoseconds: number;
  };
  modified: {
    seconds: number;
    nanoseconds: number;
  };
  archivedAt?: number;
  version: number;
  shares?: MeetingShareData[];
  userRef: unknown;
  consumedAutosave?: boolean;
  isPreview?: boolean;
  isOnboarding?: boolean;
  notesLength?: number;
  translatedTo?: string;
  recordings?: {
    googlemeet?: MeetingGoogleMeetRecording[];
    custom?: MeetingCustomRecording[];
  };
  permissions?: MeetingPermissions;
  calendarData?: MeetingCalendarData;
  settings?: MeetingSettings;
  commentsCount?: number;
  viewsCount?: number;
  changesCount?: number;
  indexedVersion?: number;
  movedFrom?: string;
  noSharingPrompts?: boolean;
  aiOutputs?: {
    promptOutputs?: Record<string, AIPromptOutputData>;
    structured?: {
      actionItems?: AIStructuredActionItemsData;
    };
  };
}

/**
 * This can come from three places:
 * - a system (e.g. "summary", "next meeting agenda")
 * - a meeting kit item of type PROMPT
 * - a one-off prompt
 */
export type AIPromptOutputData = {
  requestedAt: number;
  generatedAt?: number;
  finishReason?: string;

  prompt: string;

  // these two should be the only two fields editable after generation
  contentType: PromptOutputTypeData;
  content?: string | AIPromptOutputDataContent;

  // this is true if it comes from the predefined system prompts
  isSystemPrompt?: boolean;

  // these two will be set if it was a meeting kit prompt; meetingKitId will be set to 'system' if it was a system prompt, and meetingKitItemId will be a fixed string for system prompts
  meetingKitId?: string;
  meetingKitItemId?: string;

  // this will be set if it was a meeting kit prompt or a system prompt (e.g. "next meeting agenda")
  promptTitle?: string;

  askedBy?: AIPromptOutputAskedByData;
};

export type AIPromptOutputDataContentText = {
  text: string;
};

export type AIPromptOutputDataContentActionItems = {
  actionItems: AIStructuredActionItems[];
};

export type AIPromptOutputDataContent =
  | AIPromptOutputDataContentText
  | AIPromptOutputDataContentActionItems;

export type AIPromptOutputAskedByData = {
  uid: string;
  displayName: string;
  photoUrl: string;
};

export type AIStructuredActionItemsData = {
  requestedAt: number;
  generatedAt?: number;
  finishReason?: string;

  content?: AIStructuredActionItems[];
};

export enum PromptOutputTypeData {
  ACTION_ITEMS = 'ACTION_ITEMS',
  MARKDOWN = 'MARKDOWN',
  TEXT = 'TEXT',
}

export interface AIStructuredActionItems {
  participantName: string;
  actions: AIGeneratedActionItem[];
}

export interface AIGeneratedActionItem {
  isDone: boolean;
  content: string;
}

export type UpdateMeetingData = Partial<Pick<MeetingData, 'title'>>;

export enum AutosaveStatusCode {
  success = 'success',
  disabled = 'disabled',
  empty = 'empty',
  paused = 'paused',
  error = 'error',
  unauthorized = 'unauthorized',
  noCredits = 'noCredits',
  networkIssue = 'networkIssue',
}

export interface AutoSaveStatus {
  errorMessage?: string;
  messageCode: AutosaveStatusCode;
  lastAutosaveMs?: number;
  nextAutosaveDelay?: number;
}

export interface AutoSaveResult {
  code?: string;
  error?: {
    code: string;
    message?: string;
  };
}

export interface WidgetMeeting {
  meetingId?: string;
  title?: string;
  platform: MeetingPlatform;
  participants?: string[] | MeetingParticipant[];
  speechDuration?: number;
  pathnameSHA512?: string;
  translatedTo?: string;
  partIndex?: number;
  languageCode?: string;
  isZoomHost?: boolean;
}

export interface SaveMeetingResponse {
  meetingId?: string;
  transcripts?: MeetingTranscript[];
  mustRelogin?: boolean;
  delay?: number;
}

export enum MeetingKitOwnerTypeData {
  SELF = 'SELF',
  USER = 'USER',
  TEAM = 'TEAM',
}

export type MeetingKitOwnerData = {
  type: MeetingKitOwnerTypeData;
  id: string;
  sourceId?: string;
  subscribedAt?: number;
  updatedAt?: number;
  sourceLastUpdatedAt?: number;
};

export type MeetingKitSharingData = {
  public: boolean;
  allowCopy: boolean;
  // userIds: [ID!]!
  // teamIds: [ID!]!
};

export type MeetingKitSharingUsersData = {
  kit: string;
  uid: string;
  email: string;
  displayName: string;
};

export type MeetingKitSharingTeamsData = {
  kit: string;
  uid: string;
  email: string;
  displayName: string;
};

export type MeetingKitData = {
  id: string;
  name: string;
  description: string;
  icon: string;

  owner: MeetingKitOwnerData;
  shared: MeetingKitSharingData;
  items: MeetingKitItemData[];

  explore?: boolean;
  publishedId?: string;
  publishedFrom?: string;
};

export enum MeetingKitItemTypeData {
  PROMPT = 'PROMPT',
}

export interface MeetingKitItemBaseData {
  id: string;
  name: string;
  description: string;
  icon: string;
  order?: number;

  type: MeetingKitItemTypeData;
  outputType: PromptOutputTypeData;
}

export interface MeetingKitItemPromptData extends MeetingKitItemBaseData {
  type: MeetingKitItemTypeData.PROMPT;
  prompt: string;
}

export type MeetingKitItemData = MeetingKitItemPromptData;

export type MeetingAccessRequest = {
  meetingId: string;
  uid: string;
};
