import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { trackWebPage } from '../../../helpers/analytics';
import { Alert } from '../../../components/Alert';
import { CalendarClock } from 'lucide-react';

export const PreviewMeetingsAlert: React.FC<{
  meetingsCount: number;
  lastRenewal: number;
  onUpgrade: () => void;
}> = ({ meetingsCount, lastRenewal, onUpgrade }) => {
  const nextRenewal = new Date(lastRenewal);
  nextRenewal.setMonth(nextRenewal.getMonth() + 1);

  useEffect(() => {
    trackWebPage('Preview meetings alert', {
      meetingsCount,
    });
  }, [meetingsCount]);

  return (
    <Alert
      severity="error"
      alternateIcon={<CalendarClock />}
      action={
        <Button size="small" onClick={onUpgrade}>
          <FormattedMessage
            defaultMessage="Upgrade"
            id="QUGHWv"
            description="Button title."
          />
        </Button>
      }
      description={
        <FormattedMessage
          defaultMessage="{count} preview meeting(s) created before {date} will be archived in {days} days. Upgrade to access unlimited meetings."
          id="yRDrlo"
          description="Preview meetings cleanup warning message."
          values={{
            count: <b>{meetingsCount}</b>,
            date: <b>{new Date(lastRenewal).toLocaleDateString()}</b>,
            days: (
              <b>
                {Math.ceil(
                  (nextRenewal.getTime() - Date.now()) / (1000 * 60 * 60 * 24)
                )}
              </b>
            ),
          }}
        />
      }
    />
  );
};
