import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectTeam,
  selectUserEmail,
  selectUserTier,
} from '../redux/selectors';
import {
  OauthClientDocument,
  GetOauthClientRedirectUrlDocument,
  UserTier,
} from '../graphql/operations';
import { useQuery, useMutation } from '@apollo/client';
import { Button } from '../components/buttons';
import { FormattedMessage, useIntl } from 'react-intl';

import { CenteredPanel } from '../components/panels/centered';
import { TierPricingDialog } from './Credits/TierPricing/TierPricingDialog';
import { TierPricingDialogSource } from '../helpers/analytics';
import { GlobalLoadingClasses } from './Common/Loading';

function canUseOauthClient(
  currentUser: UserTier,
  clientTier: UserTier
): boolean {
  const tierRank: Record<UserTier, number> = {
    [UserTier.FREE]: 1,
    [UserTier.PRO]: 2,
    [UserTier.TEAM]: 3,
    [UserTier.ENTERPRISE]: 4,
  };

  return tierRank[currentUser] >= tierRank[clientTier];
}

function rejectClient({
  redirect_uri,
  reason,
}: {
  redirect_uri: string;
  reason?: string;
}) {
  if (reason) {
    window.location.href = `${redirect_uri}?error=access_denied&error_description=${reason}`;
    return;
  }
  window.location.href = `${redirect_uri}?error=access_denied&error_description=The+user+has+denied+your+application+access`;
}

function ErrorMessage({ error }: { error: React.ReactElement | string }) {
  return (
    <div className={GlobalLoadingClasses}>
      <p>{error}</p>
    </div>
  );
}

export const OauthConsentPage: React.FC = function () {
  const pageSearchParams = new URLSearchParams(window.location.search);
  const state = pageSearchParams.get('state') || '';
  const scope = pageSearchParams.get('scope') || '';
  const client_id = pageSearchParams.get('client_id') || '';
  const redirect_uri = pageSearchParams.get('redirect_uri') || '';
  const [getRedirectUrl, getRedirectUrlMutation] = useMutation(
    GetOauthClientRedirectUrlDocument
  );

  const userEmail = useSelector(selectUserEmail);
  const userTier = useSelector(selectUserTier);
  const intl = useIntl();

  const scopeNames: Record<string, string> = {
    'transcripts:list': intl.formatMessage({
      defaultMessage: 'View list of your transcripts',
      id: 'WPjddQ',
    }),
    'transcript:read': intl.formatMessage({
      defaultMessage: 'Read your transcripts',
      id: 'O2qwfe',
    }),
  };

  const {
    previousData,
    data = previousData,
    loading,
  } = useQuery(OauthClientDocument, {
    variables: {
      id: client_id ?? '',
    },
  });

  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const team = useSelector(selectTeam);

  const scopesArray = scope.split(' ').map((s) => s.trim());

  const authorizeClient = async () => {
    try {
      const response = await getRedirectUrl({
        variables: {
          input: {
            client_id,
            redirect_uri,
            state,
            scopes: scopesArray,
          },
        },
      });
      const location = response.data?.getOauthClientRedirectUrl?.redirect_uri;
      if (!location) {
        throw new Error(
          intl.formatMessage({
            defaultMessage: 'Could not get redirect url',
            id: '9iwWuN',
          })
        );
      }
      window.location.href = location;
    } catch (error) {
      return rejectClient({
        redirect_uri: data?.oauthClient?.redirect_uri || '',
        reason: intl.formatMessage(
          {
            defaultMessage: 'Failed to authorize the client: {errorMessage}',
            id: 'Kz123l',
          },
          {
            errorMessage: error.message,
          }
        ),
      });
    }
  };

  if (!state || !client_id || !redirect_uri) {
    return (
      <ErrorMessage
        error={
          <FormattedMessage
            defaultMessage="Client configuration error: Missing state, client_id or redirect_uri."
            id="x31y1j"
          />
        }
      />
    );
  }

  let content = null;

  if (loading) {
    content = <FormattedMessage defaultMessage="Loading..." id="gjBiyj" />;
  } else if (!data?.oauthClient) {
    content = 'Error';
  } else if (redirect_uri !== data.oauthClient.redirect_uri) {
    content = (
      <ErrorMessage
        error={
          <FormattedMessage
            defaultMessage="Client configuration error: Mismatched redirect_uri."
            id="6Qa7Rm"
          />
        }
      />
    );
  } else if (canUseOauthClient(userTier, data.oauthClient.requiredTier)) {
    content = (
      <>
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-row gap-8 p-2">
            <img
              src="/assets/tactiq-addon.png"
              alt="Tactiq logo"
              className="h-[4rem] w-[4rem]"
            />

            <svg viewBox="0 0 200.4 200.4" className="h-[4rem] w-[4rem]">
              <path d="M7.9 102.3h184.6l-31.8 31.8 2.9 2.9 36.8-36.8-36.8-36.8-2.9 2.9 31.8 31.8H7.9l31.8-31.8-2.9-2.9L0 100.2 36.8 137l2.9-2.9z" />
            </svg>
            <img
              className="h-[4rem] w-[4rem]"
              src={data.oauthClient.icon_url}
              alt={`${data.oauthClient.name} icon`}
            />
          </div>
          <p className="p-2 text-center">
            Allow <strong>{data.oauthClient.name}</strong> to connect to your
            Tactiq account ( <strong>{userEmail}</strong> )
          </p>
          {scopesArray.length > 0 && (
            <div className="flex flex-col gap-2 pt-4">
              <p className="text-center text-sm font-semibold">
                This will allow them to:
              </p>
              <ul className="list-inside list-disc">
                {scopesArray.map((scope) => (
                  <li key={scope}>{scopeNames[scope]}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex flex-row gap-6 p-12">
          <Button
            onClick={() =>
              rejectClient({
                redirect_uri: data?.oauthClient?.redirect_uri || '',
              })
            }
          >
            <FormattedMessage defaultMessage="Reject" id="VzIOKf" />
          </Button>
          <Button
            onClick={() => authorizeClient()}
            loading={getRedirectUrlMutation.loading}
          >
            <FormattedMessage defaultMessage="Allow" id="y/bmsG" />
          </Button>
        </div>
      </>
    );
  } else if (data.oauthClient.requiredTier === UserTier.PRO) {
    content = (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            <FormattedMessage
              defaultMessage="Upgrade to continue"
              id="6Z9SvQ"
            />
          </h3>
          <div className="mt-2 sm:flex sm:items-center sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>
                <FormattedMessage
                  defaultMessage="You need to be on a Pro plan or higher to use this integration."
                  id="q1NB82"
                />
              </p>
            </div>
            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
              <Button onClick={() => setShowTierPricingDialog(true)}>
                <FormattedMessage defaultMessage="Upgrade" id="0h/lPM" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data.oauthClient.requiredTier === UserTier.TEAM) {
    content = (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            <FormattedMessage
              defaultMessage="Upgrade to continue"
              id="6Z9SvQ"
            />
          </h3>
          <div className="mt-2 sm:flex sm:items-center sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>
                <FormattedMessage
                  defaultMessage="You need to be on a Team plan or higher to use this integration."
                  id="n0o4PP"
                />
              </p>
            </div>
            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
              <Button onClick={() => setShowTierPricingDialog(true)}>
                <FormattedMessage defaultMessage="Upgrade" id="0h/lPM" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data.oauthClient.requiredTier === UserTier.ENTERPRISE) {
    content = (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            <FormattedMessage
              defaultMessage="Upgrade to continue"
              id="6Z9SvQ"
            />
          </h3>
          <div className="mt-2 sm:flex sm:items-center sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>
                <FormattedMessage
                  defaultMessage="You need to be on an Enterprise plan to use this integration."
                  id="WBXtRr"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // why are we here, exactly?
    content = (
      <ErrorMessage
        error={
          <FormattedMessage
            defaultMessage="Cannot authorize. User tier ({userTier}) is not compatible with required tier ({clientTier})."
            id="eW5tzj"
            values={{
              clientTier: data.oauthClient.requiredTier,
              userTier,
            }}
          />
        }
      />
    );
  }

  return (
    <CenteredPanel>
      <div className="flex flex-col items-center gap-4">{content}</div>
      {showTierPricingDialog && (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.OAUTH_CONSENT}
          onClose={() => setShowTierPricingDialog(false)}
        />
      )}
    </CenteredPanel>
  );
};
