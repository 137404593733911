import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { kSignInPage } from '../../../../helpers/routes';
import { selectIsAuthenticated } from '../../../../redux/selectors';
import { MeetingUnauthorized as MeetingUnauthorizedImpl } from './MeetingUnauthorized';

const MeetingUnauthorized: React.FC<{ meetingId: string }> = ({
  meetingId,
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to={`${kSignInPage}?redirect=${location.pathname}`} />;
  }

  return <MeetingUnauthorizedImpl meetingId={meetingId} />;
};

export default MeetingUnauthorized;

export { MeetingNotFound } from './MeetingNotFound';
