import React from 'react';

export const Dot: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="3"
      height="4"
      viewBox="0 0 3 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1.99994C3 2.82837 2.32843 3.49994 1.5 3.49994C0.671573 3.49994 0 2.82837 0 1.99994C0 1.17151 0.671573 0.499939 1.5 0.499939C2.32843 0.499939 3 1.17151 3 1.99994Z"
        fill="#ADB3BD"
      />
    </svg>
  );
};
