import { GitPullRequestArrow } from 'lucide-react';
import React from 'react';
import { Button } from '../../components/buttons';
import { isProduction } from '../../helpers/firebase/config';

export const PullRequestButton: React.FC = () => {
  if (!process.env.PR_LINK || isProduction()) {
    return null;
  }
  return (
    <Button
      target="_blank"
      href={process.env.PR_LINK ?? ''}
      className="ml-auto"
      variant="icon"
    >
      <GitPullRequestArrow size="1rem" />
    </Button>
  );
};

export default PullRequestButton;
