import React from 'react';

interface VisibleOnlyWhenProps {
  condition: boolean;
  children: React.ReactNode;
}
export const VisibleOnlyWhen: React.FC<VisibleOnlyWhenProps> = (props) => {
  if (props.condition) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};
