import { logger } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';

export const copyToClipboard = async (
  text: string,
  message?: string
): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text);

    if (message) {
      enqueueSnackbar(message, {
        autoHideDuration: 10000,
        variant: 'SUCCESS',
      });
    }
  } catch (err) {
    logger.warn(err);
  }
};
