import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeadEnd } from '../../../../components/DeadEnd';
import { kTranscripts } from '../../../../helpers/routes';
import { Button } from '../../../../components/buttons';

export const MeetingNotFound: React.FC = () => {
  const intl = useIntl();
  return (
    <DeadEnd
      title={intl.formatMessage({
        defaultMessage: '😫 Ugh, oh!',
        id: 'cRRMEQ',
        description: 'Not found meeting view. Title',
      })}
      description={intl.formatMessage({
        defaultMessage: 'Looks like this meeting was deleted or never existed.',
        id: 'vuJmII',
        description: 'Not found meeting view. Description',
      })}
      action={
        <Button variant="light" href={kTranscripts}>
          <FormattedMessage defaultMessage="Go to my meetings" id="5nKGwH" />
        </Button>
      }
    />
  );
};
