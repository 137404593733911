// Sharing UI and Auto Team Sharing process is disabled for these teams.
const DISABLED_TEAMS = [
  '8dD1D8RJCVQvW7EPno3z', // Amplify
];

/**
 * Check if team share is allowed
 * @param teamId
 * @returns
 */
// Temporarily disabling team share for every while we do the migration.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isTeamShareAllowed = (teamId?: string): boolean =>
  !DISABLED_TEAMS.includes(teamId || '');
