import React from 'react';
import { cx } from '../../helpers/utils';

export type ChipClickHandler = (() => void) | (() => Promise<void>);

export type TabDefinition = {
  id: string;
  name: React.ReactNode;
  count?: number;
};

export const Tabs: React.FC<{
  tabs: TabDefinition[];
  onChange: (tabId: string) => void;
  selectedTab?: string;
}> = ({ tabs, selectedTab, onChange }) => {
  const [selectedTabState, setSelectedTab] = React.useState<string>(
    tabs.length > 0 ? tabs[0].id : ''
  );
  const selectedTabId = selectedTab ?? selectedTabState;

  const handleTabClick = (id: string) => {
    if (typeof selectedTab === 'undefined') {
      // uncontrolled component, update state
      setSelectedTab(id);
    }
    onChange(id);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => selectedTabId === tab.id)?.id}
        >
          {tabs.map((tab) => (
            <option key={tab.id}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <a
                key={tab.id}
                className={cx(
                  selectedTabId === tab.id
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                )}
                aria-current={selectedTabId === tab.id ? 'page' : undefined}
                onClick={() => {
                  handleTabClick(tab.id);
                }}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={cx(
                      selectedTabId === tab.id
                        ? 'bg-indigo-100 text-indigo-600'
                        : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
