import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideReasonForSignupModal } from '../../../redux/modules/global';
import {
  selectShowReasonForSignupModal,
  selectUid,
} from '../../../redux/selectors';
import { SignupReasonModal as SignupReasonModalImpl } from './SignupReasonModal';
import { useMutation } from '@apollo/client';
import { UpdateUserSettingDocument } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import featureFlagService from '../../../helpers/feature-flags';

/**
 * Rate us modal
 * @returns {React.FC} a component
 */
const SignUpReason: React.FC = () => {
  const uid = useSelector(selectUid);
  const dispatch = useDispatch();
  const [updateUserSetting] = useMutation(UpdateUserSettingDocument);
  const shouldShow = featureFlagService.isSetupPageTypeformEnabled();
  const hasSeenTypeform = useSelector(selectShowReasonForSignupModal);

  const onClose = useCallback(async () => {
    const now = Date.now();
    trackWebEvent('Reason for signup modal onClose clicked', {
      user: uid,
    });
    dispatch(hideReasonForSignupModal());
    await updateUserSetting({
      variables: {
        input: {
          lastReasonForSignupModalTimestamp: Math.ceil(now / 1000),
        },
      },
    });
  }, [uid, dispatch, updateUserSetting]);

  const onSubmit = useCallback(async () => {
    const now = Date.now();
    trackWebEvent('Reason for signup modal onSubmit clicked', {
      user: uid,
    });
    dispatch(hideReasonForSignupModal());
    await updateUserSetting({
      variables: {
        input: {
          lastReasonForSignupModalTimestamp: Math.ceil(now / 1000),
        },
      },
    });
  }, [uid, dispatch, updateUserSetting]);

  if (!uid) return null;
  if (!shouldShow) return null;
  if (hasSeenTypeform) return null;

  return (
    <SignupReasonModalImpl
      uid={uid}
      open
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default SignUpReason;
