import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { FuseResult } from 'fuse.js';
import { Button } from '../../../../components/buttons';

interface Props<T = unknown> {
  matches: FuseResult<T>[];
  onMatchSelected: (match: FuseResult<T>) => void;
}

export interface Ref {
  onNextResult: () => void;
  onPrevResult: () => void;
}

const SearchBarNavigation: React.ForwardRefRenderFunction<
  Ref | undefined,
  Props
> = (props, ref) => {
  const { matches, onMatchSelected } = props;

  const [currentMatchIndex, setCurrentMatch] = useState<number | null>(null);

  const onPrevResult = useCallback(() => {
    if (!currentMatchIndex) {
      return;
    }

    const newIndex = (currentMatchIndex - 1) % matches.length;
    const match = matches[newIndex];
    onMatchSelected(match);
    setCurrentMatch(newIndex);
  }, [currentMatchIndex, matches, onMatchSelected]);

  const onNextResult = useCallback(() => {
    const newIndex =
      (currentMatchIndex === null ? 0 : currentMatchIndex + 1) % matches.length;
    const match = matches[newIndex];
    onMatchSelected(match);
    setCurrentMatch(newIndex);
  }, [currentMatchIndex, matches, onMatchSelected]);

  useImperativeHandle(ref, () => ({
    onNextResult,
    onPrevResult,
  }));

  useEffect(() => {
    if (matches.length > 0) {
      setCurrentMatch(0);
    } else {
      setCurrentMatch(null);
    }
  }, [matches]);

  return (
    <>
      <span>
        {currentMatchIndex === null ? 0 : currentMatchIndex + 1} /{' '}
        {matches.length}
      </span>
      <Button
        aria-label="prev result"
        onClick={onPrevResult}
        variant="icon"
        size="icon"
      >
        <ArrowUp />
      </Button>
      <Button
        aria-label="next result"
        variant="icon"
        onClick={onNextResult}
        size="icon"
      >
        <ArrowDown />
      </Button>
    </>
  );
};

export default forwardRef(SearchBarNavigation);
