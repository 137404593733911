import React from 'react';
import { cx } from '../../helpers/utils';
import { Tooltip } from '../Tooltip';

// The below set of colours come from COLORS from src/graphql/resolvers/serializers/meeting.ts
// It's a workaround for now but we can make it better soon.
export type SpeakerColourProps =
  | 'default'
  | '#03a9f4'
  | '#4caf50'
  | '#9c27b0'
  | '#ff9800'
  | '#f44336'
  | '#673ab7'
  | '#e91e63'
  | '#8bc34a'
  | '#ffc107'
  | '#ff5722'
  | '#2196f3'
  | '#cddc39'
  | '#ffeb3b'
  | '#3f51b5'
  | '#00bcd4'
  | '#009688';

type AvatarProps = {
  src?: string;
  alt?: string;
  name: string;
  tooltip?: string;
  className?: string;
  color?: SpeakerColourProps;
  size?: 'sm' | 'md' | 'lg';
  onClick?: () => void;
};

export const ColorClasses: Record<NonNullable<AvatarProps['color']>, string> = {
  default: 'bg-gray-100 text-gray-800',
  '#03a9f4': 'bg-blue-100 text-blue-800',
  '#4caf50': 'bg-green-100 text-green-800',
  '#9c27b0': 'bg-purple-100 text-purple-800',
  '#ff9800': 'bg-orange-100 text-orange-800',
  '#f44336': 'bg-red-100 text-red-800',
  '#673ab7': 'bg-indigo-100 text-indigo-800',
  '#e91e63': 'bg-pink-100 text-pink-800',
  '#8bc34a': 'bg-light-green-100 text-light-green-800',
  '#ffc107': 'bg-amber-100 text-amber-800',
  '#ff5722': 'bg-orange-600 text-orange-800',
  '#2196f3': 'bg-sky-100 text-sky-800',
  '#cddc39': 'bg-lime-100 text-lime-800',
  '#ffeb3b': 'bg-yellow-100 text-yellow-800',
  '#3f51b5': 'bg-indigo-100 text-indigo-800',
  '#00bcd4': 'bg-cyan-100 text-cyan-800',
  '#009688': 'bg-teal-100 text-teal-800',
};

const sizeClass: Record<NonNullable<AvatarProps['size']>, string> = {
  sm: 'size-4',
  md: 'size-8',
  lg: 'size-10',
};

export const Avatar: React.FC<AvatarProps> = ({
  src,
  alt,
  name,
  tooltip,
  className,
  color,
  size = 'md',
  onClick,
}) => {
  if (src) {
    return (
      <Tooltip title={tooltip ?? name} placement="top" arrow>
        <img
          onClick={onClick}
          className={cx(
            'overflow-clip rounded-full',
            sizeClass[size],
            className
          )}
          src={src}
          alt={alt ?? name}
        />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tooltip ?? name} placement="top" arrow>
        <div
          aria-label={alt ?? name}
          onClick={onClick}
          className={cx(
            'flex cursor-default items-center justify-center rounded-full text-sm font-medium',
            ColorClasses[color ?? 'default'],
            sizeClass[size],
            className
          )}
        >
          {name ? name[0].toUpperCase() : ''}
        </div>
      </Tooltip>
    );
  }
};

export const AvatarGroup: React.FC<{
  avatars: AvatarProps[];
  size?: AvatarProps['size'];
  onClick?: () => void;
  max?: number;
}> = ({ avatars, size = 'md', onClick, max = 6 }) => {
  const trimAmount = max ?? 6;
  let remainingGroup = null;
  let remainingNames: AvatarProps[] = [];
  if (avatars.length > trimAmount) {
    remainingGroup = avatars.length - trimAmount;
    remainingNames = avatars.slice(trimAmount);
  }
  return (
    <div className="isolate flex -space-x-1 overflow-hidden" onClick={onClick}>
      {avatars.slice(0, trimAmount).map((avatar, index) => {
        return (
          <Avatar
            className={cx(
              'relative inline-block rounded-full ring-2 ring-white',
              avatar.className ? avatar.className : ''
            )}
            onClick={avatar.onClick}
            key={`${avatar.name}-${index}`}
            src={avatar.src}
            alt={avatar.alt}
            name={avatar.name}
            color={avatar.color}
            size={size}
          />
        );
      })}
      {remainingGroup && (
        <Tooltip
          title={
            <div className="flex flex-col flex-nowrap gap-0.5">
              {remainingNames.slice(0, 10).map((a, index) => (
                <p key={`${a}-${index}`}>{a.name}</p>
              ))}
              {remainingNames.slice(10).length > 0 && (
                <p>+ {remainingNames.slice(10).length} more</p>
              )}
            </div>
          }
          placement="top"
          arrow
        >
          <div
            className={cx(
              'relative flex cursor-default items-center justify-center rounded-full text-xs font-medium ring-2 ring-white',
              ColorClasses.default,
              sizeClass[size]
            )}
          >
            + {remainingGroup}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
