import React from 'react';
import { RichTextInput } from '../../../components/RichTextInput';
import { Button } from '../../../components/buttons';
import { WorkflowStatus } from '@tactiq/model';
import { useSubmitWorkflowConfirmation } from '../../../services/Workflow';
import { FormattedMessage } from 'react-intl';
import { NodeData, WorkflowExecution } from '../../../graphql/operations';

const ConfirmationScreen: React.FC<{
  nodeData: NodeData;
  execution: WorkflowExecution;
}> = ({ nodeData, execution }) => {
  const [content, setContent] = React.useState(nodeData.output.data.prompt);
  const submitYes = useSubmitWorkflowConfirmation();
  const submitNo = useSubmitWorkflowConfirmation();

  const createSubmitHandler = (isYes: boolean) => () => {
    const fetcher = isYes ? submitYes : submitNo;
    if (fetcher.loading) {
      return;
    }
    return fetcher.request({
      input: {
        executionId: execution.id,
        nodeId: nodeData.id,
        content,
        isYes,
      },
    });
  };

  if (submitYes.error) throw submitYes.error;
  if (submitNo.error) throw submitNo.error;

  return (
    <div className="border-1 flex flex-col rounded-lg border border-slate-200 p-4">
      <RichTextInput
        value={content}
        isEditable={true}
        onChange={(value) => setContent(value)}
        disableCitations
      />
      <div className="flex flex-row justify-center gap-2">
        <Button
          variant="secondaryOutline"
          loading={submitNo.loading}
          onClick={createSubmitHandler(false)}
        >
          <FormattedMessage defaultMessage="No" id="oUWADl" />
        </Button>
        <Button loading={submitYes.loading} onClick={createSubmitHandler(true)}>
          <FormattedMessage defaultMessage="Yes" id="a5msuh" />
        </Button>
      </div>
    </div>
  );
};

export const WorkflowConfirmation: React.FC<{
  execution: WorkflowExecution;
}> = (props) => {
  const { execution } = props;

  return (
    <>
      {execution.nodeData
        .filter((_) => _.status === WorkflowStatus.WAITING_FOR_CONFIRMATION)
        .map((nodeData) => (
          <ConfirmationScreen
            key={nodeData.id}
            nodeData={nodeData}
            execution={execution}
          />
        ))}
    </>
  );
};
