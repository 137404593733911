import { useReactFlow, getIncomers } from '@xyflow/react';
import { WorkflowNode, WorkflowNodeProps } from './BaseNode';
import { nodeTypes, useNodeTypeToName } from '.';
import type { Completion, CompletionContext } from '@codemirror/autocomplete';
import type { EditorState } from '@codemirror/state';
import { useWorkflowId } from '../WorkflowIdContext';
import { useWorkflowItem } from '../../../services/Workflow';
import { WorkflowTemplateContext } from '@tactiq/model';

type AnyShape = ObjectShape<any> | ArrayShape | BasicType;

export interface ObjectShape<T> {
  type: 'object';
  description?: string;
  fields: Record<keyof T, AnyShape>;
}

export interface BasicType {
  type: 'string' | 'number' | 'boolean';
  description?: string;
}

export interface ArrayShape {
  type: 'array';
  description?: string;
  items: AnyShape;
}

const userProperties: ObjectShape<WorkflowTemplateContext['user']> = {
  type: 'object',
  fields: {
    email: { type: 'string', description: 'The email of the user' },
    name: { type: 'string', description: 'The name of the user' },
  },
};

const meetingProperties: ObjectShape<WorkflowTemplateContext['meeting']> = {
  type: 'object',
  fields: {
    title: { type: 'string', description: 'The title of the meeting' },
    url: { type: 'string', description: 'A link to the meeting in Tactiq.io' },
    participants: {
      type: 'array',
      description: 'The participants of the meeting',
      items: {
        type: 'string',
        description: 'The name of the participant',
      },
    },
  },
};

export function staticCompletion<T extends ObjectShape<any>>(
  shape: T,
  path: string[]
): Completion[] {
  let currentShape: AnyShape = shape;
  for (const currentPath of path) {
    if (currentShape.type === 'object') {
      currentShape = currentShape.fields[currentPath];
    } else if (currentShape.type === 'array') {
      currentShape = currentShape.items;
    }
    if (!currentShape) {
      return [];
    }
  }
  if (currentShape.type === 'object') {
    return Object.entries(currentShape.fields).map(([label, field]) => ({
      label,
      type: field.type,
      detail: field.description,
    }));
  } else if (currentShape.type === 'array') {
    return [
      {
        label: '0',
        type: currentShape.items.type,
        detail: currentShape.items.description,
      },
      {
        label: 'length',
        type: currentShape.items.type,
        detail: currentShape.items.description,
      },
    ];
  }
  return [];
}

export interface AutocompleteProvider {
  autocompleteProperties: (node: WorkflowNode, path: string[]) => Completion[];
}

export function nodeAutocompleteProperies(
  node: WorkflowNode,
  path: string[]
): Completion[] {
  const nodeType = node.type;
  if (!nodeType) {
    return [];
  }
  const nodeTypeProperties = (
    nodeTypes[nodeType] as unknown as AutocompleteProvider
  ).autocompleteProperties;
  if (!nodeTypeProperties) {
    return [];
  }
  return nodeTypeProperties(node, path);
}

const emptyAutocomplete = {
  variables: [],
  properties: () => [],
};

export function useAutocomplete(node: WorkflowNodeProps): {
  variables: Completion[];
  properties: (
    path: readonly string[],
    state: EditorState,
    context: CompletionContext
  ) => Completion[];
} {
  const { workflowId } = useWorkflowId();
  const { data } = useWorkflowItem({ id: workflowId });
  const { getNodes, getEdges, getNode } = useReactFlow<WorkflowNode>();
  const nodeTypeToName = useNodeTypeToName();

  // performance optimization: don't calculate autocomplete for non-selected nodes
  if (!node.selected || node.dragging) {
    return emptyAutocomplete;
  }

  const recentExecutionData = data?.workflow?.recentExecution?.nodeData;

  const parentNodes = new Set<string>();

  const edges = getEdges();
  const nodes = getNodes();
  const stack: string[] = [node.id];

  while (stack.length > 0) {
    const currentId = stack.pop();
    if (!currentId) {
      break;
    }
    if (parentNodes.has(currentId)) {
      continue;
    }
    parentNodes.add(currentId);
    const incomingNodes = getIncomers({ id: currentId }, nodes, edges).filter(
      (n) => n.type !== 'StartNode'
    );
    stack.push(...incomingNodes.map((n) => n.id));
  }

  const stepVariables: Completion[] = Array.from(parentNodes)
    .filter((nodeId) => node.id !== nodeId)
    .map((id) => {
      const autocompleteNode = getNode(id);
      if (!autocompleteNode) {
        throw new Error(`Node with id ${id} not found`);
      }
      const nodeType = autocompleteNode.type;
      if (!nodeType) {
        throw new Error(`Node with id ${id} has no type`);
      }
      const info = recentExecutionData?.find((d) => d.id === id)?.output;
      return {
        label: id,
        type: `step_${nodeType}`,
        detail: autocompleteNode.data.displayName ?? nodeTypeToName[nodeType],
        info: info
          ? `Recent output: \n\n ${JSON.stringify(info).substring(0, 300)}...`
          : undefined,
      };
    });

  // don't suggest `input` variable if there more than one incoming edge
  const currentNodeIncomers = getIncomers({ id: node.id }, nodes, edges).filter(
    (ii) => ii.type !== 'StartNode'
  );
  const hasInputVariable = currentNodeIncomers.length === 1;

  const properties = (
    path: readonly string[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: EditorState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: CompletionContext
  ): Completion[] => {
    if (path.length < 1) {
      return [];
    }
    if (path[0] === 'input') {
      return nodeAutocompleteProperies(currentNodeIncomers[0], path.slice(1));
    } else if (parentNodes.has(path[0])) {
      const node = getNode(path[0]);
      if (!node) {
        return [];
      }
      return nodeAutocompleteProperies(node, path.slice(1));
    } else if (path[0] === 'meeting') {
      return staticCompletion(meetingProperties, path.slice(1));
    } else if (path[0] === 'user') {
      return staticCompletion(userProperties, path.slice(1));
    }
    return [];
  };

  return {
    variables: [
      ...(hasInputVariable
        ? [
            {
              label: 'input',
              type: `step_${currentNodeIncomers[0].type}`,
              detail: currentNodeIncomers[0].data.displayName,
            },
          ]
        : []),
      {
        label: 'meeting',
      },
      {
        label: 'user',
      },
      ...stepVariables,
    ],
    properties,
  };
}

/**
 * This is very hacked together for now. Will see what people say about the
 * dropdown and amend it as needed. JSON output support is intentionally omitted
 */
export function getFlatVariableList(variables: Completion[]): Array<{
  group?: string;
  templates: Array<{ label: string; template: string }>;
}> {
  return [
    {
      group: 'outputs',
      templates: variables
        .filter((ii) => ii.label !== 'meeting' && ii.label !== 'user')
        .map((v) =>
          v.label === 'input'
            ? {
                label: 'Previous step',
                template: '{{input}}',
              }
            : {
                label: v.label,
                template: `{{${v.label}.output}}`,
              }
        ),
    },
    {
      group: 'meeting',
      templates: Object.entries(meetingProperties.fields).map(
        ([key, value]) => ({
          label: key,
          template:
            value.type === 'array'
              ? `{{meeting.${key} | map: 'name' | join: ', '}}`
              : `{{meeting.${key}}}`,
        })
      ),
    },
    {
      group: 'user',
      templates: Object.keys(userProperties.fields).map((key) => ({
        label: key,
        template: `{{user.${key}}}`,
      })),
    },
  ];
}
