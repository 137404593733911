import React from 'react';
import { Button } from '../../../../components/buttons';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useIntl } from 'react-intl';

interface Props {
  isExpanded: boolean;
  onClick: () => void;
}

/**
 * Expanded Participants
 */
export const ExpandParticipants: React.FC<Props> = ({
  isExpanded,
  onClick,
}) => {
  const intl = useIntl();
  let icon: React.ReactElement;
  let text: string;

  if (isExpanded) {
    icon = <ChevronUp />;
    text = intl.formatMessage({ defaultMessage: 'hide', id: 'O4umPu' });
  } else {
    icon = <ChevronDown />;
    text = intl.formatMessage({ defaultMessage: 'show more', id: 'IRPFF9' });
  }

  return (
    <li className="p-4">
      <Button color="info" endIcon={icon} onClick={onClick}>
        {text}
      </Button>
    </li>
  );
};
