import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { trackWebEvent } from '../../../../helpers/analytics';
import { isMeetingEditable } from '../../../../helpers/meetings';
import { selectUserIntegrationConnections } from '../../../../redux/selectors';
import { useFullMeeting } from '../../common/meeting-hooks';
import MeetingRecordings from '../recordings';
import { ShareCardWrapper } from './ShareCardWrapper';
import { CustomLinkDialog } from './recordings/CustomLinkDialog';
import { Cable, HelpCircle, PlaySquare, Plus, Webcam } from 'lucide-react';
import { Tooltip } from '../../../../components/Tooltip';
import { Button } from '../../../../components/buttons';
import { ModalDialog } from '../../../../components/modals';

const VIDEO_HELP_URL =
  'https://help.tactiq.io/en/articles/5820600-link-meeting-video-recording-to-the-transcript';

export const RecordingCard: React.FC = () => {
  const meeting = useFullMeeting();

  const integration = useSelector(selectUserIntegrationConnections)?.find(
    (c) => c.type === 'googledrive'
  );
  const hasGoogleCalendarIntegration = Boolean(
    integration?.scope?.includes('calendar.events') && !integration.error
  );
  const intl = useIntl();
  const isEditable = isMeetingEditable(meeting);
  const [showRecordingsModal, setShowRecordingsModal] = useState(false);
  const [showCustomLinkDialog, setShowCustomLinkDialog] = useState(false);
  const navigate = useNavigate();

  const onRecordingsClick = useCallback(() => {
    if (!meeting) return;

    document.body.style.setProperty('overflow-y', 'hidden', 'important');
    setShowRecordingsModal(true);
    trackWebEvent('Meeting View - Recordings - Recording link clicked', {
      meetingId: meeting.id,
    });
  }, [meeting]);

  const onAddCustomLinkClick = useCallback(() => {
    if (!meeting) return;

    trackWebEvent('Meeting View - Recordings - Add custom link clicked', {
      meetingId: meeting.id,
    });
    setShowCustomLinkDialog(true);
  }, [meeting]);

  const onAddonHelpClick = useCallback(() => {
    if (!meeting) return;

    trackWebEvent('Meeting View - Recordings - Add-on help link clicked', {
      meetingId: meeting.id,
    });
  }, [meeting]);

  const onConnectGoogleCalendarClick = useCallback(() => {
    if (!meeting) return;

    trackWebEvent('Meeting View - Recordings - Connect calendar link clicked', {
      meetingId: meeting.id,
    });

    navigate('/integrations/google-calendar');
  }, [meeting, navigate]);

  const allRecordings = [
    ...(meeting?.recordings?.GoogleMeet ?? []),
    ...(meeting?.recordings?.Custom ?? []),
  ];

  const actionAddCustomLink = isEditable ? (
    <span>
      <Tooltip
        title={
          <FormattedMessage
            defaultMessage="Add custom recording link"
            id="7g6Xso"
          />
        }
      >
        <Button size="tiny" variant="icon" onClick={onAddCustomLinkClick}>
          <Plus strokeWidth={2} size={18} className="text-slate-600" />
        </Button>
      </Tooltip>

      <CustomLinkDialog
        open={showCustomLinkDialog}
        onClose={() => setShowCustomLinkDialog(false)}
      />
    </span>
  ) : null;

  const actionHelp = (
    <Tooltip
      title={
        <FormattedMessage
          defaultMessage="Learn how to auto-attach recordings"
          id="ctHGCR"
          description="Video recording article link text. Meeting header section."
        />
      }
    >
      <Button
        size="tiny"
        variant="icon"
        href={VIDEO_HELP_URL}
        target="_blank"
        onClick={onAddonHelpClick}
      >
        <HelpCircle strokeWidth={2} size={18} className="text-slate-600" />
      </Button>
    </Tooltip>
  );

  const actionConnectCalendar = (
    <Tooltip
      title={
        <FormattedMessage
          defaultMessage="Connect to Google Calendar"
          id="j8U16W"
        />
      }
    >
      <Button size="tiny" variant="icon" onClick={onConnectGoogleCalendarClick}>
        <Cable className="size-5 text-slate-600" />
      </Button>
    </Tooltip>
  );

  if (!meeting) {
    return null;
  }

  if (allRecordings.length) {
    return (
      <span>
        {allRecordings.map((r) => (
          <ShareCardWrapper
            key={'fileId' in r ? r.fileId : r.url}
            imageComponent={
              <Webcam strokeWidth={2} size={16} className="text-slate-600" />
            }
            title={intl.formatMessage({
              defaultMessage: 'Google Meet recording',
              id: '+UoPTx',
            })}
            actions={
              <Tooltip
                title={<FormattedMessage defaultMessage="View" id="FgydNe" />}
              >
                <Button size="tiny" variant="icon" onClick={onRecordingsClick}>
                  <PlaySquare
                    strokeWidth={2}
                    size={20}
                    className="text-slate-600"
                  />
                </Button>
              </Tooltip>
            }
          />
        ))}

        <ModalDialog
          size="large"
          open={showRecordingsModal}
          onClose={() => {
            document.body.style.setProperty(
              'overflow-y',
              'scroll',
              'important'
            );
            setShowRecordingsModal(false);
          }}
        >
          <MeetingRecordings recordings={meeting.recordings} />
        </ModalDialog>
      </span>
    );
  } else {
    return (
      <ShareCardWrapper
        imageComponent={
          <Webcam strokeWidth={2} size={16} className="text-slate-600" />
        }
        title={intl.formatMessage({
          defaultMessage: 'No recording',
          id: '4AVB96',
        })}
        actions={
          <span className="flex flex-col md:flex-row">
            {hasGoogleCalendarIntegration ? null : actionConnectCalendar}
            {actionAddCustomLink}
            {actionHelp}
          </span>
        }
      />
    );
  }
};
