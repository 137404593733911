// Also, check /r/* redirects in Webflow (https://webflow.com/dashboard/sites/tactiq/publishing)

export enum TrackedFeature {
  TranscriptNotification = 'transcript-notification',
  MeetingChatNotification = 'meeting-chat-notification',
  ExtensionNotInstalledAlert = 'extension-not-installed-alert',
  GoogleWorkspaceAddon = 'google-workspace-addon',
  GoogleCalendar = 'google-calendar',
  CopyAIActionItems = 'copy-ai-action-items',
  CopyHighlights = 'copy-highlights',
  ExportedTranscript = 'exported-transcript',
  ShareMeeting = 'share-meeting',
  MeetingAddedToSpace = 'meeting-added-to-space',
  MeetingCommentNotification = 'meeting-comment-notification',
  TranscriptChangedNotification = 'transcript-changed-notification',
  ZapMeetingLink = 'zap-meeting-link',
}

export enum TrackedMedium {
  Email = 'email',
  Link = 'link',
  GoogleDrive = 'google-drive',
  Notion = 'notion',
  Confluence = 'confluence',
  Salesforce = 'salesforce',
  PDFExport = 'pdf-export',
  TXTExport = 'txt-export',
  Zapier = 'zapier',
}

/**
 *
 * @param {string} link link
 * @param {TrackedFeature} feature tracked feature
 * @param {TrackedMedium} medium tracked medium
 * @param {ConstructorParameters<typeof URLSearchParams>[0]} baseSearchParams base search params
 * @param {string} content content
 * @returns {string} URL string
 */
export function getTrackingURL(
  link: string,
  feature: TrackedFeature,
  medium: TrackedMedium,
  baseSearchParams?: ConstructorParameters<typeof URLSearchParams>[0],
  content?: string
): string {
  const searchParams = new URLSearchParams(baseSearchParams);

  const url = new URL(link);
  for (const [key, value] of url.searchParams) {
    searchParams.append(key, value);
  }

  addTrackingParams(searchParams, feature, medium, content);
  const query = searchParams.toString();

  return `${url.toString().split('?')[0]}${query ? '?' + query : ''}`;
}

/**
 *
 * @param {URLSearchParams} searchParams url params
 * @param {TrackedFeature} feature Tracked feature
 * @param {TrackedMedium} medium Tracked medium
 * @param {string} content content
 */
export function addTrackingParams(
  searchParams: URLSearchParams,
  feature: TrackedFeature,
  medium: TrackedMedium,
  content?: string
): void {
  searchParams.append('utm_source', 'tactiq-product');

  searchParams.append('utm_medium', feature);
  searchParams.append('utm_campaign', medium);

  if (content) searchParams.append('utm_content', content);
}
