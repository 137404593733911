import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { trackWebPage } from '../../helpers/analytics';
import featureFlags from '../../helpers/feature-flags';
import { gotMeetings, showRateUsModal } from '../../redux/modules/global';
import {
  selectIsProUser,
  selectLastRenewDate,
  selectTeam,
  selectUserPlan,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { ImportTranscriptDialog } from './ImportTranscript';
import { TranscriptsList } from './list';
import { BasicMeeting } from '../../models/meeting';
import { ListMeetingsDocument, MeetingType } from '../../graphql/operations';
import { useQuery } from '@apollo/client';
import { SearchPopup } from './common/SearchBar';

const Transcripts: React.FC<{ uploadFileOnboarding?: boolean }> = ({
  uploadFileOnboarding,
}) => {
  const intl = useIntl();
  const team = useSelector(selectTeam);
  const [importFileDialogOpen, setImportFileDialogOpen] = useState<boolean>(
    uploadFileOnboarding ?? false
  );
  useEffect(() => {
    trackWebPage('Transcripts', {
      team_id: team?.id,
      onboarding: uploadFileOnboarding ? 'upload_file_popup' : undefined,
    });
  }, []);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'My Meetings',
        id: 'ZCJ8At',
        description: 'Menu bar text.',
      })}
      maxWidth={'md'}
    >
      <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
        <h1 className="my-0 text-3xl">
          <FormattedMessage
            defaultMessage="My Meetings"
            id="DLCT04"
            description="Transcripts page title."
          />
        </h1>
        <Button
          onClick={() => setImportFileDialogOpen(true)}
          variant="outlined"
        >
          <FormattedMessage
            defaultMessage="Upload Transcript or Recording"
            id="Pebbm4"
          />
        </Button>
      </div>
      {importFileDialogOpen && (
        <ImportTranscriptDialog
          isOpen={importFileDialogOpen}
          setClose={() => setImportFileDialogOpen(false)}
          isOnboarding={uploadFileOnboarding}
        />
      )}
      <LegacyTranscriptsList />
    </Page>
  );
};

const selectPreviewMeetingsToCleanup = (
  meetings: BasicMeeting[],
  plan: RootState['user']['plan']
): BasicMeeting[] => {
  if (!plan.free.prevLastRenewDate) {
    return [];
  }

  if (plan.paid?.isPaid) {
    return [];
  }

  const prevLastRenewDate = plan.free.prevLastRenewDate ?? 0;

  return meetings.filter((m) => {
    const createdAt = m.created;

    return (
      createdAt < plan.free.lastRenewDate &&
      createdAt >= prevLastRenewDate &&
      m.isPreview
    );
  });
};

const LegacyTranscriptsList: React.FC = () => {
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const uploadingMeetings = useSelector(
    (state: RootState) => state.global.activeTranscriptUploads
  );
  const plan = useSelector(selectUserPlan);
  const isProUser = useSelector(selectIsProUser);
  const isPreviewMeetingsCleanupWarningEnabled =
    featureFlags.isPreviewMeetingsCleanupWarningEnabled();
  const lastRenewDate = useSelector(selectLastRenewDate);

  const {
    previousData,
    data = previousData,
    loading,
    fetchMore,
  } = useQuery(ListMeetingsDocument, {
    variables: {
      type: MeetingType.MYMEETINGS,
      filter: {},
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(newData) {
      dispatch(
        gotMeetings({
          type: MeetingType.MYMEETINGS,
          meetings: newData.meetings.meetings,
        })
      );
      setHasMore(newData.meetings.hasMore);
    },
  });

  const storeMeetings = Object.values(
    useSelector((state: RootState) => state.global.meetings.my)
  );

  const meetings = storeMeetings.length
    ? storeMeetings
    : (data?.meetings.meetings ?? []);

  const previewMeetingsToCleanup = selectPreviewMeetingsToCleanup(
    meetings,
    plan
  );

  useEffect(() => {
    if (meetings.length > 10) {
      const timeout = setTimeout(() => {
        dispatch(showRateUsModal());
      }, 2500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [dispatch, meetings.length]);

  return (
    <TranscriptsList
      meetings={meetings}
      loadMore={async () => {
        if (hasMore) {
          setIsLoadingMore(true);
          const result = await fetchMore({
            variables: { offset: storeMeetings.length },
          });

          dispatch(
            gotMeetings({
              type: MeetingType.MYMEETINGS,
              meetings: [...storeMeetings, ...result.data.meetings.meetings],
            })
          );
          setHasMore(result.data.meetings.hasMore);
          setIsLoadingMore(false);
        }
      }}
      uploadingMeetings={uploadingMeetings}
      previewMeetingsToCleanup={
        isPreviewMeetingsCleanupWarningEnabled ? previewMeetingsToCleanup : []
      }
      lastRenewal={lastRenewDate}
      showRefreshCycleDivider={!isProUser}
      isLoading={loading && !meetings.length}
      isLoadingMore={isLoadingMore}
      searchBar={<SearchPopup />}
    />
  );
};

export default Transcripts;
