import { useSelector } from 'react-redux';
import type { QueryResult } from './helpers';
import { RootState } from '../redux/store';
import { UserUpdatesSubscription } from '../graphql/operations';

/**
 * The Integrations the user has configured
 */
export function useConnections(): QueryResult<
  NonNullable<UserUpdatesSubscription['user']>['connections']
> {
  const connections = useSelector((state: RootState) => state.user.connections);

  return {
    loading: false,
    error: undefined,
    data: connections,
  };
}
